@import '../../../../../../../../styles/variables.scss';

@keyframes fade-in {
	from {
		background-color: rgba(0, 0, 0, 0);
	}
	to {
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.container {
	--animation-timeOut: 0.3s;

	position: fixed;
	z-index: 99;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	animation: fade-in var(--animation-timeOut) ease-in-out forwards;
	overflow-y: scroll;

	// -ms-overflow-style: none;
	// scrollbar-width: none;

	// overflow-x: hidden;

	// &::-webkit-scrollbar {
	// 	display: none;
	// }
}

@keyframes fade-out {
	from {
		background-color: rgba(0, 0, 0, 0.4);
	}
	to {
		background-color: rgba(0, 0, 0, 0);
	}
}

.container.fadeOut {
	animation: fade-out var(--animation-timeOut) ease-in-out forwards;
}

@keyframes slide-in {
	from {
		right: -666px;
	}
	to {
		right: 0;
	}
}

.modal {
	position: absolute;
	right: 0;
	background-color: $white;
	width: 70%;
	min-height: 100%;
	animation: slide-in var(--animation-timeOut) ease-in-out forwards;

	.timeFLex {
		display: flex;
	}

	@media screen and (max-width: 800px) {
		margin: 0;
		width: 90%;
	}

	@media screen and (max-width: 600px) {
		width: 85%;
	}

	@media screen and (max-width: 500px) {
		height: calc(100% - 100px);
		overflow: auto;
	}
}

@keyframes slide-out {
	from {
		right: 0;
	}
	to {
		right: -666px;
	}
}

.modal.slideOut {
	animation: slide-out var(--animation-timeOut) ease-in-out forwards;
}

.close {
	cursor: pointer;
	width: fit-content;
	display: flex;
	align-items: center;
	gap: 15px;
	padding: 20px;
}

.info {
	background-color: #f7f7f7;
	border-left: 2px solid $green;
	padding: 15px;
	margin-top: 10px;
}

.copilotPreInfo {
	display: flex;
	align-items: flex-start;
	gap: 15px;
	background: linear-gradient(90deg, #1e0290 0%, #033ac8 100%);
	color: white;
	margin: 20px;
	padding: 15px;
}

.note {
	margin: 20px;

	button {
		width: 100%;
	}

	textarea {
		color: black !important;
		height: 500px;
		padding: 5px;
	}
}

.name {
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: 600;
}

.entry {
	display: flex;
	align-items: start;
	justify-content: space-between;
	padding: 20px;
	margin: 30px;
	background-color: #f7f7f7;
}

.text {
	padding: 15px;
}

.message {
	text-align: center;
	margin: 40px;
	padding: 0;
	font-size: 20px;
}
