@import '../styles/variables';

.page {
	display: flex;
}

.mainContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 1;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: hidden;

	&::-webkit-scrollbar {
		display: none;
	}
}

.main {
	display: flex;
	height: 100%;
	width: 100%;
}

.endTrial {
	z-index: 999;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.522);

	.modalTrial {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: white;
		box-shadow: 0, 0, 2, 0, #00000040;
		width: 839.03px;
		padding: 32px;
		border-radius: 12px;

		.trialTitle {
			font-size: 20px;
			margin-left: 0;
		}

		p {
			margin-top: 15px;
			font-size: 16px;
			font-weight: 400;
		}

		.flex {
			display: flex;
			align-items: center;
			flex-flow: wrap;
			justify-content: space-between;
			margin-top: 25px;
		}
	}
}