@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/mixins.scss';

.over {
	width: 100%;
	height: calc(100vh - 260px);
}

.overviewList {
	height: 100%;
	overflow-x: auto;
	white-space: nowrap;
	padding-inline: 32px;
	padding-block: 24px;
}

.confirmBox {
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	div {
		margin: auto;
		margin-top: 300px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;

		button {
			margin: 10px;
		}
	}
}

.form {
	border-top: 1px solid $lign;
	width: 100%;
	margin: 0;
	padding: 0;
	padding: 10px;
	position: fixed;
	bottom: 0;
	background-color: $white;

	@media screen and (max-width: 500px) {
		bottom: 50px;
		padding-bottom: 20px;
		position: relative;
		margin-top: 50px;
	}

	p {
		font-weight: 500;
		font-size: 16px;
	}

	form {
		margin: 0;
		padding: 0;
		width: 60%;
		display: flex;
		align-items: center;
		flex-flow: wrap;
		gap: 20px;

		@media screen and (max-width: 820px) {
			width: 300px;
		}

		@media screen and (max-width: 800px) {
			width: 90%;
		}

		input {
			border: 1px solid $lign;
			width: 340.95px;
			height: 24px;
			padding: 0;
			margin: 0;
		}

		select {
			border: 1px solid $lign;
			width: 200px;
			height: 36px;
		}

		button {
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}
}

.selectCustom {
	position: relative;
	button {
		background-color: $inputBg;
		border: 1px solid $lign;
		color: $black;
		width: 150px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;

		&:hover {
			background-color: #ebebeb;
		}
	}

	.dropdown {
		background-color: white;
		width: 150px;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		position: absolute;
		max-height: 200px;
		overflow: auto;

		p {
			padding: 0;
			margin: 0;
			padding: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 5px;

			&:hover {
				background-color: $lightGreen;
				color: $green;
			}
		}
	}
}

.tabs {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	margin-bottom: 2rem;
	color: #8a8886;
	font-size: 13px;
	padding-left: 20px;

	a {
		cursor: pointer;

		&:hover {
			font-weight: 600;
			border-bottom: 2px solid $green;
			color: $black;
		}
	}

	.activeTab {
		font-weight: 600;
		padding-bottom: 10px;
		border-bottom: 2px solid $green;
		color: $black;
	}
}

.selectNote {
	padding: 15px;
}

.note {
	padding: 15px;

	cursor: pointer;
}

.fullNote {
	border: 1px solid $lign;
	border-radius: 3px;
	position: relative;
	margin-top: 25px;
	border-radius: 4px;
	// max-height: 400px;
	// overflow: auto;

	.button {
		display: flex;
		justify-content: space-between;
		padding: 15px;
		padding-bottom: 0px;

		.date {
			margin: 0;
			padding: 0;
			color: #707070;
			font-weight: 500;
		}

		.icon {
			cursor: pointer;
			color: $lign;
		}
	}

	&:hover {
		.icon {
			color: $black;
		}
	}
}

.buttonComment {
	display: flex;
	justify-content: end;
	padding-top: 15px;
}

.commentSection {
	background-color: #f7f7f7;
	padding: 20px 15px;
	margin-top: 30px;
	border-top: 1px solid #d7d7d7;

	.formComment {
		// border-top: 1px solid $grey;
		padding-top: 30px;
		position: relative;

		// &::after {
		// 	content: '';
		// 	position: absolute;
		// 	top: 13px;
		// 	left: -15px;
		// 	width: calc(100% + 30px);
		// 	height: 1px;
		// 	background-color: $lign;
		// }

		.comment {
			display: flex;
			align-items: flex-start;
			gap: 10px;

			textarea {
				width: 100%;
				padding-block: 12px;
				padding-inline: 20px;
				border: 1px solid #ccc;
				border-radius: 4px;
				background-color: $white;
				font-size: 14px;
				resize: none;

				&:focus {
					outline: none;
				}
			}
		}

		.button {
			display: flex;
			justify-content: flex-end;
			position: relative;
		}

		button {
			display: flex;
			align-items: center;
			gap: 10px;
			padding-inline: 20px;
		}
	}
}

.commentNote {
	padding-top: 20px;

	.icon {
		color: $lign;
	}

	&:hover {
		.icon {
			color: $black;
		}
	}

	.name {
		display: flex;
		align-items: center;
		gap: 10px;
		color: #707070;
	}

	.commentText {
		margin-left: 40px;
		font-weight: 500;

		display: flex;
		align-items: center;

		.commentTextDisplay {
			width: 100%;
			padding: 0;
			margin: 0;
		}

		textarea {
			width: 100%;
			height: 50px;
			padding: 12px 20px;
			box-sizing: border-box;
			border: 2px solid #ccc;
			border-radius: 4px;
			background-color: $white;
			font-size: 14px;
			resize: none;
			margin-right: 5px;

			&:focus {
				outline: none;
			}
		}

		.icons {
			display: flex;
			align-items: center;
			gap: 15px;
		}

		.icon {
			cursor: pointer;
		}
	}
}

.count {
	font-size: 14px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: $green;

	span {
		text-decoration: underline;
		&:hover {
			color: #006b34;
		}
	}
}

.countActive {
	font-size: 14px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: $green;

	span {
		text-decoration: underline;
		color: #006b34;
	}
}

.title {
	margin: 0;
	padding: 0;
	padding-left: 15px;
	font-size: 20px;
	font-weight: 700;
}

.access {
	display: flex;
	align-items: center;
	gap: 10px;
	position: relative;
	margin-left: 15px;

	.companiesName {
		display: flex;
		gap: 5px;
	}
}

.accessButton {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	width: fit-content;
	background-color: #231f20;
	padding-block: 16px;
	padding-inline: 12px;
	border-radius: 87px;
	color: $white;
	height: 20px;

	i {
		font-size: 16px;
	}
}

.filters {
	display: flex;
	align-items: center;
	gap: 10px;
	position: relative;

	.filterModalWrapper {
		position: absolute;
		top: 110%;
		left: 271px;
		z-index: 999;
	}

	.buttonFilter {
		background-color: #f7f7f7;
		border-radius: 4px;
		font-weight: 500;
		font-size: 16px;
		padding-inline: 20px;
		padding-block: 12px;
		display: flex;
		align-items: center;
		gap: 10px;
		cursor: pointer;
		border: 1px solid #f7f7f7;
		&:hover {
			background-color: #ebebeb;
			border: 1px solid $black;
		}
	}

	.activeButton {
		position: relative;
		border-radius: 4px;
		padding-inline: 20px;
		padding-block: 12px;
		font-weight: 500;
		font-size: 16px;
		border-radius: 3px;
		display: flex;
		align-items: center;
		gap: 10px;
		cursor: pointer;
		border: 1px solid $black;
		background-color: #ebebeb;
	}
}

.container{
    position: relative;
    width: 250px;
    background-color: $white;
    padding: 15px;
    box-shadow: 0 0 6px 0px rgb(200 198 196);
    overflow: auto;
    border-radius: 4px;
    z-index: 99;
    @include fade-animation;

    h2{
        padding: 0;
        margin: 0;
        font-size: 18px;
        margin-bottom: 20px;
 
    }

    .list{
        .row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            font-weight: 500;
            color: #333;

            input{
                width: 16px;
                height: 20px;
            }

            .avatar{
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }
}

.selectCustom {
	position: relative;
	button {
		background-color: $inputBg;
		border: 1px solid $lign;
		color: $black;
		width: fit-content;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
		height: 37px;

		&:hover {
			background-color: #ebebeb;
		}
	}

	.dropdown {
		background-color: white;
		min-width: 150px;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		position: absolute;
		height: 100px;
		overflow: auto;
		top: -105px;

		p {
			padding: 0;
			margin: 0;
			padding: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 14px;

			&:hover {
				background-color: $lightGreen;
				color: $green;
			}
		}
	}
}

.flexButton {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	gap: 15px;
}

.accessFLex {
	margin-top: 20px;
	display: flex;
	align-items: center;
	gap: 15px;
}

.cancel {
	background-color: transparent;
	color: $black;
	font-weight: 600;
	border: 1px solid #8a8886;
	display: flex;
	align-items: center;
	gap: 10px;
	margin: 0;

	&:hover {
		background-color: #ebebeb;
	}
}

.form {
	background-color: $white;
	width: 400px;
	padding-inline: 22px;
	padding-block: 22px;
	min-width: 400px;
	border: 1px solid $lign;
	position: absolute;
	top: 50px;
	left: 0;
	height: max-content;
	z-index: 99;
	border-radius: 3px;
	box-shadow: 2px 2px 10px $lign;
	@include fade-animation;

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media screen and (max-width: 800px) {
		margin: 0;
		width: 90%;
	}

	.formButton {
		width: min-content;
		float: right;
		font-size: 16px;
		margin-top: 16px;
		padding-inline: 24px;
		padding-block: 12px;
		display: flex;
		align-items: center;
		gap: 8px;
	}

	p {
		font-weight: 500;
		font-size: 16px;
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		gap: 10px;
	}
}

.flexAccess {
	display: flex;
	align-items: center;
	justify-content: space-between;
	// flex-flow: wrap;
	margin-top: $marginTopInput;
	border-radius: 6px;
	border: 1px solid $lign;
	overflow: hidden;
}

.access {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	cursor: pointer;
	flex-basis: 50%;
	background-color: $white;
	padding-inline: 10px;
	margin: 0;
	height: 45px;
	transition: 50ms;
	// width: 100px;
}

.selectAccess {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	flex-basis: 50%;
	// width: 100px;
	cursor: pointer;
	background-color: $green;
	padding-inline: 10px;
	height: 45px;
	color: $white;
	margin: 0;
	transition: 50ms;
}

.textArea {
	position: relative;
	padding: 12px 20px;
	width: 100%;
	height: 100px;
	background-color: white;

	.mention {
		background-color: white;
		position: relative;
		color: $green;
		z-index: 50;
	}
}
