@import '../../../../styles/variables.scss';

.inputContainer {
	position: relative;
	background-color: transparent;
	border: 1px solid $lign;
	border-radius: 6px;
	background-color: $white;
	margin-top: $marginTopInput;
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;

	&:has(.inputAttendeeWrapper) {
		.attendeeInput {
			width: min-content;
			padding-top: 0;
		}
	}

	.inputAttendeeTodoWrapper {
		padding: 4px;
		margin-inline: 8px;
		display: flex;
		align-items: center;
		gap: 8px;
		margin-block: 6px;

		.inputWrapperSubtitle {
			font-size: 13px;
			color: $textGrey;
		}
	}

	.inputAttendeeWrapper {
		border: 1px solid $green;
		background-color: #00ad5636;
		color: $green;
		border-radius: 3px;
		padding: 4px;
		width: fit-content;
		margin-inline: 14px;
		display: flex;
		align-items: center;
		gap: 5px;
		font-size: 13px;
		margin-bottom: 3px;

		.attendeeIcons {
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&:first-child {
			margin-top: 30px;
		}
	}

	.meetingPlaceholder {
		position: absolute;
		top: 50%;
		left: 10px;
		font-size: 14px;
		color: grey;
		transform: translateY(-55%);
		// background-color: white;
		padding-inline: 5px;
		transition: 0.2s;
		pointer-events: none;
	}

	input {
		border: none;
		margin-top: 0 !important;
		padding-top: 30px;
		padding-bottom: 8px;
		transition: 0.1s;
	}

	.shrunk {
		top: 18px;
		font-size: 13px;
		color: $green;
		font-weight: 500;
	}

	input:focus+.meetingPlaceholder,
	input:not(:placeholder-shown)+.meetingPlaceholder {
		top: 18px;
		font-size: 13px;
		color: $green;
		font-weight: 500;
	}
}

.inputModal {
	position: absolute;
	z-index: 99;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: 100%;
	border-radius: 4px;
	top: 105%;
	max-height: 300px;
	overflow: auto;

	.titleShimmer {
		margin-bottom: 5px;
	}

	.inputMeetingModalItems {
		gap: 10px;
		display: flex;
		align-items: center;
		margin: 5px;
		cursor: pointer;
		padding: 15px;
		border-radius: 5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.inputModalItems {
		gap: 10px;
		display: flex;
		align-items: center;
		margin: 5px;
		cursor: pointer;
		padding: 15px;
		border-radius: 5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		.modalSubTitle {
			font-size: 13px;
			color: $textGrey;
		}

		&:hover {
			background-color: rgba(128, 128, 128, 0.05);
		}
	}
}

.inputIcon {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
}