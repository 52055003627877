@import '../../../../../../../styles/variables';

.bodyMeeting {
	overflow-y: auto;
	height: calc(100vh - 258px);
	padding-left: 32px;
	

	.link{
		display: flex;
		align-items: center;
		gap: 10px;

		a {
			text-decoration: none;
			color: $black;
			position: relative;
			font-weight: 600;

			&::after {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 0;
				width: 100%;
				height: 1.5px;
				background-color: $black;
			}
		}

		img{
			width: 20px;
		}
	}

	.row{
		display: flex;
		align-items: start;
		// flex-flow: wrap;
		width: 100%;
		padding-bottom: 23px;
		
		&:last-child {
			padding-bottom: 0;
		}

		.title{
			flex-basis: 30%;
			color: $textGrey;
			display: flex;
			align-items: center;
			gap: 13px;
			font-weight: 500;			
		}

		.icon {
			display: flex;
			align-items: center;
		}
	}

	.info{
		padding-top: 55px;
		padding-bottom: 50px;
		border-bottom: 1px solid $lign;
	}
}

.descriptionText {
	width: fit-content;
	flex-basis: 70%;
}

.slot{
	cursor: pointer;
	color: $green;

	&:hover{
		color: $greenHover
	}
}

.icon{
	font-size: 17px;
}

.descriptionGraph {
	margin-top: 30px;
	margin-bottom: 50px;
	width: calc(100vw - 494px);
	background-color: white !important;
}

.des{
	position: relative;
	display: flex;
	align-items: center;
	gap: 15px;
	font-weight: 500;

	.icon{
		cursor: pointer;
		color: $green;
		font-size: small;
		width: fit-content;
	}
}