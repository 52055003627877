@import '../../../../styles/variables.scss';

.invited {
	margin-top: 15px;
	padding-bottom: 5px;
}

h3 {
	display: flex;
	align-items: center;
	gap: 1rem;
	margin: 0;
}

.close {
	cursor: pointer;
	width: fit-content;
	position: absolute;
	right: 47px;
	top: 32px;
}

.flexAccess {
	display: flex;
	align-items: center;
	margin-top: 15px;
	border-radius: 6px;
	border: 1px solid $lign;
	width: fit-content;
	margin-bottom: 5px;
	overflow: hidden;
}

.access {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	border-radius: 6px;
	cursor: pointer;
	background-color: $white;
	height: 45px;
	margin: 0;
	min-width: 100px;
}

.selectAccess {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 100px;
	gap: 10px;
	cursor: pointer;
	background-color: $green;
	height: 45px;
	color: $white;
	margin: 0;
}

.iconPlus {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.avatarWrapper {
	display: flex;
	align-items: center;
	gap: 10px;
}

.avatar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.icon {
		margin-right: 15px;
	}
}

.iconSelect {
	border: 1px solid $lign;
	background-color: $white;
	border-radius: 6px;
	width: fit-content;
	padding-left: 8px !important;
	padding-right: 8px !important;
	padding-top: 6px !important;
	padding-bottom: 6px !important;
	margin-top: $marginTopInput;
	cursor: pointer;
	position: relative;
}

.addEmoticone {
	width: max-content;
	border: 1px solid $lign;
	background-color: $white;
	border-radius: 6px;
	margin-top: $marginTopInput;
	padding-inline: $paddingInlineInput;
	padding-block: 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	color: #707070;
	cursor: pointer;
	position: relative;
}

.addAttendeeContainer {
	background-color: $white;
	border-radius: 6px;
	padding-inline: $paddingInlineInput;
	padding-block: $paddingBlockInput;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	color: #707070;
	margin-top: $marginTopInput;
	cursor: pointer;

	.invite {
		display: flex;
		align-items: center;
		gap: 15px;
	}
}

.addAttendee {
	position: relative;
}

.iconZone {
	position: relative;
}

.modalPeople {
	position: absolute;
	z-index: 99;
	width: 50%;
	border-radius: 4px;
	left: 0;
	top: 105%;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding: 15px;

	.searchInput {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		color: $textNav;

		span {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
		}

		input {
			// font-size: medium;
			color: $textNav;
			padding-block: 8px;
			padding-inline: $paddingInlineInput;
			margin-top: 0;
		}
	}

	.peopleList {
		max-height: 320px;
		overflow: auto;
	}

	.avatar {
		display: flex;
		align-items: center;
		gap: 15px;
	}

	.row {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		padding-block: 10px;
		margin-block: 5px;
		padding-left: 5px;
		padding-right: 10px;
		border-radius: 4px;
		z-index: 10;

		cursor: pointer;

		.avatar {
			max-width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&:hover {
			background-color: rgba(128, 128, 128, 0.04);
		}

		input {
			width: 15px;
			margin: 0;
		}
	}

	button {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
}

.containerTags {
	margin-top: $marginTopInput;
	border: 1px solid $lign;
	border-radius: 6px;
	position: relative;
	padding-inline: $paddingInlineInput;

	.buttonTags {
		display: flex;
		align-items: center;
		border-radius: 6px;
		justify-content: space-between;
		cursor: pointer;
	}

	.tagsWrapper {
		display: flex;
		align-items: center;
		gap: 15px;
		width: 100%;
		padding-block: 20px;
	}

	p {
		color: #707070;
		margin: 0;
		padding: 0;
	}

	.tags {
		display: flex;
		align-items: center;
		flex-flow: wrap;
		gap: 15px;
		position: relative;

		.tag {
			display: flex;
			align-items: center;
			height: min-content;
			gap: 10px;
			padding: 5px;
			border-radius: 87px;

			.iconDelete {
				cursor: pointer;
				display: flex;
				align-items: center;
				margin: 0;
				padding: 0;
			}
		}
	}

	.icon {
		cursor: pointer;
		display: flex;
		align-items: center;
		height: 14px;
	}
}

.textEditor {
	border: 1px solid $lign;
	padding-inline: $paddingInlineInput;
	padding-block: $paddingBlockInput;
	margin-top: $marginTopInput;
	border-radius: 6px;
}

.buttons {
	gap: 1rem;
	margin-top: 16px;
	display: flex;
	justify-content: end;

	.addBtn {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
	}

	.cancel {
		background-color: transparent;
		color: $black;
		font-weight: 600;
		border: 1px solid #8a8886;

		&:hover {
			background-color: #ebebeb;
		}
	}
}

.emoticonBlock {
	display: flex;
	align-items: center;
	flex-flow: wrap;
	gap: 15px;
	height: 200px;
	overflow-y: auto;
	overflow-x: hidden;

	.emoticon {
		cursor: pointer;
		width: 8px;
	}
}

.addAttendee {
	cursor: pointer;
	border: 1px solid $lign;
	background-color: $white;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #707070;
	font-style: italic;
	position: relative;
	width: 100%;
	padding-right: 10px;

	input {
		margin-top: 0;
		border: none;
		width: 100%;
	}
}

.modalAttendee {
	position: absolute;
	z-index: 9;
	width: 100%;
	border-radius: 4px;
	left: 0;
	top: 103%;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);

	.searchInput {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		color: $textNav;

		span {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
		}

		input {
			// font-size: medium;
			color: $textNav;
			padding-block: 8px;
			padding-inline: $paddingInlineInput;
			margin-top: 0;
		}
	}

	.containerList {
		max-height: 300px;
		overflow-y: auto;
		cursor: pointer;
		color: rgba(0, 128, 0, 0);
		position: relative;
		z-index: 10;
	}

	.list {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		padding-block: 5px;
		margin-block: 5px;
		padding-left: 15px;
		padding-right: 15px;
		border-radius: 4px;
		z-index: 10;

		&:hover {
			background-color: rgba(128, 128, 128, 0.04);
		}

		.name {
			display: flex;
			align-items: center;
			gap: 10px;
			color: $black;
		}

		input {
			display: none;
			width: 15px;
			margin: 0;
		}

		i {
			height: 15px;
			color: black;
		}
	}

	button {
		padding: 0;
		padding-left: 13px;
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		background-color: transparent;
		color: $green;
		border: none;

		&:hover {
			background-color: transparent;
		}
	}
}

.attendees {
	margin-top: $marginTopInput;
	position: relative;

	.flex {
		padding-top: $marginTopInput;
		margin-bottom: calc($marginTopInput * 2);
		padding-right: 14px;
		padding-left: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.name {
			display: flex;
			align-items: center;
			gap: 15px;
			margin: 0;
		}
	}

	select {
		width: 100px;
		border: none;
		background-color: $inputBg;
		margin-top: 9px;

		&:focus {
			outline: none;
		}
	}

	button {
		margin-top: 10px;
	}

	.close {
		color: $black;
		cursor: pointer;
	}
}
