@import '../../../../../../styles/variables';

.headerMeeting {
	padding-left: 32px;
	padding-right: 48px;
	min-height: 143.5px;
	padding-top: 15px;
	padding-bottom: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: 1px solid $lign;

	h1 {
		padding: 0;
		margin: 0;
		font-size: 20px;
		font-weight: 600 !important;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 1000px;
	}

	.flexTitleBtn {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.flex {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;

		@media screen and (max-width: 820px) {
			display: block;
		}
	}
}
