@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

@-webkit-keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

// ---------------

.main {
	display: flex;

	@media screen and (max-width: 900px) {
		display: block;
	}

	.editor {
		width: 100%;

		-ms-overflow-style: none;
		scrollbar-width: none;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			display: none;
		}

		@media screen and (max-width: 900px) {
			border: none;
			width: 90%;
		}

		@media screen and (max-width: 900px) {
			height: calc(100vh - 120px);
		}

		.icon {
			padding: 0;
			margin: 0;
			cursor: pointer;
			display: flex;
			align-items: center;
			margin-right: 15px;
		}

		.inputTitle {
			font-weight: 600;
			margin: 0;
			font-size: 20px;
			padding-top: 12px !important;
			padding-bottom: 12px !important;
			margin-left: -12px;
		}

		.data {
			display: flex;
			gap: 40px;
			flex-flow: wrap;
		}
	}

	.icon {
		width: 24px;
		cursor: pointer;
	}

	.iconMob {
		width: 24px;
		cursor: pointer;
		display: none;

		@media screen and (max-width: 500px) {
			display: block;
		}
	}

	.tags {
		display: flex;
		align-items: center;
		gap: 15px;
		position: relative;

		.tag {
			display: flex;
			align-items: center;
			gap: 7px;
			padding-block: 5px;
			padding-left: 16px;
			padding-right: 10px;
			border-radius: 87px;

			.iconDelete {
				display: flex;
				align-items: center;
				cursor: pointer;
				font-size: 10px;
			}
		}
	}
}

.save {
	background-color: $buttonLight;
	color: white;
	padding: 2px 5px 2px 5px;
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	position: absolute;
	right: 10px;
	top: 10px;
}

.meeting {
	width: 280px;
}

.access {
	display: flex;
	align-items: center;
	gap: 10px;
	position: relative;

	.companiesName {
		display: flex;
		gap: 5px;
	}
}

.containeUpdate {
	position: absolute;
	top: 40px;
	z-index: 9;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: 250px;

	form {
		margin: 10px;

		.inputMain {
			display: flex;
			align-items: center;
			gap: 10px;

			input {
				width: 15px;
			}

			label {
				font-weight: 600;
			}
		}

		hr {
			width: 90%;
			background-color: $lign;
			opacity: 0.2;
		}

		.search_input {
			background-color: $inputBg;
			width: auto;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			padding-left: 10px;
			color: $textNav;
			margin: 5px;

			input {
				width: 100px;
				font-size: medium;
				color: $textNav;
			}
		}

		.compagnies {
			display: flex;
			flex-direction: column;
			justify-content: right;
			align-items: flex-start;
			max-height: 200px;
			margin-bottom: 10px;
			overflow: hidden;
			overflow-y: auto;
			text-transform: capitalize;
		}

		button {
			width: 100%;
		}
	}
}

.meeting {
	padding-right: 20px;
	padding-left: 20px;
	padding-bottom: 20px;

	.slot {
		margin-bottom: 15px;
		cursor: pointer;
		background-color: #f2fcf7;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 5px;

		.close {
			display: none;
		}

		&:hover {
			.close {
				display: block;
			}
		}
	}

	.flex {
		display: flex;
		align-items: center;
		gap: 5px;
	}

	.more {
		color: $green;
		font-size: 20px;
		margin-left: 15px;
		cursor: pointer;
	}

	.add {
		color: $green;
		cursor: pointer;
	}
}

.contentNoteWrapper {
	overflow: auto;
	height: calc(100vh - 209px);
	padding-bottom: 30px;
}

.noteInformationsWrapper {
	// overflow: hidden;
	transition: 0.3s ease-in-out;

	.informationsArrow {
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s ease-in-out;
	}
}

.notesWrapper {
	overflow: auto;
	height: calc(100vh - 209px);
}

.noteDetailsArrow {
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s;
}

.noteDetails {
	// overflow: hidden;
	transition: 0.2s ease-in-out;
}

.modalAttendee {
	position: absolute;
	z-index: 99;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding: 10px;
	width: 320px;

	.searchInput {
		height: 45px;
		width: 97%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		padding-left: 10px;
		color: $textNav;
		margin-bottom: 20px;
		background-color: $white;

		span {
			height: 45px;
			display: flex;
			align-items: center;
		}

		input {
			font-size: medium;
			color: $textNav;
			margin: 0;
		}
	}

	.containerList {
		max-height: 300px;
		overflow-y: auto;

		.slot {
			margin: 15px;
			cursor: pointer;
			background-color: #f2fcf7;
			padding: 15px;
			border-radius: 5px;
			width: 88%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.more {
	position: relative;
}

.options {
	position: absolute;
	z-index: 9;
	background-color: white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding-right: 10px;
	right: 0;
	top: 0;
	margin-top: 30px;
	width: 150px;

	.remove {
		color: red;
		margin: 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.confirmBox {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	div {
		margin: auto;
		margin-top: 300px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;

		button {
			margin: 10px;
		}
	}
}

.back {
	color: $button;
	cursor: pointer;
}

.buttonLinkCopied {
	display: flex;
	width: 124px;
	padding: 5px 5px 7px 5px;
	justify-content: center;
	align-items: center;
	gap: 3px;
	border-radius: 52px;
	border: 0.5px solid #00ad56;
	background: var(--grey-palette-white, #fff);
	box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);

	color: var(--theme-primary, #00ad56);
	text-align: center;
	font-family: Segoe UI;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;

	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	position: absolute;
	right: 10px;
	top: 10px;

	span {
		border: 1px solid #00ad56;
		border-radius: 100%;
		width: 20px;
		height: 20px;
		color: #00ad56;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.textEditor {
	position: relative;
	margin-inline: 32px;
	margin-top: 30px;
	padding: 5px;
	border: 1px solid $lign;
	border-radius: 4px;

	&:last-child {
		margin-bottom: 20px;
	}
}

.addPeople {
	color: $green;
	cursor: pointer;
	margin-top: 15px;
	margin-bottom: 15px;
	width: fit-content;
}

.modalPeople {
	position: absolute;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding: 20px;
	width: 300px;
	bottom: 0;
	left: 0px;

	.peopleList {
		height: 200px;
		overflow: auto;
	}

	.avatar {
		display: flex;
		align-items: center;
		gap: 15px;
	}

	.row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		input {
			width: 20px;
		}
	}

	button {
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
}

.rowPeople {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.delete {
		cursor: pointer;
	}

	.avatar {
		display: flex;
		align-items: center;
		gap: 10px;
	}
}

.noteHeader {
	display: flex;
	align-items: center;
	gap: 5px;
	padding-inline: 32px;
	margin-top: 12px;

	input {
		border: 1px solid $white;

		&:hover {
			border: 1px solid $lign;
		}
	}
}

.attendees {
	padding-inline: 32px;
	padding-bottom: 17px;
	border-bottom: 1px solid $lign;
}

.mainNoteInformation {
	padding-inline: 32px;
	margin-top: 32px;

	.row {
		display: flex;
		align-items: center;
		padding-bottom: 21px;
		font-weight: 500;

		.title {
			width: 200px;
			color: $textGrey;
			display: flex;
			align-items: center;
			gap: 15px;
		}

		.avatar {
			display: flex;
			align-items: center;
			gap: 15px;
		}
	}
}

.show {
	display: flex;
	align-items: center;
	gap: 15px;
	font-size: 14px;
	font-weight: 500;
	margin-top: 30px;
	margin-inline: 32px;
	cursor: pointer;
}

.accessButton {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	width: fit-content;
	background-color: #231f20;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 12px;
	padding-right: 12px;
	border-radius: 87px;
	color: $white;
}

.link {
	color: $green;
	cursor: pointer;
	margin-right: 15px;

	&:hover {
		color: $greenHover;
	}
}

.noNotes {
	margin: auto;
	margin-top: 200px;
	text-align: center;

	h2 {
		font-weight: 400;
		font-size: 32px;

		@media screen and (max-width: 1040px) {
			font-size: x-large;
		}

		@media screen and (max-width: 600px) {
			font-size: medium;
		}

		@media screen and (max-width: 370px) {
			font-size: small;
		}
	}

	button {
		padding: 6px, 20px, 6px, 20px;

		span {
			display: flex;
			align-items: center;
			gap: 10px;

			span {
				font-size: medium;
			}
		}
	}
}

.searchInput {
	height: 45px;
	width: 90%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	padding-left: 10px;
	color: $textNav;
	margin-bottom: 20px;

	span {
		height: 45px;
		display: flex;
		align-items: center;
	}

	input {
		font-size: medium;
		color: $textNav;
		margin: 0;
	}
}

.peopleListShared {
	padding-top: 10px;
	height: 100px;
	overflow: auto;
}
