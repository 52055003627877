@import '../../../../styles/variables';

a {
	text-decoration: none;
}

.avatar {
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 15px;
	font-weight: 500;
}

.dropDownAccount {
	.container {
		position: absolute;
		z-index: 999;
		background-color: white;
		box-shadow: 0 0 6px 0px rgba(200, 198, 196, 0.8);
		top: 53px;
		right: 5px;
		border-radius: 4px;
		list-style-type: none;
		padding: 10px;
		margin: 0;
		font-size: small;
		color: $black;
		width: 320px;

		.userInfos {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.infoAccount {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 20px;
			padding-left: 20px;
			padding-right: 30px;
		}

		.name {
			font-weight: 500;
			font-size: x-large;
		}

		.mail {
			color: $greyMail;
			font-weight: 600;
			margin-bottom: 5px;
		}

		.viewAccount {
			color: $green;
			font-weight: 400;
			cursor: pointer;
		}

		.signOut {
			cursor: pointer;
			float: right;
			font-weight: 400;
		}

		.avatar {
			cursor: default;
			width: 60px;
		}
	}
}

.header {
	width: 100%;
	height: $header-height;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid $lign;
	position: relative;

	.searchInput {
		background-color: $inputBg;
		height: 32px;
		width: 331.5px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		padding-left: 10px;
		padding-right: 10px;
		color: $textNav;

		@media screen and (max-width: 550px) {
			width: 200px;
		}

		@media screen and (max-width: 360px) {
			width: 150px;
		}

		span {
			height: 30px;
			display: flex;
			align-items: center;
		}

		input {
			font-size: medium;
			color: $textNav;
			margin: 0;
		}

		input::placeholder {
			color: $textNav;
			opacity: 0.3;
		}
	}

	.leftPart {
		display: flex;
		align-items: center;
		gap: 30px;

		.trialPlan {
			display: flex;
			gap: 15px;

			.linkTrial {
				color: $green;
				font-weight: 500;
			}

			.bold {
				font-weight: 700;
			}
		}
	}

	.rightPart {
		display: flex;
		align-items: center;
		gap: 25px;
		margin-right: 15px;
		font-weight: 100;
	}
}

.icons {
	cursor: pointer;
}

.sidebarMobile {
	display: none;
	background-color: $lightGrey;
	margin-right: 10px;
	width: 64px;
	height: 83px;
	z-index: 9;

	@media screen and (max-width: 500px) {
		display: block;
	}

	.logo {
		z-index: 2;
		text-align: center;
		height: 48px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 17px;
		border-bottom: 1px solid $lign;
		border-right: 1px solid $lign;

		img {
			width: 25px;
		}
	}

	.menuBurger {
		text-align: center;
		margin: 0;
		padding: 0;
		font-size: 14px;
		background-color: $lightGrey;
		height: 50px;
		z-index: 9;
	}
}

.modalMenu {
	position: absolute;
	z-index: 9;
	width: 100%;
	height: 85%;
	background-color: white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: $black;
	margin: 0;
	padding: 0;

	.menuTop {
		margin-top: 20px;
	}

	.iconClose {
		float: right;
		padding-right: 20px;
	}

	.menu {
		margin-top: 40px;
	}

	.menuList {
		height: 64px;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 10px;
		padding-left: 20px;
		font-weight: 500;

		&:hover {
			background-color: #edebe9;
			color: $green;
		}
	}

	.buttonCreate {
		span {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 15px;

			span {
				font-size: 20px;
			}
		}

		height: 48px;
		width: 90%;
		margin-bottom: 10px;
	}
}

.modalSearch {
	position: absolute;
	width: 352px;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	z-index: 9;
	max-height: 500px;
	overflow: hidden;
	overflow-y: auto;

	@media screen and (max-width: 550px) {
		width: 220px;
	}

	.viewFilter {
		display: flex;
		align-items: center;
		flex-flow: wrap;
		gap: 10px;
		padding-left: 10px;
		padding-top: 10px;

		.filter {
			font-weight: 500;
		}

		.info {
			color: #8a8886;
			font-weight: 300;
		}
	}

	.hits {
		color: #a19f9d;
		font-weight: 400;
		padding-left: 10px;
	}

	.listItem {
		display: flex;
		align-items: center;
		gap: 15px;
		color: $green;
		cursor: pointer;
		padding-left: 10px;

		p {
			color: $black;
		}

		&:hover {
			background-color: #faf9f8;
		}
	}
}

.icon {
	height: 24px;
	font-size: 16px;
	color: $black;
	display: flex;
	align-items: center;
}

.iconNotif {
	height: 24px;
	position: relative;
	color: $black;
	font-size: 19px;
	display: flex;
	align-items: center;
}

.nbrNotif {
	position: absolute;
	outline: 2px solid white;
	top: -4px;
	right: -5px;
	font-size: 11px;
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	padding: 1px;
	background-color: $red;
	width: 17px;
	height: 17px;
	border-radius: 100%;
	color: $white;
}

.meetings {
	min-width: 360px;
	background-color: $greyBackground;
	height: calc($header-height + 2px);
	border-right: 1px solid $lign;

	@media screen and (max-width: 800px) {
		background-color: transparent;
		border: none;
		min-width: 15px;
	}
}

.logo {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	img {
		width: 15px;
	}
}
