@import '../../../../styles/variables';

.timeFLex {
	display: flex;
}

form {
	color: $black;

	label {
		font-weight: 500;
	}

	.duration {
		.inputs {
			display: flex;
			align-items: center;
			gap: 10px;
		}

		input {
			width: 50px;
		}

		select {
			width: 70px;
			margin-top: 8px;
		}
	}

	.isOnline {
		padding-top: 1.5rem;
		padding-bottom: calc(1.5rem - $marginTopInput);
		padding-inline: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.teams {
			display: flex;
			align-items: center;
			gap: 0.75rem;
			font-weight: 500;

			img {
				width: 23px;
			}
		}

		select {
			border: none;
			background-color: $inputBg;

			&:focus {
				outline: none;
			}
		}
	}
}

.switch {
	position: relative;
	display: inline-block;
	width: 34px;
	height: 21.5px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		transition: 0.2s;
		border-radius: 34px;

		&::after {
			position: absolute;
			content: '';
			height: 17px;
			width: 17px;
			left: 2px;
			bottom: 2.5px;
			background-color: white;
			transition: 0.2s;
			border-radius: 50%;
		}
	}

	input:checked+.slider {
		background-color: $green;
	}

	input:checked+.slider::after {
		-webkit-transform: translateX(13px);
		-ms-transform: translateX(13px);
		transform: translateX(13px);
	}
}

.addAttendee {
	padding-right: 14px;
	cursor: pointer;
	border: 1px solid $lign;
	background-color: $white;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #707070;
	font-style: italic;
	position: relative;

	input {
		margin-top: 0;
		border: none;
	}
}

.modalAttendee {
	position: absolute;
	z-index: 9;
	width: 100%;
	border-radius: 4px;
	left: 0;
	top: 103%;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);

	.searchInput {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		color: $textNav;

		span {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
		}

		input {
			// font-size: medium;
			color: $textNav;
			padding-block: 8px;
			padding-inline: $paddingInlineInput;
			margin-top: 0;
		}
	}

	.containerList {
		max-height: 300px;
		overflow-y: auto;
		cursor: pointer;
		color: rgba(0, 128, 0, 0);
		position: relative;
		z-index: 10;
	}

	.list {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		padding-block: 5px;
		margin-block: 5px;
		padding-left: 15px;
		padding-right: 15px;
		border-radius: 4px;
		z-index: 10;

		&:hover {
			background-color: rgba(128, 128, 128, 0.04);
		}

		.name {
			display: flex;
			align-items: center;
			gap: 10px;
			color: $black;
		}

		input {
			display: none;
			width: 15px;
			margin: 0;
		}

		i {
			height: 15px;
			color: black;
		}
	}

	button {
		padding: 0;
		padding-left: 13px;
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		background-color: transparent;
		color: $green;
		border: none;

		&:hover {
			background-color: transparent;
		}
	}
}

.agenda {
	margin-bottom: 15px;
	position: relative;
}

.agendaMeetings {
	overflow-x: auto;

	height: 300px;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	.week {
		display: flex;
		width: 100%;

		.day {
			border-right: 1px solid $lign;
			position: relative;
			width: 100%;

			.meeting {
				height: 59px;
				width: 100%;
				border-bottom: 1px solid $lign;
			}
		}
	}
}

.dayHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px;
	background: var(--grey-palette-grey-20, #f3f2f1);
	padding-inline: 12px;
	padding-block: 8px;

	.daySlot {
		text-align: center;
		cursor: pointer;
		color: #201f1e;
		text-align: center;
		font-family: Segoe UI;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 14px;
	}
}

.invitePeople {
	position: relative;

	.iconColor {
		position: absolute;
		top: 45%;
		right: 14px;
		color: black;
	}
}

.datePicker {
	position: absolute;
	z-index: 9;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	top: 105%;
	left: 0;
	border-radius: 6px;
	overflow: hidden;

	.confirm {
		width: 100%;
		border-radius: 0;
	}

	.hoursPicker {
		input {
			width: 50px;
		}

		select {
			width: 65px;
			border-radius: 6px;
		}
	}
}

.transition {
	transition: 0.3s;
}

.hourPicker {
	position: absolute;
	z-index: 9;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	top: 110%;
	left: 0px;
	border-radius: 6px;
	width: 100%;

	.confirm {
		width: 100%;
	}

	.hoursPicker {
		display: flex;
		justify-content: space-between;
		padding-block: 15px;

		input {
			width: 50px;
		}

		select {
			width: 65px;
			padding-inline: 10px;
			padding-block: 8px;
			margin: 0;
			border: 1px solid rgba(0, 0, 0, 0.07);
			border-radius: 6px;
		}
	}
}

.timeslotContainer {
	margin-top: $marginTopInput;
}

.timeslot {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;

	&:nth-child(2) {
		margin-top: $marginTopInput;
	}
}

.hourPicker {
	padding: 15px;
	margin: 0 auto;
	text-align: center;

	.confirm {
		width: 100%;
	}

	.hoursPicker {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-top: -20px;

		input {
			width: 50px;
		}

		select {
			width: 50%;
		}
	}
}

.datePickerTime {
	display: flex;
	align-items: center;
	gap: 15px;
	font-weight: 500;
	color: $black;

	.icon {
		cursor: pointer;
		margin: 0;
		padding: 0;
		margin-top: 4px;
	}
}

.textEditor {
	border: 1px solid $lign;
	background-color: $white;
	border-radius: 6px;
	margin-top: $marginTopInput;
	padding-inline: $paddingInlineInput;
	padding-block: $paddingBlockInput;
	color: #707070;

	p {
		padding-bottom: $paddingBlockInput;
	}
}

.linkMeeting {
	position: relative;
	background-color: $white;

	.iconColor {
		position: absolute;
		top: 45%;
		right: 14px;
		color: black;
	}
}

.connectedMeeting {
	position: relative;

	.meetingSubject {
		position: absolute;
		top: 38%;
		left: 40%;
		background-color: #f2fcf7;
		padding-inline: 10px;
		padding-block: 5px;
		border-radius: 6px;
	}
}

.projectModal {
	position: absolute;
	z-index: 99;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: 100%;
	border-radius: 4px;
	top: 105%;

	.projectModalItems {
		margin: 5px;
		cursor: pointer;
		padding: 15px;
		border-radius: 5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			background-color: $green;
			color: white;
		}
	}
}

.repeat {
	border: 1px solid $lign;
	background-color: $white;
	border-radius: 6px;
	max-width: 200px;
	padding-left: 8px !important;
	padding-right: 8px !important;
	padding-top: 6px !important;
	padding-bottom: 6px !important;
	color: #707070;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	p {
		margin: 0;
		padding: 0;
	}
}

.startDate {
	border: 1px solid $lign;
	background-color: $white;
	border-radius: 6px;
	width: 70%;
	padding-inline: $paddingInlineInput;
	padding-block: 7px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	gap: 10px;
	cursor: pointer;
	transition: .1s background-color;

	&:hover {
		background-color: $lightGrey;
	}

	.startDateButton {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		cursor: pointer;
	}

	.dateTitle {
		font-size: 13px;
		color: $green;
		font-weight: 600;
		display: inline-block;
		margin-bottom: 6px;
	}

	span {
		font-weight: 500;
		color: #707070;
	}

	.icon {
		cursor: pointer;
	}

	&:nth-child(2) {
		width: 40%;
		padding-block: 14.5px;
	}
}

.tags {
	display: flex;
	align-items: center;
	flex-flow: wrap;
	position: relative;
	gap: 5px;

	.tag {
		display: flex;
		align-items: center;
		gap: 7px;
		padding-block: 5px;
		padding-inline: 10px;
		border-radius: 87px;

		.iconDelete {
			cursor: pointer;
			display: flex;
			align-items: center;
			font-size: 11px;
			margin: 0;
			padding: 0;
		}
	}
}

.icon {
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-left: 5px;
}

.containerTags {
	cursor: pointer;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid $lign;
	padding-block: 18px;
	padding-inline: $paddingInlineInput;
	margin-top: $marginTopInput;
	border-radius: 6px;

	.containerTagsButton {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	p {
		color: #707070;
		display: flex;
		align-items: center;
	}

	.tagsWrapper {
		display: flex;
		gap: 15px;
		width: 100%;
	}
}