@import '../../../../../../styles/variables';

.meetingTopButton {

	position: relative;

	.options {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 10px;
		width: 100%;
		color: $icon;

		@media screen and (max-width: 800px) {
			justify-content: flex-start;
		}

		@media screen and (max-width: 580px) {
			margin-bottom: 15px;
		}
	}
}

.buttonTeams {
	display: flex;
	align-items: center;
	background-color: transparent;
	border: 1px solid #231F20;
	padding-inline: 24px;
	padding-block: 10px;
	font-size: 16px;
	font-weight: 500;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	gap: 6px;

	a {
		text-decoration: none;
		color: $black;
		font-weight: 500;
	}

	img {
		width: 21px;
	}

	&:hover{
		background-color: #EBEBEB;
		border-color: black;
	}
}

.pastTeams {
	display: flex;
	align-items: center;
	background-color: transparent;
	border: 1px solid #231F20;
	padding-inline: 24px;
	padding-block: 10px;
	font-size: 16px;
	font-weight: 500;
	border-radius: 4px;
	color: $black;
	cursor: pointer;
	transition: all 0.5s ease-in-out;
	gap: 8px;
	opacity: 0.5;

	a {
		text-decoration: none;
		color: $black;
		font-weight: 500;
	}

	img {
		width: 21px;
	}

	&:hover{
		background-color: #EBEBEB;
	}
}

.buttonStart {
	min-width: max-content;
	height: 23px;
	padding-top: 2px !important;
	padding-bottom: 5px !important;
	padding-right: 8px !important;
	padding-left: 8px !important;
	cursor: default;
}

.icon {
	cursor: pointer;
	font-size: 16px;
	line-height: 16px;
	color: #231F20;
	border: 1.5px solid #231F20;
	border-radius: 3px;
	padding-inline: 16px;
	font-size: 18px;
	padding-block: 11.5px;
	border-radius: 4px;
	transition: 100ms;

	&:hover{
		background-color: #EBEBEB;
	}
}

.containerSharing {
	position: fixed;
	z-index: 9;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: 250px;
	padding: 20px;
	top: 100px;
	color: $black;

	.cancel {
		background-color: $white;
		color: $black;
		font-weight: 600;
		border: 1px solid $black;

		&:hover{
			background-color: #EBEBEB;
		}
	}

	.send {
		margin-top: 20px;
		max-height: 200px;
		overflow-y: auto;

		.mail {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 8px;
			margin-right: 15px;

			button {
				padding: 2px !important;
				padding-left: 3px !important;
				padding-right: 3px !important;
			}
		}
	}

	.link {
		margin-top: 15px;
		text-align: center;

		// .linkContain {
		// 	position: absolute;
		// 	padding: 20px;
		// 	width: 300px;
		// 	background-color: $white;
		// 	box-shadow: 0 0 6px 0px rgb(200 198 196);
		// }
	}
}


.sendButton{
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-size: 16px;
	padding-inline: 24px;
	padding-block: 11px;
	border-radius: 4px;
	border: 1.5px solid $green;
	transition: .1s;

	i {
		font-size: 18px;
	}
}