@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.overviewList {
	width: 100%;
	padding-top: 30px;
	// height: calc(100vh - $header-height);
	// overflow: hidden;

	.viewHeader {
		position: relative;
		z-index: 100;
		padding-bottom: 10px;
	}

	.cat {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 15px;
		color: var(--grey-palette-grey-190, #201f1e);
		font-family: Segoe UI;
		font-size: 15px;
		font-style: normal;
		font-weight: 700;
		line-height: 21px;
		padding-bottom: 10px;
		padding-top: 15px;
	}

	.titleCat {
		display: flex;
		align-items: center;
		gap: 15px;
		cursor: pointer;

		.number {
			padding-block: 2px;
			padding-inline: 6px;
			border-radius: 4px;
			font-size: 13px;
			color: grey;
			background-color: $lightGrey;
		}
	}

	.top {
		padding-inline: 32px;
		display: flex;
		align-items: start;
		gap: 10px;
		flex-flow: wrap;

		.button {
			button {
				padding-block: 12px;
			}
		}

		.icons {
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $lign;
			border-radius: 4px;
			font-size: 16px;

			.iconSelect {
				background-color: #2e4552;
				color: #dcdfe3;
				cursor: pointer;
				height: 43.5px;
				width: 43.5px;
				display: flex;
				align-items: center;
				justify-content: center;

				&:nth-child(1) {
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
				}

				&:nth-child(4) {
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
				}
			}

			.icon {
				background-color: transparent;
				color: #2e4552;
				cursor: pointer;
				height: 43.5px;
				width: 43.5px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	h1 {
		text-align: left;
		margin: 0;
		padding: 0;
		margin-bottom: 23px;
		font-size: 24px;
		font-weight: 800 !important;
	}

	.plus {
		border-radius: 50%;
		border: none;
		background-color: $greyBackground;
		font-size: 14px;
		padding: 5px;
		font-weight: 500;
		width: max-content;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.tags {
		display: flex;
		align-items: center;
		gap: 10px;
		position: relative;

		.tag {
			font-weight: 500 !important;
			padding-inline: 24px;
			padding-block: 4px;
			font-size: 14px;
			border-radius: 87px;
		}
	}
}

.listViewContainer {
	position: relative;
	padding-inline: 24px;
	height: 100%;
	overflow-y: auto;
}

.options {
	position: absolute;
	z-index: 9;
	background-color: white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding-right: 10px;
	right: 50px;
	margin-top: 22px;

	.remove {
		color: red;
		margin: 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.confirmBox {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	div {
		margin: auto;
		margin-top: 300px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;

		button {
			margin: 10px;
		}
	}
}

.meeting {
	color: $button;
	display: flex;
	align-items: center;
	gap: 5px;
}

.linkMeeting {
	cursor: pointer;
	text-decoration: underline;
	color: $green;
	font-weight: 500;

	&:hover {
		color: $greenHover;
	}
}

.modalAttendees {
	position: absolute;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: max-content;
	padding: 15px;
	top: 50px;
	z-index: 9;
	max-height: 500px;
	overflow-y: auto;

	.attendee {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
	}
}

.noMeetings {
	margin: auto;
	margin-top: 200px;
	text-align: center;
}

.todoButton {
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 18px;
	position: relative;
	padding-top: 18px;
	padding-bottom: 18px;

	@media screen and (max-width: 430px) {
		flex-flow: wrap;
	}

	.showComp {
		margin: 0;
		padding: 0;
		color: #00ad56;
		font-family: Segoe UI;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		text-decoration-line: underline;
		cursor: pointer;
	}
}

.titleTodoButton {
	position: absolute;
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 18px;
	padding: 0;

	@media screen and (max-width: 430px) {
		display: block;
	}
}

.form {
	border-top: 1px solid $lign;
	width: 100%;
	margin: 0;
	padding: 0;
	position: fixed;
	bottom: 0;
	left: 70px;
	padding-left: 20px;
	background-color: $white;
	padding-bottom: 20px;

	@media screen and (max-width: 820px) {
		width: 90%;
	}

	@media screen and (max-width: 800px) {
		width: 90%;
	}

	@media screen and (max-width: 500px) {
		display: none;
	}

	p {
		font-weight: 500;
		font-size: 16px;
	}

	form {
		margin: 0;
		padding: 0;
		width: 90%;
		display: flex;
		align-items: center;
		flex-flow: wrap;
		gap: 20px;

		input {
			// border: 1px solid $lign;
			width: 340.95px;
			height: 24px;
			padding: 0;
			margin: 0;
		}

		select {
			// border: 1px solid $lign;
			width: 200px;
			height: 36px;
		}

		button {
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}
}

.selectCustom {
	position: relative;

	button {
		background-color: $inputBg;
		border: 1px solid $lign;
		color: $black;
		width: 150px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;

		&:hover {
			background-color: #ebebeb;
		}
	}

	.dropdown {
		background-color: white;
		min-width: 150px;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		position: absolute;
		height: 100px;
		overflow: auto;
		top: -105px;

		p {
			padding: 0;
			margin: 0;
			padding: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 14px;

			&:hover {
				background-color: $lightGreen;
				color: $green;
			}
		}
	}
}

.button {
	button {
		display: flex;
		align-items: center;
		gap: 15px;
	}
}

.flex {
	display: flex;
	align-items: center;
	gap: 15px;
	padding: 0;
	margin: 0;

	.todoChekbox {
		display: flex;
		align-items: flex-start;
		opacity: 0;
		transition: 0.3s;
	}

	input {
		flex-basis: 18px;
		width: 18px;
		height: 24px;
		padding: 0;
		margin: 0;
	}

	p {
		flex-basis: 100%;
		padding: 0;
		margin: 0;
	}
}

.titleTodoInput {
	white-space: wrap;
	font-size: 14px;
	padding-right: 20px !important;
}

.filters {
	display: flex;
	align-items: center;
	gap: 15px;
	padding-inline: 32px;
	padding-bottom: 30px;
	position: relative;

	.place {
		position: relative;
	}

	.filterModalWrapper {
		position: absolute;
		top: 110%;
		left: 0;
	}

	.buttonFilter {
		position: relative;
		background-color: #f7f7f7;
		border-radius: 4px;
		padding-inline: 24px;
		padding-block: 12px;
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 8px;
		font-weight: 500;
		cursor: pointer;
		border: 1px solid #f7f7f7;
		transition: 100ms;

		&:hover {
			background-color: #ebebeb;
			border: 1px solid $black;
		}
	}

	.activeButton {
		position: relative;
		border-radius: 4px;
		padding-inline: 24px;
		padding-block: 12px;
		font-weight: 500;
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 8px;
		cursor: pointer;
		border: 1px solid $black;
		background-color: #ebebeb;
	}
}

.overflow {
	width: 100%;
	overflow: auto;
	padding-bottom: 15px;
}

.flexHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-basis: 450px;
	flex-shrink: 0;
	padding-right: 10px;
	border-right: 1px solid $lign;
	gap: 15px;

	i {
		padding-right: 40px;
		font-size: 14px;
		display: flex;
		align-items: center;
		color: #2e4552;
	}
}

.headTitle {
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 30;
	display: grid;
	padding-inline: 20px;
	height: 45px;
	align-items: stretch;
	flex-shrink: 1;
	border-top: 1px solid $lign;
	border-bottom: 1px solid $lign;
	font-weight: 500;

	.title {
		display: flex;
		align-items: center;
		font-weight: 400;
		gap: 10px;
		width: 100%;
		font-size: 14px;
		color: $textGrey;
		padding-left: 10px;
	}

	td {
		font-size: medium;
		padding-block: 18px !important;
	}
}

.todoState {
	display: grid;
	grid-template-columns: 10px auto;
	align-items: center;
	justify-content: center;
	padding-block: 4px;
	padding-inline: 20px;
	border-radius: 24px;
	gap: 7px;
	width: fit-content;
}

.todoLayout {
	background-color: rgba(128, 128, 128, 0.1);
	color: gray;
}

.doingLayout {
	background-color: #00707825;
	color: #007078;
}

.doneLayout {
	background-color: $lightGreen;
	color: $green;
}

.bloquedLayout {
	background-color: #b02c2825;
	color: #b02d28;
}

.todoStateIcon {
	height: 10px;
	width: 10px;
	border-radius: 100%;
}

.todoIcon {
	background-color: gray;
}

.doingIcon {
	background-color: #007078;
}

.doneIcon {
	background-color: $green;
}

.bloquedIcon {
	background-color: #b02d28;
}

.addTodoContainer {
	border-bottom: 1px solid $lign;
	padding-left: 24px;
	padding-inline: 20px;

	input {
		border: none;
		margin: 0 !important;
	}
}

.todoRow {
	display: grid;
	align-items: stretch;
	padding-inline: 20px;
	min-height: 25px;
	border-radius: 4px;
	transition: 0.2s;
	border-bottom: 1px solid $lign;

	&:hover {
		background-color: $lightGrey;

		.titleTodo {
			background-color: $lightGrey;
		}

		.todoChekbox {
			opacity: 1;
		}

		.titleTodoIcon {
			opacity: 1;
		}

		.dotMenuContainer {
			opacity: 1;
		}
	}
}

.filterModalTitle {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 20px;
}

.statusDropDownContainer {
	display: flex;
	flex-direction: column;
	gap: 2px;
	width: 250px;
	padding: 5px;

	.statusDropDownItem {
		border-radius: 6px;
		padding: 7px;

		&:hover {
			background-color: $lightGrey;
		}
	}
}

.filterContainer {
	--size: 40px;
	--fontSizeIcon: 14px;
	margin-inline: 32px;
	height: var(--size);
	display: flex;
	align-items: stretch;
	justify-content: space-between;

	.leftFilterContainer {
		display: flex;
		gap: 5px;

		.searchBarContainer {
			display: flex;
			gap: 5px;

			.searchBar {
				display: flex;
				align-items: center;
				border-radius: 4px;
				transition: 0.2s ease-in-out;
				border: 1px solid transparent;
				width: 0px;
				padding-inline: 0px;

				&.active {
					border-color: $lign;
					width: 250px;
					padding-inline: 12px;
				}

				.inputSearchBar {
					padding: 0;
					margin: 0;
					border: none;
				}
			}
		}
	}

	.rightFilterContainer {
		display: flex;
		gap: 5px;
	}

	.filterIcon {
		position: relative;
		display: flex;
		align-items: center;
		gap: 5px;
		cursor: pointer;
		@include center-flex(flex);
		min-width: var(--size);
		font-size: var(--fontSizeIcon);
		border-radius: 4px;
		transition: 0.1s;
		background-color: $lightGrey;
		border: 1px solid transparent;
		transition: 0.1s;

		&:has(p) {
			padding-inline: 18px;
		}

		&:hover {
			border-color: $lign;
		}
	}
}

.moreFilterContainer {
	position: relative;
}

.moreFiltersBtn {
	position: relative;
	margin-top: 20px;
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 10px;
	width: fit-content;
	border-radius: 6px;
	font-size: 14px;

	.moreFilterBtnTitle {
		font-weight: 500;
	}

	&:hover {
		background-color: $lightGrey;
	}
}

.quickSearchTitle {
	font-size: 14px;
	color: $textGrey;
}

.quickSearchContainer {
	margin-top: 15px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
}

.quickSearchItem {
	color: $textGrey;
	border: 1px solid $textGrey;
	padding-block: 5px;
	padding-inline: 16px;
	border-radius: 25px;
	font-size: 14px;
	width: max-content;
	transition: 0.1s;

	&.active {
		background-color: $green;
		border-color: $green;
		color: $white;
	}

	&:not(.active):hover {
		background: $lightGrey;
		color: $black;
	}
}

.filterChoiceContainer {
	display: flex;
	align-items: stretch;
	gap: 4px;

	.filterChoiceItemContainer {
		display: flex;
		align-items: center;
		gap: 8px;
		border: 1px solid $lign;
		border-radius: 4px;
		padding-inline: 18px;
	}

	.filterChoiceItemIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
	}
}

.settingsModalRow {
	padding-inline: 14px;
	padding-block: 14px;
	display: flex;
	justify-content: space-between;
	width: 300px;
	border-bottom: 1px solid $lign;
}

.contentSettingModal {
	display: grid;
	grid-template-columns: 25px auto;
	align-items: center;
	gap: 10px;
	color: $textGrey;
	font-size: 14px;
	font-weight: 600;
	color: $textGrey;
}

.filterListContainer {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 250px;
	padding-left: 5px;
	padding-right: 7px;
	margin: 5px;
	height: 30px;
	color: $textGrey;
	font-size: 14px;
	border-radius: 6px;

	&:hover {
		background-color: $lightGrey;
		color: $black;
	}
}

.filterListRow {
	display: grid;
	grid-template-columns: 20px auto;
	align-items: center;
	gap: 10px;
}

.titleHeader {
	flex-shrink: 1;
	flex-basis: 100%;
	border-right: 1px solid $lign;
	display: flex;
	align-items: center;
}

.endColumn {
	flex-shrink: 1;
	flex-basis: 12.5%;
}

.titleTodo {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 10px;
	border-right: 1px solid $lign;
	flex-shrink: 0;
	flex-basis: 450px;
	transition: 0.2s;
	cursor: text;

	.inputTitleModalContainer {
		width: 100%;
		height: 100%;

		.modalTitleInput {
			height: 100%;
			margin: 0 !important;
			width: 100%;
			border: none;
			padding-block: 0 !important;
			padding-inline: 10px;
		}
	}

	.titleTodoIcon {
		font-size: 12px;
		color: $textGrey;
		padding: 5px;
		cursor: pointer;
		opacity: 0;
		border-radius: 4px;
		transition: 0.2s;

		&:hover {
			background-color: $lign;
		}
	}
}

.dotMenuModal {
	border-radius: 4px;
	position: absolute;
	box-shadow: $heavy-shadow;
	top: 100%;
	right: 0;
	background-color: $white;
	z-index: 200;
}

.dotMenuModalRow {
	z-index: 999;
	// min-width: 200px;
	padding-inline: 16px;
	height: 30px;
	color: gray;
	margin: 4px;
	border-radius: 6px;
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;

	&:hover {
		color: $black;
		background-color: $lightGrey;
	}
}

.deleteMenuRow:hover {
	color: red;
}

.todoRowColumn {
	position: relative;
	display: flex;
	align-items: stretch;
	min-height: 40px;
	flex-shrink: 1;
	flex-basis: 98%;
	border-right: 1px solid $lign;
	cursor: pointer;

	.todoRowColumnContent {
		padding-inline: 10px;
		padding-block: 5px;
		border: 1px solid transparent;
		transition: 0.2s;
		flex-wrap: wrap;
		width: 100%;
		gap: 7px;
		display: flex;
		align-items: center;

		&:hover {
			border-color: $textGrey;

			.removeMeetingOrProject {
				opacity: 1;

				&:hover {
					background-color: $lightGreen;
				}
			}
		}
	}

	.removeMeetingOrProject {
		opacity: 0;
		position: absolute;
		right: 10px;
		font-size: 12px;
		top: 50%;
		transform: translateY(-50%);
		transition: 0.1s;
		padding: 5px;
		border-radius: 2px;
	}
}

.noSelectedDate {
	color: $lign;
}

.more {
	position: relative;
	flex-basis: 20%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s;

	.dotMenuContainer {
		opacity: 0;
	}

	.icon {
		color: gray;
		cursor: pointer;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 3px;
		border-radius: 4px;
		transition: 0.2s;

		&:hover {
			background-color: $lign;
		}
	}
}

.meetingModal {
	width: 100%;
}

.mainTodoBoardPage {
	background-color: #f7f7f7;
	margin-top: 10px;
	display: flex;
	gap: 30px;
	padding: 15px;
	padding-inline: 32px;
	height: 77vh;
}

.cat {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	width: max-content;
	color: var(--grey-palette-grey-190, #201f1e);
	font-family: Segoe UI;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px;
	padding-bottom: 10px;
	padding-top: 15px;
	padding-left: 26px;
}

.titleCat {
	display: flex;
	align-items: center;
	gap: 15px;
	cursor: pointer;

	.number {
		padding-block: 2px;
		padding-inline: 6px;
		border-radius: 4px;
		font-size: 13px;
		color: grey;
		background-color: $lightGrey;
	}
}

.tags {
	display: flex;
	align-items: center;
	gap: 10px;
	position: relative;

	.tag {
		font-size: 14px;
		width: fit-content;
		padding-block: 5px;
		padding-inline: 16px;
		border-radius: 87px;
	}
}
