@import '../../../../styles/variables.scss';

.containerForm {
	margin: 0 auto;
	text-align: center;
	max-width: 500px;

	.validEmailContainer {
		position: relative;

		.validEmail {
			padding-top: 23px;
			padding-bottom: 10px;
		}

		.validEmailPlaceholder {
			position: absolute;
			left: 14px;
			top: 10px;
			font-size: 11px;
			font-weight: 600;
			color: $textGrey;
		}
	}

	input{
		margin: 0;
		width: 100%;
	}

	button {
		margin-top: 10px;
		margin-bottom: 10px;
		width: 100%;
	}

	.requestButton {
		width: max-content;
	}

	.checkButton {
		width: 100%;
		color: $white;
		font-size: 16px;
		font-weight: 500;
		cursor: pointer;
	}

	.checkButtonCancel {
		color: $black;
		font-weight: 600;
		background-color: transparent;
		color: $textGrey;
		text-decoration: underline;
		border: none;
		width: max-content;
		margin: 0;
	}

	.failed {
		margin: auto;
		color: red;
	}
}

.errorEmail {
	margin-top: 20px;
}

.existEmail {
	margin-top: 10px;
}

.firstLine{
	font-weight: 700;
	font-size: 20px;
	margin-top: 40px;
}

.secondLine{
	font-weight: 500;
	font-size: 14px;
	margin-top: 10px;
	margin-bottom: 30px;
	color: #707070;
}

.stepOne{
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
	margin-top: 80px;
	.one{
		width: 32px;
		background-color: #09AB57;
		height: 3px;
	}

	.two{
		width: 32px;
		background-color: #707070;
		height: 3px;
	}

	.three{
		width: 32px;
		background-color: #707070;
		height: 3px;
	}
}

.stepTwoContainer {
	
	h1 {
		margin-top: 40px;
		font-size: 20px;
	}

	.sendCode {
		margin-block: 15px;
		
		p:nth-child(1) {
			color: $textGrey;
			font-weight: 500;
		}
	}
}

.stepTwo{
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
	margin-top: 80px;
	.one{
		width: 32px;
		background-color: #09AB57;
		height: 3px;
	}

	.two{
		width: 32px;
		background-color: #09AB57;
		height: 3px;
	}

	.three{
		width: 32px;
		background-color: #707070;
		height: 3px;
	}
}

.stepThree{
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
	margin-top: 30px;
	.one{
		width: 32px;
		background-color: #09AB57;
		height: 3px;
	}

	.two{
		width: 32px;
		background-color: #09AB57;
		height: 3px;
	}

	.three{
		width: 32px;
		background-color: #09AB57;
		height: 3px;
	}
}

.info{
	background-color: #F8F8F8;
	width: 100%;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 20px;
	padding: 15px;
}