@import '../../../../../../styles/variables';

.bodyMeeting {
	height: calc(100% - 350px);
	// overflow: hidden;

	@media screen and (max-width: 630px) {
		display: none;
	}

	.tabs {
		display: flex;
		flex-direction: row;
		flex-flow: wrap;
		align-items: center;
		gap: 24px;
		padding-left: 32px;
		color: #8a8886;
		border-bottom: 1px solid $lign;

		.tabsWrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
		}

		a {
			position: relative;
			cursor: pointer;
			font-size: 14px;
			padding-block: 15px;
			font-weight: 500;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				color: $black;
			}
		}

		.activeTab {
			border-radius: 5px;
			text-shadow: 0 0 0.5px black, 0 0 0.5px black;
			letter-spacing: 0px;
			color: $black;

			&::after {
				content: '';
				position: absolute;
				bottom: -0.5px;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: $black;
				border-radius: 50px;
			}
		}
	}

	.number {
		border: none;
		background-color: $lign;
		color: #8a8886;
		font-size: 10px;
		width: 20px;
		height: 20px;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.activeNumber {
		border: none;
		background-color: $lign;
		font-size: 10px;
		border-radius: 2px;
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.notNumber {
		border: none;
		background-color: transparent;
		font-size: 10px;
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.selectCustom {
	position: relative;
	button {
		background-color: #ebebeb;
		border: 1px solid $black;
		color: $black;
		width: 176px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
		height: 37px;

		&:hover {
			background-color: #ebebeb;
		}
	}

	.dropdown {
		background-color: $white;
		width: 176px;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		position: absolute;
		height: 100px;
		overflow: auto;
		top: -105px;

		p {
			padding: 0;
			margin: 0;
			padding: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 14px;

			&:hover {
				background-color: $lightGreen;
				color: $green;
			}
		}
	}
}

.mobileBody {
	display: none;

	@media screen and (max-width: 630px) {
		display: block;
	}
}

.menuMobile {
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
}
