@import '../../../../../styles/variables.scss';

.roundedBtn {
    cursor: pointer;
    background-color: $green;
    border-radius: 100%;
    width: 27px;
    height: 27px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: .1s;
    
    &:hover {
        background-color: $greenHover;
        transform: scale(1.05);
    }
}