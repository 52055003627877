@import '../../../../../../../../styles/variables.scss';

@keyframes fade-in {
	from {
		background-color: rgba(0, 0, 0, 0);
	}
	to {
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.container {
	--animation-timeOut: 0.3s;

	position: fixed;
	z-index: 99;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	animation: fade-in var(--animation-timeOut) ease-in-out forwards;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		display: none;
	}
}

@keyframes fade-out {
	from {
		background-color: rgba(0, 0, 0, 0.4);
	}
	to {
		background-color: rgba(0, 0, 0, 0);
	}
}

.container.fadeOut {
	animation: fade-out var(--animation-timeOut) ease-in-out forwards;
}

@keyframes slide-in {
	from {
		right: -666px;
	}
	to {
		right: 0;
	}
}

.modal {
	position: absolute;
	right: 0;
	background-color: $white;
	width: 800px;
	min-height: 100%;
	animation: slide-in var(--animation-timeOut) ease-in-out forwards;

	.timeFLex {
		display: flex;
	}

	@media screen and (max-width: 800px) {
		margin: 0;
		width: 90%;
	}

	@media screen and (max-width: 600px) {
		width: 85%;
	}

	@media screen and (max-width: 500px) {
		height: calc(100% - 100px);
		overflow: auto;
	}
}

@keyframes slide-out {
	from {
		right: 0;
	}
	to {
		right: -666px;
	}
}

.modal.slideOut {
	animation: slide-out var(--animation-timeOut) ease-in-out forwards;
}

.close {
	cursor: pointer;
	width: fit-content;
	display: flex;
	align-items: center;
	gap: 15px;
	padding: 20px;
}

.info {
	background-color: #f7f7f7;
	border-left: 2px solid $green;
	padding: 15px;
	margin-top: 10px;
}

.copilotPreInfo {
	display: flex;
	align-items: flex-start;
	gap: 15px;
	background: linear-gradient(90deg, #1e0290 0%, #033ac8 100%);
	color: white;
	margin: 20px;
	padding: 15px;
}

.todoRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px;

	.flex {
		display: flex;
		align-items: center;
		gap: 15px;
	}

	.title {
		flex-basis: 90% !important;
		input {
			flex-basis: 100% !important;
		}
	}
}

.icon {
	cursor: pointer;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 50px;
}

.modalAssignee {
	position: absolute;
	z-index: 999;
	top: 0;
	background-color: white;
	box-shadow: 2px 2px 20px 0px rgb(200 198 196);
	padding: 15px;
	width: 250px;
	max-height: 200px;
	overflow: auto;

	.row {
		padding: 10px;
		margin: 5px;
		display: flex;
		align-items: center;
		gap: 15px;
		cursor: pointer;
	}
}

.form {
	padding: 30px;

	.assignee {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 50px;
		cursor: pointer;
		border-radius: 6px;
		padding-inline: $paddingInlineInput;
		padding-block: $paddingBlockInput;
		text-align: left;
		border: 1px solid #d7d7d7;
		margin-bottom: 20px;
		padding-inline: 10px;
		padding-block: 8px;
		border-radius: 8px;
		position: relative;
	}

	.button {
		margin-top: 30px;
	}

	.formPart {
		margin-top: 30px;
	}
}

.inputWrapper {
	text-align: left;
	border: 1px solid #d7d7d7;
	margin-bottom: 20px;
	padding-inline: 10px;
	padding-block: 8px;
	border-radius: 8px;
	position: relative;

	.usageSelect {
		display: none;
	}

	.selectChoice {
		padding-top: 20px;
	}

	label {
		position: absolute;
		color: $green;
		font-size: 13px;
		font-weight: 400 !important;
	}

	span {
		position: absolute;
		right: 20px;
		top: 50%;
		z-index: 2;
		transform: translateY(-45%);
	}

	input {
		margin-bottom: 20px;
		padding: 0;
		padding-top: 17px;
		margin: 0;
		border-radius: 0;
		margin-top: 5px;
		border: none;
		font-size: 14px;

		&:-webkit-autofill {
			-webkit-background-clip: text;
		}

		&::placeholder {
			font-weight: 400;
		}
	}

	select {
		margin: 0;
		padding: 0;
		padding-top: 20px;
		font-size: 14px;
		font-weight: 500;
		max-width: 100%;
		background-color: transparent;
		border: none;
	}

	.selectIcon {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 14px;
		z-index: -1;
	}

	.customOptionsContainer {
		position: absolute;
		top: 105%;
		z-index: 100;
		max-height: 200px;
		overflow: auto;
		left: 0;
		width: 100%;
		background-color: $white;
		border-radius: 10px;
		box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
		border: 1px solid rgba(128, 128, 128, 0.1);
		cursor: pointer;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 10px;
		}

		li {
			list-style: none;
			padding-inline: 10px;
			margin: 5px;
			border-radius: 10px;
			padding-block: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 10px;

			&:hover {
				background-color: rgba(128, 128, 128, 0.05);
			}

			.checkbox {
				display: inline-block;
				height: 15px;
				width: 15px;
				border: 1px solid rgba(0, 0, 0, 0.3);
				border-radius: 100%;
			}
		}
	}
}

.todos {
	height: 70vh;
	overflow: auto;
}

.containerAvatar {
	position: relative;

	.avatar {
		cursor: pointer;
	}
}
