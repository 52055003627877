@import '../../../../../../../styles/variables.scss';

.over {
	width: 100%;
	overflow-y: auto;
	position: relative;
	padding-inline: 32px;
	padding-top: 24px;
	height: calc(100vh - 258px);
}

.overviewList {
	margin-top: 32px;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	.rows {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 6px;
		padding-block: 10px;
		padding-inline: 20px;


		.left {
			display: flex;
			align-items: center;
			width: 80%;
			justify-content: space-between;

			p {
				padding: 0;
				margin: 0;

				&:first-child {
					color: $textGrey;
					width: 100px;
				}

				&:nth-child(2) {
					font-weight: 500;
					width: 300px;
				}
			}

			.now {
				position: absolute;
				top: 50%;
				right: 15%;
				transform: translateY(-50%);
				background-color: $green;
				color: $white;
				padding-block: 8px;
				padding-inline: 12px;
				border-radius: 51px;
				text-align: center;
			}
		}
	}

	.form {
		margin-top: 20px;
		margin-left: 20px;
		display: flex;
		align-items: center;

		.submit {
			width: 24px;
			cursor: pointer;
		}

		.add {
			background-color: $inputBg;
			color: $black;
			text-transform: capitalize;
		}
	}

	.plus {
		border-radius: 50%;
		border: none;
		background-color: $lign;
		font-size: 14px;
		padding: 2px;
		height: 25px;
		width: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.confirmBox {
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	div {
		margin: auto;
		margin-top: 300px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;

		button {
			margin: 10px;
		}
	}
}

.update {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 90;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	.main {
		margin: auto;
		margin-top: 100px;
		padding: 20px;
		width: 500px;
		border-radius: 3px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);

		.closeButton {
			width: 100%;

			.icon {
				color: $black;
				font-size: large;
				cursor: pointer;
				float: right;
			}
		}

		select {
			width: 150px;
		}

		textArea {
			width: 97%;
			height: 150px;
			padding-inline: $paddingInlineInput;
			padding-block: $paddingBlockInput;
			background-color: $inputBg;
			border: none;
			resize: none;
		}
	}
}

.flex {
	display: flex;
	align-items: center;
	gap: 15px;
	cursor: pointer;
}

.cancel {
	background-color: transparent;
	color: $black;
	font-weight: 500;
	font-size: 16px;
	padding-block: 12px;
	padding-inline: 24px;
	border: 1px solid #8a8886;
	border-radius: 4px;
	display: flex;
	align-items: center;
	gap: 10px;

	&:hover {
		background-color: #ebebeb;
	}
}

.modalAttendee {
	position: absolute;
	z-index: 9;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding: 10px;
	width: 300px;

	.searchInput {
		height: 45px;
		width: 97%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		padding-left: 10px;
		color: $textNav;
		margin-bottom: 20px;
		background-color: $inputBg;

		span {
			height: 45px;
			display: flex;
			align-items: center;
		}

		input {
			font-size: medium;
			color: $textNav;
			margin: 0;
		}
	}

	.containerList {
		max-height: 300px;
		overflow-y: auto;
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-top: 15px;
		margin-right: 10px;
		margin-bottom: 15px;
		cursor: pointer;

		.name {
			display: flex;
			align-items: center;
			gap: 5px;
		}

		input {
			width: 15px;
			margin: 0;
		}
	}

	button {
		width: 100%;
	}
}

.form {
	background-color: $white;
	padding: 22px;
	min-width: 400px;
	border: 1px solid $lign;
	box-shadow: 2px 2px 10px $lign;
	position: absolute;
	top: 75px;
	left: 32px;
	border-radius: 3px;

	.buttonAttendee {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media screen and (max-width: 800px) {
		margin: 0;
		width: 90%;
	}

	p {
		font-weight: 500;
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 10px;
	}

	form {
		margin-top: 28px;

		input {
			border: 1px solid $lign;
			padding-block: $paddingBlockInput;
			padding-inline: $paddingInlineInput;
		}

		textArea {
			width: 100%;
			height: 100px;
			border-radius: 4px;
			border: 1px solid $lign;
			padding-inline: $paddingInlineInput;
			padding-block: $paddingBlockInput;
			background-color: $white;
			resize: none;
			font-size: 14px;
			margin-top: 10px;
		}

		button {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 8px;
			margin-top: $marginTopInput;
		}

		.formButton {
			width: min-content;
			float: right;
			padding-inline: 24px;
			padding-block: 12px;
			margin-top: 0px;
			font-size: 16px;
		}
	}
}

.selectCustom {
	position: relative;

	button {
		background-color: $inputBg;
		border: 1px solid $lign;
		color: $black;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
		padding-block: $paddingBlockInput;
		padding-inline: $paddingInlineInput;

		p {
			white-space: wrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: 500;
		}

		&:hover {
			background-color: #ebebeb;
		}
	}

	.dropdown {
		background-color: white;
		width: 100%;
		border-radius: 4px;
		max-height: 200px;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		position: absolute;
		overflow: auto;
		top: 100%;
		z-index: 50;

		p {
			padding: 0;
			margin: 5px;
			padding: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 14px;

			&:hover {
				background-color: $lightGreen;
				color: $green;
			}
		}
	}
}

.rows {
	border: 2px solid transparent;
	transition: .2s border;

	.flex {
		transition: .2s;
		color: $lign;
	}

	&:hover {
		background-color: $greyMarge;
		border-color: $lign;

		.flex {
			color: $black;
		}
	}
}

.flexButton {
	display: flex;
	align-items: center;
	gap: 10px;
}

.error {
	color: $red;
}

.shadow {
	//background-color: #efeeee;
	position: relative;
	border-radius: 3px;
}

.layout {
	position: absolute;
	inset: 0;
}

.placeholder {
	opacity: 1;
	height: 3px;
	padding-block: 2px;
	transition: 0.2s;
	border-radius: 5px;
}

.activePlaceHolder {
	height: 60px;
	margin-top: 5px;
	margin-bottom: 5px;
	transition: 0.1s;
	border-radius: 3px;
	background-color: #efeeee;
}