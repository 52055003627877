@import '../../../../../styles/variables';

@keyframes fade-in {
	from {
		background-color: rgba(0, 0, 0, 0);
	}

	to {
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.container {
	--animation-timeOut: 0.3s;

	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	animation: fade-in var(--animation-timeOut) ease-in-out forwards;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		display: none;
	}
}

@keyframes fade-out {
	from {
		background-color: rgba(0, 0, 0, 0.4);
	}

	to {
		background-color: rgba(0, 0, 0, 0);
	}
}

.container.fadeOut {
	animation: fade-out var(--animation-timeOut) ease-in-out forwards;
}

@keyframes slide-in {
	from {
		right: -666px;
	}

	to {
		right: 0;
	}
}

.modal {
	position: absolute;
	right: 0;
	background-color: $white;
	width: 500px;
	padding-inline: 26px;
	padding-block: 26px;
	min-height: 100%;
	animation: slide-in var(--animation-timeOut) ease-in-out forwards;

	.timeFLex {
		display: flex;
	}

	@media screen and (max-width: 600px) {
		margin: 0;
		width: 90%;
	}

	@media screen and (max-width: 500px) {
		width: 85%;
	}

	@media screen and (max-width: 500px) {
		height: calc(100% - 100px);
		overflow: auto;
	}

	.titleWrapper {
		padding-right: $paddingInlineInput;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 20px;

		h3 {
			display: flex;
			gap: 1rem;
			width: 100%;
			justify-content: start;
			align-items: center;
			margin: 0;
			padding: 0;
			color: $black;
		}
	}
}

@keyframes slide-out {
	from {
		right: 0;
	}

	to {
		right: -666px;
	}
}

.modal.slideOut {
	animation: slide-out var(--animation-timeOut) ease-in-out forwards;
}

.closeButton {
	.icon {
		color: $black;
	}
}

.transition {
	transition: 0.3s;
}

.notifTitle {
	font-size: 14px;
	margin-left: 0;
	margin-block: 20px;
}

.notif {
	position: relative;
	cursor: pointer;
	gap: 40px !important;

	.notificationContent {
		display: flex;
		align-items: center;
		gap: 25px;
	}

	p {
		margin: 0;
		padding: 0;
		color: grey;

		span {
			color: black;
		}

		.notifUserFrom {
			color: $green;
			font-weight: 600;
		}
	}

	.closeBtn {
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
	}

	.notifIcon {
		position: absolute;
		transform: scale(.35);
		outline: 7px solid rgb(250, 250, 250);
		background-color: $green;
		color: white;
		left: 30px;
		top: -10px;
		border-radius: 100%;
		padding: 10px;
	}

	.notifIconRegular {
		position: absolute;
		font-size: 12px;
		outline: 2.5px solid rgb(250, 250, 250);
		background-color: $green;
		color: white;
		left: 50px;
		top: 10px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 22px;
		width: 22px;
	}

	// .logo {
	// 	img {
	// 		width: 15px;
	// 	}
	// }

	.notifName {
		font-weight: 600;
		margin-bottom: 3px;
	}

	.notifDate {
		margin-top: 5px;
		color: rgba(128, 128, 128, 0.753);
		font-size: 13px;
	}

	color: var(--grey-palette-grey-190, #201f1e);
	font-family: Segoe UI;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;

	background-color: rgb(251, 251, 251);
	gap: 20px;
	align-self: stretch;
	border-radius: 6px;

	margin-bottom: 5px;

	padding-block: 10px;
	padding-inline: 20px;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

// #f7fffb