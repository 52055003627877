@import '../../../../../../styles/variables.scss';

.over {
	width: 100%;
	overflow-x: hidden;
	position: relative;
	padding-inline: 32px;
	padding-block: 24px;
}

.files {
	height: calc(100vh - 320px);
	width: 100%;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	margin-top: 20px;
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	flex-flow: wrap;
	gap: 20px;
}

.file {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	border: 1px solid $lign;
	margin-right: 50px;
	padding: 10px;
	font-size: 14px;
	width: auto;

	&:hover {
		background-color: $lightGrey;
	}

	.link {
		img {
			width: 20px;
		}

		.globe {
			font-size: 20px;
		}

		max-width: 200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	div {
		display: flex;
		align-items: center;
		gap: 15px;
	}

	.fileName {
		display: block;
		width: 110px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		.accessRight {
			margin-top: 5px;
			color: #605e5c;

			div {
				max-width: 100px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			p {
				margin: 0;
				color: #605e5c;
			}

			p::first-letter {
				text-transform: capitalize;
			}

			.iconDown {
				cursor: pointer;
				color: #605e5c;
				font-size: 9px;
			}
		}
	}

	a {
		color: $black;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.close {
		cursor: pointer;
		color: $lign;
	}
}

.icon {
	cursor: pointer;
	width: 24px;
	margin-top: 8px;
}

// ------------

.confirmBox {
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	div {
		margin: auto;
		margin-top: 300px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;

		button {
			margin: 10px;
		}
	}
}

.containeUpdate {
	position: absolute;
	z-index: 9;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: 250px;

	form {
		margin: 10px;

		.inb {
			display: flex;
			align-items: center;
			gap: 10px;
			input {
				width: 15px;
			}

			label {
				font-weight: 600;
			}
		}

		hr {
			width: 90%;
			background-color: $lign;
			opacity: 0.2;
		}

		.search_input {
			background-color: $white;
			width: auto;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			padding-left: 10px;
			color: $textNav;
			margin: 5px;

			input {
				width: 150px;
				font-size: medium;
				color: $textNav;
			}
		}

		.compagnies {
			display: flex;
			flex-direction: column;
			justify-content: right;
			align-items: flex-start;
			max-height: 200px;
			margin-bottom: 10px;
			overflow: hidden;
			overflow-y: auto;
			text-transform: capitalize;
		}

		button {
			width: 100%;
		}
	}
}

.form {
	background-color: $white;
	padding-inline: 33px;
	padding-block: 26px;
	border: 0.5px solid $lign;
	position: absolute;
	min-width: 400px;
	top: 75px;
	left: 32px;
	box-shadow: 2px 2px 10px $lign;
	border-radius: 3px;

	i {
		font-size: 18px;
	}

	.orForm {
		margin-top: $marginTopInput;
		display: flex;
		align-items: center;
		justify-content: center;

		.line {
			width: 100%;
			height: 1px;
			background-color: rgba(0, 0, 0, 0.05);
			margin-inline: 10px;
		}
	}

	@media screen and (max-width: 800px) {
		margin: 0;
		width: 90%;
	}

	p {
		font-weight: 500;
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.inputFile {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	form {
		margin-top: 5px;

		button {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-top: $marginTopInput;
			float: right;
			padding-inline: 20px;
			padding-block: 12px;
		}
	}
}

.formFile {
	form {
		text-align: center;
	}
}

.file {
	&:hover {
		.close {
			color: $black;
		}
	}
}

.cancel {
	background-color: transparent;
	color: $black;
	font-weight: 600;
	padding-inline: 20px;
	padding-block: 12px;
	border: 1px solid #8a8886;
	border-radius: 3px;
	display: flex;
	align-items: center;
	gap: 10px;

	&:hover {
		background-color: #ebebeb;
	}
}

.error {
	color: $red;
}

.addPeople {
	color: $black;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
	cursor: pointer;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 1px solid $black;

	&:hover {
		background-color: #ebebeb;
	}
}

.modalPeople {
	position: absolute;
	top: 0px;
	height: 100%;
	z-index: 99;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding: 10px;
	width: 250px;
	display: block;

	.peopleList {
		height: 100px;
		overflow: auto;
		display: block;
		width: 100%;
	}

	.avatar {
		display: flex;
		align-items: center;
		gap: 15px;
	}

	.row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		input {
			width: 20px;
		}
	}

	button {
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
}

.peopleListShared {
	padding-top: 10px;
	max-height: 100px;
	overflow: auto;
	display: block !important;
}

.rowPeople {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.delete {
		cursor: pointer;
	}

	.avatar {
		display: flex;
		align-items: center;
		gap: 10px;
	}
}
