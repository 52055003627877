@import '../../../../../styles/variables';

.container {

	padding-bottom: 20px;


	p {
		margin-bottom: 18px;
	}

	label {
		font-size: 14px;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	h2 {
		margin-bottom: 10px;
		font-size: 20px;
	}

	.containerDetails {
		background-color: $inputBg;
		border-radius: 6px;
		display: flex;
		align-items: stretch;
		padding: 24px;
		gap: 1rem;
		margin-bottom: 40px;

		.input {
			flex-basis: 25%;

			input {
				background-color: $white;
				text-transform: capitalize;
			}

		}

		.country {
			flex-basis: 25%;
		}

		.textArea {
			border: 1px solid $lign;
			border-radius: 6px;
			padding: 0;
			display: flex;
		}

		label {
			font-weight: 500;
		}

		select {
			border-radius: 6px;
			border: 1px solid $lign;
			background-color: $white;
			margin-top: 11px;
		}

		.addLogo {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-top: 22px;

			img{
				width: 20px;
			}

			.logo {
				width: 25px;
			}

			p {
				color: $green;
				font-size: 14px;
				margin-bottom: 0;
			}
		}

		.icon {
			color: $green;
			cursor: pointer;
		}

		.iconClose {
			color: $textGrey;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			font-size: 15px;
			height: 20px;
			cursor: pointer;
		}
	}

	button {
		margin-right: 10px;
		width: max-content;
	}
}

.containerLogo {
	position: absolute;
	padding: 10px;
	padding-top: 0;
	z-index: 9;
	background-color: white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
}

.confirmBox {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 9;

	div {
		margin: auto;
		margin-top: 300px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;

		button {
			margin: 10px;
		}
	}
}
