@import '../../../../styles/variables';

.main {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $white;
	padding: 20px;
	border-radius: 6px;
}

.calendarView {
	.top {
		margin-bottom: 15px;
		margin-inline: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		p {
			font-weight: 700;
			font-size: 13px;
		}

		div {
			display: flex;
			gap: 15px;

			.icon {
				cursor: pointer;
				font-size: 14px;
			}
		}
	}

	.calendar {
		.header {
			display: flex;

			div {
				width: 32px;
				height: 32px;
				font-size: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 500;
			}
		}

		.row {
			display: flex;

			div {
				width: 32px;
				height: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				&:hover {
					background-color: $green;
					color: $white;
				}
			}
		}
	}

	.goToday {
		cursor: pointer;
		text-align: right;
		margin-right: 10px;
		margin-block: 10px;
	}
}

.day {
	border-radius: 50%;
	font-size: 14px;

	&.differentMonth {
		color: #c8c6c4;
	}

	&.past {
		color: #c8c6c4;
	}

	&.hasMeetings {
		color: $green;
		font-weight: 500;
	}

	&.selected {
		background-color: $green;
		color: white;
	}
}