@import '../../../styles/variables';

.container {
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	align-items: center;
	margin: 0 auto;

	@media screen and (max-width: 820px) {
		display: flex;
		flex-direction: column-reverse;
		margin-top: 50px;
	}
}
