@import '../../../../styles/variables';

.button {
	margin-top: 10px;
	width: 100%;
	min-height: 50px;
	background-color: $buttonLight;
	border: none;
	border-radius: 8px;

	.loginButton {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 12px;
	}

	.flex {
		display: flex;
		align-items: center;
		gap: 15px;
	}

	.icon {
		background-color: white;
		padding: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.googleInfo {
	margin-top: 10px;
}
