@import '../../../../../../styles/variables.scss';

.headerShimmer {
    padding-left: 32px;
	padding-right: 48px;
	padding-top: 24px;
	padding-bottom: 26.5px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    border-bottom: 1px solid $lign;
}

.shimmerMenu {
    display: flex;
    gap: 30px;
    border-bottom: 1px solid $lign;
    padding-left: 32px;
    padding-block: 15.5px;
}

.bodyShimmer {
    padding-top: 55px;
    padding-left: 32px;
    display: flex;
    flex-direction: column;
    gap: 19px;

    .shimmerDescRow {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .shimmerBodyRow {
        display: flex;
        align-items: center;
        gap: 135px;
    }
}

.shimmerAvatarContainer {
    position: relative;
    display: flex;
    align-items: center;
    gap: -50px;
}