@import '../../../../styles/variables.scss';



.commentSection{
	
	background-color: #F7F7F7 ;
	border-top: 1px solid #D7D7D7;
	padding: 20px 15px;
	margin: -5px;

	.formComment{
		.comment{
			display: flex;
			align-items: flex-start;
			gap: 10px;
			margin-bottom: 10px;
			margin-top: 30px;
			textarea {
				width: 100%;
				padding: 12px 20px;
				box-sizing: border-box;
				border: 1px solid #ccc;
				border-radius: 4px;
				background-color: $white;
				font-size: 14px;
				resize: none;

				&:focus{
					outline: none;
				}
			  }
			
		}

		.button{
			display: flex;
			justify-content: flex-end;
			padding: 0;
		}

		button{
			display: flex;
			align-items: center;
			gap: 10px;
			
		}
	}
}

.commentNote{

padding-top: 20px;

	.icon{
		color: $lign;
	}

&:hover{
	.icon{
		color: $black;
	}
}
	
	.name{
		display: flex;
		align-items: center;
		gap: 10px;
		color: #707070;
	}

	.commentText{
		margin-left: 40px;
		font-weight: 500;

		display: flex;
		align-items: center;

		.commentTextDisplay{
		width: 100%;
		padding: 0;
		margin: 0;
	}

	textarea {
		width: 100%;
		height: 50px;
		box-sizing: border-box;
		border: 2px solid #ccc;
		border-radius: 4px;
		background-color: $white;
		padding: 12px 20px;
		font-size: 16px;
		resize: none;
		margin-right: 5px;

		&:focus{
			outline: none;
		}
	  }

	.icons{
		display: flex;
		align-items: center;
		gap: 15px;
	}

	.icon{
		cursor: pointer;
	}
	}
}

.count{
	font-size: 14px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: $green;
	
	span{
		text-decoration: underline;
		&:hover{
			color:#006B34
		}
	}
	
}

.countActive{
	font-size: 14px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: $green;
	
	span{
		text-decoration: underline;
		color:#006B34
		
	}
	
}

.textArea {
	position: relative;
	padding: 12px 20px;
	width: 100%;
	min-height: 100px;
	background-color: white;

	.mention {
		position: relative;
		color: $green;
		z-index: 50;
		background-color: white;
	}
}

.mentionSuggestion {
	padding-inline: 16px;
	padding-block: 8px;
	display: flex;
	align-items: center;
	gap: 10px;

	&:hover {
		background-color: $lightGreen;
	}
}