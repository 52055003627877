@import '../../../../styles/variables';

a {
	text-decoration: none;
}

.sidebar {
	background-color: $lightGrey;
	width: 64px;
	height: 100vh;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	border-right: 1px solid $lign;
	padding-bottom: 15px;

	@media screen and (max-width: 500px) {
		height: 50px;
		width: 100%;
		position: fixed;
		bottom: 0;
		z-index: 99;
		padding-bottom: 5px;
		padding-top: 5px;
	}

	.sidebarTop {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 10px;

		@media screen and (max-width: 500px) {
			display: flex !important;
			flex-direction: row;
			align-items: center;
			gap: 15px;
		}
	}

	.logo {
		z-index: 0;
		text-align: center;
		// height: 48px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-block: 13px;
		margin-bottom: 20px;
		border-bottom: 1px solid $lign;

		img {
			width: 25px;
		}

		@media screen and (max-width: 500px) {
			display: none;
		}
	}

	.icon_add_background {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;
		background-color: $green;
		border-radius: 3px;
		height: 42px;
		width: 42px;
		margin-bottom: 15px;
		cursor: pointer;

		.icon_add {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: auto;
			color: $green;
			background-color: $lightGrey;
			border-radius: 100%;
			font-size: x-large;
			height: 20px;
			width: 20px;
		}
	}

	.icon {
		margin: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		color: black;
		border-radius: 4px;
		border: 2px solid $lightGrey;
		z-index: 1;
		height: 40px;
		width: 40px;
		padding: 8px;
		margin: 0 auto;
		transition: all 0.1s;

		&:hover {
			background-color: $grey;
			border-color: $grey;
		}
	}

	.icon_nav_active {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		z-index: 1;
		height: 40px;
		width: 40px;
		padding: 8px;
		color: $orange;
		transition: all 0.1s;
		border: 2px solid $orange;
	}
}

.icons {
	cursor: pointer;
}
