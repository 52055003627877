@import '../../../../styles/variables';

.container {
	width: 60%;
	text-align: left;
	margin: 0 auto;
	height: 100%;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: hidden;

	&::-webkit-scrollbar {
	  display: none;
	}

	@media screen and (max-width: 820px) {
		width: 100%;
		
	}

	@keyframes fade-right {
		0% {
			transform: translateX(30px);
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
		}
	}

	.fadeRight {
		transform: translateX(30px);
		opacity: 0;
		animation: fade-right 0.4s forwards ease;
	}


	h1 {
		font-size: 28px;
		font-weight: 400;
		padding: 0;
		margin: 0;
		padding-top: 10px;
	}

	.Logo {
		img {
			height: 32px;
			width: 82.29px;
		}
	}

	.text {
		color: $lightGreyText;
		font-weight: 400;
		margin-bottom: 50px;
	}

	.form{
		max-width: 500px;
		margin: 0 auto;
		//padding-top: 50px;
		//margin-bottom: 50px;
		margin-top: 50px;
		height: 90vh;
		overflow: auto;
	}

	form {
		margin-top: 50px;
		max-width: 450px;

		.inputWrapper {
			text-align: left;
			border: 1px solid #D7D7D7;
			margin-bottom: 20px;
			padding-inline: 10px;
			padding-block: 8px;
			border-radius: 8px;
			position: relative;

			.usageSelect {
				display: none;
			}

			.selectChoice {
				padding-top: 20px;
			}

			label {
				position: absolute;
				color: $green;
				font-size: 13px;
				font-weight: 400 !important;
			}

			span {
				position: absolute;
				right: 20px;
				top: 50%;
				z-index: 2;
				transform: translateY(-45%);
			}

			input {
				margin-bottom: 20px;
				padding: 0;
				padding-top: 17px;
				margin: 0;
				border-radius: 0;
				margin-top: 5px;
				max-width: 100%;
				border: none;
				font-size: 14px;

				&:-webkit-autofill { 
					-webkit-background-clip: text;
				}

				&::placeholder {
					font-weight: 400;
				}
			}

			select {
				margin: 0;
				padding: 0;
				padding-top: 20px;
				font-size: 14px;
				font-weight: 500;
				max-width: 100%;
				background-color: transparent;
			}

			.selectIcon {
				position: absolute;
				right: 20px;
				top: 50%;	
				transform: translateY(-50%);
				font-size: 14px;
				z-index: -1;
			}

			.customOptionsContainer {
				position: absolute;
				top: 105%;
				z-index: 100;
				max-height: 200px;
				overflow: auto;
				left: 0;
				width: 100%;
				background-color: $white;
				border-radius: 10px;
				box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
				border: 1px solid rgba(128, 128, 128, 0.1);
				cursor: pointer;

			
				&::-webkit-scrollbar {
					width: 6px;
				}  

				&::-webkit-scrollbar-thumb {
					background: #888;
					border-radius: 10px;
				}

				li {
					list-style: none;
					padding-inline: 10px;
					margin: 5px;
					border-radius: 10px;
					padding-block: 10px;
					cursor: pointer;
					display: flex;
					align-items: center;
					gap: 10px;

					&:hover {
						background-color: rgba(128, 128, 128, 0.05);
					}

					.checkbox {
						display: inline-block;
						height: 15px;
						width: 15px;
						border: 1px solid rgba(0, 0, 0, 0.3);
						border-radius: 100%;
					}
				}
			}
		}

		.bottomTextForm {
			color: #707070;
			font-weight: 500;
			font-size: 13px;
		}

		.checkbox {
			input {
				width: 20px;
			}

			display: flex;
			align-items: center;
			gap: 20px;

			span {
				font-weight: 500;
				width: 350px;
			}
		}

		button {
			margin-top: 30px;
			border-radius: 8px;
			text-transform: capitalize;
			width: 100%;
			height: 50px;
		}

		.stepBack {
			color: #707070;
			font-size: 16px;
			font-weight: 500;
			text-align: center;
			margin-top: 30px;
			text-decoration-line: underline;
			cursor: pointer;
		}
	}
} 

.invitePeoplesWrapper {
	position: relative;
}

.invitePeoplesModal {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background-color: white;
	z-index: 40;
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
	padding: 20px;
	cursor: pointer;
	border-radius: 8px;
}

.opacity {
	opacity: 0.7;
}

.noOpacity {
	opacity: 1;
}

.country {
	input {
		background-color: transparent;
		padding: 0 !important;
		margin: 0 !important;
	}
}

textArea {
	background-color: $inputBg;
	padding: 5px;
	width: 100%;
	height: 80px;
	resize: none;
	margin-bottom: 10px;
	border: none;

	&:focus {
		outline: none;
		border: none;
	}
}