@import '../../../../../../../../styles/variables.scss';

.text {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px solid $lign;
	border-radius: 6px;
	padding-inline: $paddingInlineInput;
	padding-block: $paddingBlockInput;
	margin-bottom: 10px;
}

.box {
	margin-bottom: 15px;
	border-radius: 15px;
	padding: 15px;
}

.flex {
	margin-top: 30px;
	margin-bottom: 10px;
	display: flex;
	align-items: flex-start;
	gap: 15px;
}
