@import '../../../../../../../styles/variables';

.icon {
	cursor: pointer;
	display: flex;
	align-items: center;
	height: 14px;
}



.tags {
	display: flex;
	align-items: center;
	flex-flow: wrap;
	gap: 15px;
	position: relative;

	.tag {
		display: flex;
		align-items: center;
		gap: 10px;
		padding-block: 5px;
		padding-inline: 10px;
		border-radius: 87px;
		
	

		.iconDelete {
			cursor: pointer;
			display: flex;
			align-items: center;
			font-size: 10px;
		}
	}
}
