@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.container {
	margin-top: 25px;
	margin-bottom: 25px;
	width: 100%;
	min-height: 82vh;
	overflow: auto;
}

.buttonRestore {
	margin: 50px;
}
