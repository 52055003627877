@import '../../../../../styles/variables';

.overview {
	overflow-x: auto;
	white-space: nowrap;
	padding-bottom: 60px;
	padding-top: 30px;
}

.container {
	margin-top: 25px;
	margin-bottom: 25px;
	width: 100%;

	.invite {
		background-color: transparent;
		color: $black;
		font-weight: 600;
		border: 1px solid #8a8886;
		border-radius: 2px;
		display: flex;
		align-items: center;
		gap: 10px;

		&:hover {
			background-color: #ebebeb;
		}

		margin-bottom: 20px;

		.icon {
			font-size: 14px;
		}
	}

	table {
		text-align: left;
		padding-left: 0;
		margin: 0;
		th,
		td {
			padding-right: 10px;
		}

		tr,
		th {
			min-height: 47px;
		}

		td,
		th {
			border-bottom: 1px solid $lign;
			padding: 20px;

			.more {
				cursor: pointer;
				float: right;
			}
		}

		.displayName {
			text-transform: capitalize;
		}

		.statut {
			&.active {
				color: $green;
			}

			&.pending {
				color: $red;
			}
		}
	}
}

.options {
	position: absolute;
	z-index: 9;
	background-color: white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: 200px;
	right: 20px;

	.edit {
		color: $green;
		margin: 10px;
		display: flex;
		align-items: center;
		gap: 10px;
		cursor: pointer;
		span {
			color: $black;
		}
	}

	.remove {
		color: red;
		margin: 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.confirmBox {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	div {
		margin: auto;
		margin-top: 300px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;

		button {
			margin: 10px;
		}
	}
}

.optionsButtons {
	display: flex;
	align-items: center;
	gap: 15px;
	flex-flow: wrap;
}

.toggle {
	display: flex;
	gap: 10px;
	margin-bottom: 10px;
}

.request {
	margin-top: 50px;

	.accept {
		color: $green;
		width: fit-content;
		cursor: pointer;
	}

	.refuse {
		color: $red;
		width: fit-content;
		cursor: pointer;
	}
}

.country {
	input {
		background-color: transparent;
		padding: 0 !important;
		margin: 0 !important;
	}
}

.startDate {
	background-color: $white;
	border-radius: 8px;
	text-align: left;
	border: 1px solid #d7d7d7;
	margin-bottom: 20px;
	padding-inline: 10px;
	padding-block: 8px;
	color: #707070;
	position: relative;
	gap: 10px;

	.titleLabel {
		color: $green;
		font-size: 13px;
		font-weight: 400 !important;
	}

	.startDateButton {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100%;
	}

	.datePickerTime {
		font-weight: 500;
		color: $black;
	}

	.icon {
		position: absolute;
		right: 10px;
		top: 25%;
		color: $black;
		cursor: pointer;
	}
}

.inputWrapper {
	text-align: left;
	border: 1px solid #d7d7d7;
	margin-bottom: 20px;
	padding-inline: 10px;
	padding-block: 8px;
	border-radius: 8px;
	position: relative;

	.usageSelect {
		display: none;
	}

	.selectChoice {
		padding-top: 20px;
	}

	label {
		position: absolute;
		color: $green;
		font-size: 13px;
		font-weight: 400 !important;
	}

	.addLogo {
		margin-bottom: 20px;
		padding: 0;
		padding-top: 17px;
		margin: 0;
		border-radius: 0;
		margin-top: 5px;
		max-width: 100%;
		border: none;
		font-size: 14px;
		position: relative;

		.logo {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	span {
		position: absolute;
		right: 20px;
		top: 50%;
		z-index: 2;
		transform: translateY(-45%);
	}

	input {
		margin-bottom: 20px;
		padding: 0;
		padding-top: 17px;
		margin: 0;
		border-radius: 0;
		margin-top: 5px;
		max-width: 100%;
		border: none;
		font-size: 14px;

		&:-webkit-autofill {
			-webkit-background-clip: text;
		}

		&::placeholder {
			font-weight: 400;
		}
	}

	select {
		margin: 0;
		padding: 0;
		padding-top: 20px;
		font-size: 14px;
		font-weight: 500;
		max-width: 100%;
		background-color: transparent;
	}

	.selectIcon {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 14px;
		z-index: -1;
	}

	.customOptionsContainer {
		position: absolute;
		top: 105%;
		z-index: 100;
		max-height: 200px;
		overflow: auto;
		left: 0;
		width: 100%;
		background-color: $white;
		border-radius: 10px;
		box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
		border: 1px solid rgba(128, 128, 128, 0.1);
		cursor: pointer;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 10px;
		}

		li {
			list-style: none;
			padding-inline: 10px;
			margin: 5px;
			border-radius: 10px;
			padding-block: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 10px;

			&:hover {
				background-color: rgba(128, 128, 128, 0.05);
			}

			.checkbox {
				display: inline-block;
				height: 15px;
				width: 15px;
				border: 1px solid rgba(0, 0, 0, 0.3);
				border-radius: 100%;
			}
		}
	}
}

.flex {
	display: flex;
	align-items: center;
	gap: 15px;
}

@keyframes fade-in {
	from {
		background-color: rgba(0, 0, 0, 0);
	}
	to {
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.update {
	--animation-timeOut: 0.3s;

	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	animation: fade-in var(--animation-timeOut) ease-in-out forwards;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		display: none;
	}
}

@keyframes fade-out {
	from {
		background-color: rgba(0, 0, 0, 0.4);
	}
	to {
		background-color: rgba(0, 0, 0, 0);
	}
}

.update.fadeOut {
	animation: fade-out var(--animation-timeOut) ease-in-out forwards;
}

@keyframes slide-in {
	from {
		right: -666px;
	}
	to {
		right: 0;
	}
}

.modal {
	position: absolute;
	right: 0;
	background-color: $white;
	width: 660px;
	padding-inline: 33px;
	padding-block: 26px;
	min-height: 100%;
	animation: slide-in var(--animation-timeOut) ease-in-out forwards;

	@media screen and (max-width: 500px) {
		padding-bottom: 60px;
	}

	.icon {
		float: right;
	}

	.timeFLex {
		display: flex;
	}

	@media screen and (max-width: 800px) {
		margin: 0;
		width: 90%;
	}

	@media screen and (max-width: 600px) {
		width: 85%;
	}

	@media screen and (max-width: 500px) {
		height: calc(100% - 100px);
		overflow: auto;
	}

	.titleWrapper {
		padding-right: $paddingInlineInput;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		h3 {
			display: flex;
			gap: 1rem;
			width: 100%;
			justify-content: start;
			align-items: center;
			margin: 0;
			padding: 0;
			color: $black;
		}
	}
}

@keyframes slide-out {
	from {
		right: 0;
	}
	to {
		right: -666px;
	}
}

.modal.slideOut {
	animation: slide-out var(--animation-timeOut) ease-in-out forwards;
}

.closeButton {
	.icon {
		color: $black;
	}
}

form {
	color: $black;

	label {
		font-weight: 500;
	}
}

.cancel {
	background-color: $white;
	color: $black;
	font-weight: 600;
	border: 1px solid $black;
	margin-left: 16px;

	&:hover {
		background-color: #ebebeb;
	}
}

.hasAccept {
	opacity: 0.5;
}

.moreCol {
	position: relative;
}

.cat {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	width: max-content;
	color: var(--grey-palette-grey-190, #201f1e);
	font-family: Segoe UI;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px;
	padding-bottom: 20px;
	padding-top: 10px;
}

.domain {
	margin: 5px;
	margin-left: 22px;
}
