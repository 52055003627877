@import '../../../../../styles/variables.scss';

.outlinedBtn {
	background-color: transparent;
	color: $black;
	font-weight: 500;
	font-size: 16px;
	padding-block: 12px;
	padding-inline: 24px;
	border: 1px solid #8a8886;
	border-radius: 4px;
	display: flex;
	align-items: center;
	gap: 10px;

	&:hover {
		background-color: #ebebeb;
		border-color: black;
	}

}