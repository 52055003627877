@import '../../../../../styles/variables.scss';

.attendees {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-flow: wrap;
	gap: 40px;
	margin-top: 20px;

	h3 {
		margin-top: 0;
		margin-bottom: 0;
	}

	.avatar {
		width: 33px;
		border-radius: 50%;
	}
}

.groupAttendees {
	position: relative;
	z-index: 200;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-flow: wrap;
	gap: 0.5rem;
	margin-right: 20px;
}

.domain {
	display: flex;
	align-items: center;
	gap: 10px;
	text-transform: capitalize;
	border-radius: 100%;
	border: 1.5px solid $green;
	background-color: white;
	height: 44px;
	width: 44px;
	padding: 3px;
	z-index: 25;

	img {
		width: 100%;
		height: 100%;
	}
}

.domainModal {
	display: flex;
	align-items: center;
	gap: 10px;
	text-transform: capitalize;
	border-radius: 100%;
	border: 1.5px solid $green;
	background-color: white;
	padding: 3px;

	img {
		width: 100%;
		height: 100%;
	}
}

.icon {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal {
	z-index: 1;
	position: absolute;
	background-color: $white;
	padding: 15px;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: 320px;

	p {
		margin: 0;
		padding: 0;
	}
}

.tabs {
	display: flex;
	flex-direction: row;
	overflow: auto;
	gap: 2rem;
	padding-left: 20px;
	color: #8a8886;
	margin-bottom: 5px;

	a {
		cursor: pointer;
		width: 80px;

		&:hover {
			font-weight: 600;
			border-bottom: 2px solid $green;
			color: $black;
		}
	}

	.activeTab {
		width: 80px;
		font-weight: 600;
		padding-bottom: 10px;
		border-bottom: 2px solid $green;
		color: $black;
	}
}

.flex {
	display: flex;
	align-items: center;
	gap: 15px;
}

.attendeesList {
	max-height: 500px;
	overflow: auto;
}
