@import '../../../../../styles/variables.scss';

.tagModalContainer {
    width: 100%;

    .tagsEditContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        width: 100%;
        padding-inline: 10px;
        padding-block: 10px;
        border-bottom: 1px solid $lign;

        input {
            margin: 0;
            display: flex;
            align-items: stretch;
            border: none;
            background-color: transparent;
            padding: 0;
            justify-content: stretch;
        }
    }

    .pickerColorWrapper {
        flex-basis: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        transition: .2s;
        margin: 4px;
        border-radius: 6px;

        &:hover {
            background-color: $lightGrey;
        }

        .tagCreationContainer {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .createTag {
                padding-block: 3px;
                padding-inline: 10px;
                border-radius: 87px;
                font-size: 14px;
            }
        }

        .pickerColorModal {
            padding: 10px;
            width: 200px;
        }

        .pickerModalRow {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px;
            border-radius: 6px;

            &:hover {
                background-color: $lightGrey;
            }

            .colorContainer {
                height: 12px;
                width: 12px;
                border-radius: 100%;
            }
        }
    }
}

.usedTagsContainer {
    padding: 10px;

    .usedTagsTitle {
        margin-bottom: 10px;
        font-weight: 600;
        color: $textGrey;
        text-transform: uppercase;
    }
}

.tagModalInput {
    margin: 0;
    border: none;
    border-bottom: 1px solid $lign;
    border-radius: 0;
}