@import '../../../../../../../styles/variables.scss';

.notif {
	padding: 25px;
	border-bottom: 1px solid #d7d7d7;
	padding-bottom: 25px;

	display: flex;
	justify-content: space-between;

	h3 {
		color: #707070;
		font-weight: 500;
		font-size: 14px;
		margin: 0;
		padding: 0;
		margin-bottom: 10px;
		font-size: 14px;
	}

	p {
		margin: 0;
		padding: 0;
		font-size: 16px;
		font-weight: 500 !important;
	}

	.link {
		color: $green;
		cursor: pointer;
		width: fit-content;
	}

	.icon {
		visibility: hidden;
		cursor: pointer;
		font-size: 20px;
	}

	&:hover {
		background-color: #f7f7f7;

		.icon {
			visibility: visible;
		}
	}
}
