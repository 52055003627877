@import '../../../../styles/variables';

.meetings {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	

	.meeting {
		display: flex;
		flex-flow: wrap;
		height: 59px;
		width: 100%;
		border-bottom: 1px solid $lign;

		.halfHour {
			width: 100%;
			height: 30px;
			cursor: pointer;

			&:hover {
				background-color: rgba(123, 214, 166, 0.123);
			}
		}
	}
}

.agendaSlot {
	position: absolute;
	background-color: #00ad569f;
	width: 100%;
	border-radius: 5px;
}

.conflictSlot {
	position: absolute;
	background-color: rgba(156, 21, 21, 0.548);
	width: 100%;
}

.meetingSlot {
	position: absolute;
	color: #00ad56;
	cursor: pointer;
	font-weight: 600;
	display: flex;
	background-color: #f2fcf7;
	margin-right: 10px;
	overflow: hidden;
	border-radius: 5px;
	border: 1px solid #00ad5628;

	.meetingSubject {
		padding: 5px;
		padding-left: 1px;

		width: 95%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		div {
			width: 95%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&.lessthanOneHour {
			div {
				width: 95%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.startEnd {
		font-weight: 600;
		font-size: 10px;
	}

	&.shortMeeting {
		height: max-content !important;
	}

	.meetingStatus {
		min-width: 5px;
		background-color: $lightGreen;

		&.organizer {
			background-color: blue;
		}

		&.accepted {
			background-color: green;
		}

		&.declined {
			background-color: red;
		}

		&.notResponded {
			background-color: orange;
		}

		&.none {
			background-color: orange;
		}

		&.tentativelyAccepted {
			background-color: yellow;
		}
	}
}
