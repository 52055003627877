@import '../../../../../styles/variables';

.main {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
	margin-bottom: 15px;
	position: absolute;
	z-index: 100;
	background-color: $white;
	border: $lign;
	padding: 25px;
	top: -25px;
	left: 30px;
	border-radius: 5px;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.calendarView {
	.top {
		position: relative;
		margin-left: 10px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		p {
			font-weight: 700;
			font-size: 16px;
		}

		div {
			display: flex;
			gap: 2px;
			transition: 0.1s;

			.icon {
				cursor: pointer;
				font-size: 11px;
				padding: 12px;
				border-radius: 100%;

				&:hover {
					background-color: $lightGrey;
				}
			}
		}
	}

	.calendar {
		.header {
			display: flex;
			font-size: 14px;

			div {
				width: 36px;
				height: 30px;
				display: flex;
				color: #333;
				justify-content: center;
				align-items: center;
				font-weight: 500;
			}
		}

		.row {
			display: flex;

			div {
				width: 34px;
				height: 34px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				&:hover {
					background-color: $green;
					color: $white;
				}
			}
		}
	}

	.goToday {
		margin-top: 10px;
		// margin-right: 10px;
		padding-block: 6px;
		padding-inline: 12px;
		cursor: pointer;
		float: right;
		border-radius: 6px;
		transition: 0.1s;

		&:hover {
			background-color: $lightGrey;
		}
	}
}

.day {
	border-radius: 50%;
	margin: 1px;
	font-size: 14px;
	transition: 0.1s;

	&.differentMonth {
		color: #c8c6c4;
	}

	&.past {
		color: #c8c6c4;
	}

	&.hasMeetings {
		color: $green;
		font-weight: 600;
	}

	&.selected {
		background-color: $green;
		color: white;
	}
}

.calendarLoader {
	position: absolute;
	top: -40%;
	transform: scale(0.25);
	right: 55px;
}
