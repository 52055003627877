@import '../../../../styles/variables';

.loader {
    width: 64px;
    height: 64px;
    border: 3px solid $green;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}	 

// .loader {
// 	position: relative;
// 	border: 14px solid $color-light;
// 	/* Light grey */
// 	border-top: 14px solid $green;
// 	/* Blue */
// 	border-radius: 50%;
// 	width: 80px;
// 	height: 80px;
// 	animation: spin 2s linear infinite;
// 	top: 40%;
// 	left: 40%;
// 	transform: translate(-50%, -50%);
// }

// @keyframes spin {
// 	0% {
// 		transform: rotate(0deg);
// 	}
// 	100% {
// 		transform: rotate(360deg);
// 	}
// }
