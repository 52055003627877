@import '../../../../styles/variables.scss';

.listContainer {
	min-width: 360px;
	max-width: 360px;
	border-right: 1px solid $lign;
	background-color: $lightGrey;
	padding-bottom: 100px;

	@media screen and (max-width: 800px) {
		min-width: 100%;
		max-height: 200px;
	}

	@media screen and (max-width: 500px) {
		max-height: unset;
	}

	.list {
		.title {
			margin-top: 28px;
			display: flex;
			align-items: center;
			gap: 20px;

			h1 {
				font-weight: 800 !important;
				font-size: 24px;
			}

			.button {
				cursor: pointer;
				background-color: $green;
				border-radius: 100%;
				width: 27px;
				height: 27px;
				font-size: 11px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $white;
				transition: transform 100ms;
			}
		}

		.projects {
			margin-bottom: 20px;
			max-height: calc(100vh - $header-height - 150px);
			overflow: auto;

			@media screen and (max-width: 800px) {
				min-width: 100%;
				max-height: 90px;
			}

			@media screen and (max-width: 500px) {
				max-height: calc(100vh - $header-height - 200px);
			}
		}

		.project {
			border-top: 1px solid $lign;
			border-right: none;
			padding-left: 30px;
			padding-block: 25px;
			cursor: pointer;
			border-left: 0px solid $green;
			gap: 5px;
			filter: grayscale(100%);
			opacity: 0.4;
			font-size: 16px !important;
			font-weight: 600;
			transition: 0.1s ease-in-out;

			&:hover {
				opacity: 0.7;
			}

			&:last-child {
				border-bottom: 1px solid $lign;
			}

			.projectTitle {
				display: flex;
				align-items: center;
				gap: 0.25rem;
				margin-bottom: 0.5rem;

				p {
					margin: 0;
				}
			}

			.accessButton {
				font-size: 18px;
			}
		}

		.project.active {
			opacity: 1;
			filter: none;
			border-left-width: 4px;
		}
	}
}

.filters {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	// gap: 15px;
	position: relative;
	margin-top: 43.5px;

	i {
		font-size: 12px;
	}

	.modalFilterWrapper {
		padding-top: 5px;
		width: 95%;
		margin: auto;
		margin-bottom: 5px;
		overflow: hidden;
		border-radius: 4px;
		transition: 0.2s ease-in-out;
	}

	.buttonFilter {
		transform: translateX(0);
		background-color: #f7f7f7;
		background-color: $grey;
		border-radius: 4px;
		margin-left: 14px;
		padding-left: 14px;
		margin-right: 10px;
		padding-right: 14px;
		padding-top: 7.5px;
		padding-bottom: 8px;
		display: flex;
		align-items: center;
		gap: 10px;
		cursor: pointer;
		transition: transform 0.2s ease-in-out, color 50ms ease-in-out, background-color 50ms ease-in-out;

		&:hover {
			background-color: $green;
			color: white;
		}
	}

	.activeButton {
		transform: translateX(10px);
		background-color: $green;
		color: white;
		border-radius: 4px;
		margin-left: 14px;
		padding-left: 14px;
		margin-right: 10px;
		padding-right: 14px;
		padding-top: 7.5px;
		padding-bottom: 8px;
		display: flex;
		align-items: center;
		gap: 10px;
		cursor: pointer;
		transition: 0.2s;
	}
}
