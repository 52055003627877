@import '../../../../styles/variables.scss';


.container {
	width: 100%;
	background-color: $white;
	padding-inline: 15px;
	padding-top: 15px;
	padding-bottom: 20px;
	// box-shadow: 0 0 6px 0px rgb(200 198 196);
	max-height: 500px;
	overflow: auto;
	border-radius: 3px;
	z-index: 999;
	background-color: transparent;

	h2 {
		padding: 0;
        margin: 0;
        font-size: 18px;
        margin-bottom: 20px;
	}

	.list{
        .row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            font-weight: 500;
            color: #333;

            input{
                width: 16px;
                height: 20px;
            }

            .avatar{
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }
}