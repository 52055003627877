@import '../../../../../../styles/variables.scss';

.over {
	width: 100%;
	overflow-x: none;
	position: relative;
	padding-inline: 32px;
	padding-block: 24px;
}

.overviewList {
	height: calc(100vh - 320px);
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	overflow-x: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	overflow-x: auto;
	white-space: nowrap;

	table {
		margin: 0 auto;
		width: 100%;
		border-collapse: collapse;
		empty-cells: show;
		text-align: left;
		margin-top: 10px;

		input {
			background-color: transparent;
			margin: 0;
		}

		tr {
			width: 100%;

			td {
				display: table-cell;

				.dateMeeting {
					font-weight: 500;
				}

				.titleMeeting {
					margin-bottom: 5px;
					font-weight: 500;
				}

				.hour {
					font-weight: 700;
					font-size: 14px;
				}

				.title {
					font-weight: 500;
					font-size: 16px;
					color: $textGrey;
					padding-block: 0px;
					display: flex;
					align-items: center;
					gap: 10px;
				}
			}
		}

		.done {
			opacity: 0.5;
		}
	}

	.tags {
		display: flex;
		align-items: center;
		gap: 15px;
		position: relative;

		.plus {
			border-radius: 50%;
			border: none;
			background-color: $lign;
			font-size: 14px;
			padding: 2px;
			height: 25px;
			width: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.tag {
			padding-inline: 10px;
			padding-block: 5px;
			border-radius: 87px;
		}
	}
}

.options {
	position: absolute;
	z-index: 9;
	background-color: white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding-right: 10px;
	top: 40px;
	right: 0;

	.remove {
		color: red;
		margin: 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.confirmBox {
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	div {
		margin: auto;
		margin-top: 300px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;

		button {
			margin: 10px;
		}
	}
}

.subCategory {
	overflow: hidden;
	transition: 0.3s;
	width: 100%;
}

.cancel {
	background-color: transparent;
	color: $black;
	font-weight: 600;
	border: 1px solid #8a8886;
	display: flex;
	align-items: center;
	gap: 10px;

	&:hover {
		background-color: #ebebeb;
	}
}

.category {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	color: var(--grey-palette-grey-190, #201f1e);
	font-family: Segoe UI;
	font-size: 15px;
	font-style: normal;
	overflow: hidden;
	font-weight: 700;
	padding-top: 20px;
	padding-bottom: 15px;
	width: 100%;

	.titleCat {
		display: flex;
		align-items: center;
		gap: 15px;
		cursor: pointer;

		.categoryIcon {
			transition: 0.2s;
		}

		.number {
			padding-block: 2px;
			padding-inline: 6px;
			font-size: 13px;
			border-radius: 4px;
			color: grey;
			background-color: $lightGrey;
		}
	}
}

.hour {
	margin: 0;
	padding: 0;
}

.attendeesFlex {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-flow: wrap;
	gap: 0.5rem;
	height: 50px;
	position: relative;
}

.modalAttendees {
	position: absolute;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: max-content;
	padding: 15px;
	z-index: 9;
	overflow-y: auto;
	margin-top: 160px;

	.attendee {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
	}
}

.header {
	border-top: 1px solid $lign;
	border-bottom: 1px solid $lign;
}
