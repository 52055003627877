@import '../../../../../styles/variables.scss';

.fiveDaysButton{
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-size: 16px;
	padding-inline: 24px;
	padding-block: 11px;
	border-radius: 4px;
	box-shadow: 1px 1px 2px $lign;
	transition: .1s;

	i {
		font-size: 18px;
	}
	
}