@import '../styles/variables';

.app {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
}

a {
	text-decoration: none !important;
}
