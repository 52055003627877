@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.overview {
	width: 100%;
}

.mainTodoContainer {
	height: 20vh;
	overflow-y: auto;
}

.column {
	padding-inline: 25px;
}

.noData {
	padding-inline: 25px;
}

.mainTodoBoardPage {
	background-color: #f7f7f7;
	// width: 100%;
	overflow: auto;
	display: flex;
	// gap: 50px;
	padding-top: 32px;
	padding-bottom: 25px;
	padding-inline: 7px;
	// height: calc(100vh - 249px);
}

.item {
	position: relative;
	display: flex;
	background-color: white;
	box-shadow: 1px 1px 2px 0px #00000020;
	border-radius: 6px;
	border: 1px solid transparent;
	width: 300px;
	padding-right: 15px;
	padding-left: 10px;
	padding-block: 20px;
	z-index: 50;
	cursor: pointer;
	transition: border-color .2s;

	p {
		margin: 0;
		padding: 0;
		margin-bottom: 10px;
	}

	.info {
		display: flex;
		width: 100%;
		align-items: start;
		justify-content: space-between;
	}

	.icons {
		display: flex;
		align-items: center;
		gap: 15px;
		color: $lign;
		visibility: hidden;
		transition: .1s;
	}

	&:hover {
		border-color: $lign;

		.icons {
			color: $black;
			visibility: visible;
		}
	}
}

.cardWrapper {
	padding-left: 15px;
	width: 100%;
}

.leftLine {
	position: absolute;
	width: 5px;
	bottom: 10px;
	left: 10px;
	top: 10px;
	border-radius: 10px;
	flex-basis: 1;
}

.bottomCard {
	display: flex;
	flex-direction: column;
	gap: 15px;

	.tagWrapper {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 10px;
	}

	.moreTags {
		font-weight: 500;
		margin-left: 10px;
	}

	.tag {
		font-size: 14px;
		width: fit-content;
		padding-block: 5px;
		padding-inline: 16px;
		border-radius: 87px;
	}
}

.layout {
	position: absolute;
	inset: 0;
}

.emptyPlaceHolder {
	width: 300px;
}

.placeholder {
	opacity: 0.5;
	height: 8px;
	background-color: $lightGrey;
}

.activePlaceHolder {
	// height: 123px;
	padding-top: 30px;
	border-radius: 6px;
	margin-top: 8px;
	margin-bottom: 8px;
	border-radius: 3px;
	background-color: #efeeee;
}

::-webkit-scrollbar {
	position: absolute;
	right: 0;
	top: 0;
	width: 8px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
	opacity: 0;
	border-radius: 20px;
}

::-webkit-scrollbar-thumb {
	background: #c1c1c1;
	border-radius: 20px;
	opacity: 0;
}

::-webkit-scrollbar-thumb:hover {
	background: #a6a6a6;
	transition: 0.2s;
}

.number {
	margin-left: 15px;
	color: #767676;
	font-size: 15px;
	font-weight: 700;
}

.flex {
	display: flex;
	align-items: end;
	justify-content: space-between;
	gap: 0.5rem;
}

.iconPlus {
	@include center-flex(flex);

	cursor: pointer;
	color: $white;
	background-color: $green;
	border-radius: 100%;
	font-size: 10px;
	padding: 5px;
}

@keyframes close {
	from {
		max-height: 350px;
	}

	to {
		max-height: 0px;
	}
}

.isActive {
	animation: close 0.2s forwards;
}

.addTaskForm {
	padding: 24px;
	border-radius: 4px;
	margin-inline: 5px;
	display: flex;
	flex-direction: column;
	background-color: #e9e9e9;
	position: relative;
	margin-bottom: 3px;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1490196078);

	h2 {
		font-size: 14px;
		margin-bottom: 14px;
		text-transform: uppercase;
		color: #707070;
	}

	.timeslot {
		padding-block: 0;
	}

	.titleDate {
		color: #707070;
		font-weight: 500;
	}

	.dueDate {
		font-weight: 500;
		color: black;
	}

	button {
		margin-top: 0px;
	}
}

.datePicker {
	position: absolute;
	z-index: 9;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	top: -150px;

	.confirm {
		width: 100%;
		margin-top: 15px;
	}
}

.startDate {
	background-color: $white;
	border-radius: 6px;
	padding-inline: $paddingInlineInput;
	padding-block: 7px;
	color: #707070;
	border: 1px solid $lign;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	gap: 10px;
	margin-top: $marginTopInput;

	.icon {
		cursor: pointer;
	}
}

.assignee {
	background-color: $white;
	padding-inline: $paddingInlineInput;
	padding-block: 7px;
	margin-top: $marginTopInput;
	margin-bottom: 10px;
	border-radius: 6px;
	border: 1px solid $lign;
	position: relative;
	cursor: pointer;

	.nameAt {
		font-weight: 500;
	}

	.flexAvatar {
		display: flex;
		align-items: center;
		gap: 15px;
	}
}

.modalAttendee {
	position: absolute;
	z-index: 99;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding: 10px;
	width: 200px;

	.searchInput {
		height: 45px;
		width: 97%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		padding-left: 10px;
		color: $textNav;
		margin-bottom: 20px;

		span {
			height: 45px;
			display: flex;
			align-items: center;
		}

		input {
			font-size: medium;
			color: $textNav;
			margin: 0;
		}
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-top: 15px;
		margin-right: 10px;
		margin-bottom: 15px;
		cursor: pointer;

		.name {
			display: flex;
			align-items: center;
			gap: 5px;
		}

		input {
			width: 15px;
			margin: 0;
		}
	}

	button {
		width: 100%;
	}
}

.dropFlex {
	display: flex;
	align-items: center;
	gap: 10px;
}

.shadow {
	//background-color: #efeeee;
	position: relative;
	border-radius: 3px;
}

.inCol {
	position: relative;
	margin-top: 10px;
	height: 95%;
	// overflow-y: hidden;
	// overflow-x: hidden;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 10;
		width: calc(100% - 8px);
		height: 40px;
		background: linear-gradient(to bottom, transparent, $lightGrey);
	}

	&::-webkit-scrollbar {
		display: none;
	}
}

.colTodo {
	position: relative;
	height: 100%;
	width: 308px;
	overflow-y: auto;
	overflow-x: hidden;

	&::-webkit-scrollbar-track {
		margin-top: 8px;
		width: 2px;
		background-color: transparent;
	}

	&::-webkit-scrollbar {
		width: 5px;
	}
}

.newCol {
	margin-top: 10px;
	height: 100%;
	width: 300px;
	border: 1px solid $lign;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	i {
		font-size: 32px;
		color: $lign;
	}
}

.flexCol {
	display: flex;
	align-items: center;
	gap: 20px;

	p {
		display: flex;
		align-items: start;
		font-weight: 700;
		font-size: 16px;
	}

	.icon {
		cursor: pointer;
		width: max-content;
		visibility: hidden;
	}

	&:hover {
		.icon {
			visibility: visible;
		}
	}
}

.inputTitle {
	display: flex;
	align-items: center;

	input {
		margin: 0;
		min-width: 150px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	button {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.check {
	display: flex;
	align-items: start;
	justify-content: center;
	margin-bottom: 5px;

	.checkbox {
		height: 16px !important;
		min-width: 16px !important;
		max-width: 16px !important;
		display: inline;
	}

	input {
		position: relative;
		margin-top: 1.5px;
		appearance: none;
		display: inline;
		padding: 0;
		min-width: 16px !important;
		max-width: 16px !important;
		height: 16px !important;
		border-radius: 100%;

		&:checked {
			border-color: $green;
		}

		&:checked::before {
			content: '';
			position: absolute;
			height: 8px !important;
			width: 8px !important;
			border-radius: 100%;
			background-color: $green;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	p {
		padding: 0;
		margin-right: 10px;
		font-weight: 500;
	}
}