@import 'styles/global';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

//============================//
//=========== HTML ===========//
//============================//
html {
	height: 100%;
	background-color: white;
}

//============================//
//=========== BODY ===========//
//============================//

* {
	// font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-family: 'Inter', sans-serif;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	font-variant-ligatures: none;
	-moz-osx-font-smoothing: grayscale;
	font-size: $font-size-normal;
	color: $black;
	width: 100%;
	height: 100%;
}

//---- QUILL

.ql-container {
	&.ql-snow {
		border: none !important;
	}

	font-size: 14px;
}

.ql-toolbar {
	border: none !important;
}

.drag {
	opacity: 0;
	z-index: 1000;
}

.drag-clone {
	opacity: 1;
	border: 1px solid $green !important;
}

// #board {
//     position: relative;
// }

@keyframes statusUpdate {
	0% {
		opacity: 1;
		transform: scale(1);
	}

	50% {
		opacity: 0.5;
		transform: scale(1.1);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.animationRequest {
	animation: statusUpdate 1s ease-in-out;
}

.remirror-editor.ProseMirror table {
	width: 90% !important;
}