@import '../../../../../../styles/variables.scss';

.main{
    padding-block: 24px;
    padding-inline: 32px;
    height: calc(100vh - 260px);
	-ms-overflow-style: none;
	overflow-y: auto;

	.filters{
		display: flex;
		align-items: center;
		gap: 15px;
		position: relative;
		margin-bottom: 26px;


		
	
		.buttonFilter{
			position: relative;
			color: $black;
			background-color: #F7F7F7;
			border-radius: 4px;
			font-size: 16px;
			padding-inline: 20px;
			padding-block: 12px;
			display: flex;
			font-weight: 500;
			align-items: center;
			gap: 10px;
			cursor: pointer;
			border: 1px solid $lign;

			&:hover{
				background-color: #ebebeb;
        		border: 1px solid $black;	
			}

			span {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 20px;
			}
		}
	
		.activeButton{
			position: relative;
			font-weight: 600;
			border-radius: 4px;
			padding-inline: 20px;
			font-weight: 500;
			font-size: 16px;
			padding-block: 12px;
			display: flex;
			align-items: center;
			gap: 10px;
			cursor: pointer;
			border: 1px solid $black;	
			background-color: #EBEBEB;
		}

		.filterModalWrapper {
			position: absolute;
    		left: 267px;
    		top: 110%;	
		}
	}
	

	.load{
		margin-top: 20px;
		background-color: transparent;
		color: $black;
		border: 1px solid $black;
		padding-inline: 20px;
		padding-block: 12px;
		border-radius: 4px;
		font-weight: 600;
		margin-bottom: 20px;

			&:hover{
				background-color: #EBEBEB;
			}
	}
}