@import '../../../../styles/variables';

@keyframes fade-in {
	from {
		background-color: rgba(0, 0, 0, 0);
	}

	to {
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.container {
	--animation-timeOut: 0.3s;

	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	animation: fade-in var(--animation-timeOut) ease-in-out forwards;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		display: none;
	}
}

@keyframes fade-out {
	from {
		background-color: rgba(0, 0, 0, 0.4);
	}

	to {
		background-color: rgba(0, 0, 0, 0);
	}
}

.container.fadeOut {
	animation: fade-out var(--animation-timeOut) ease-in-out forwards;
}

@keyframes slide-in {
	from {
		right: -666px;
	}

	to {
		right: 0;
	}
}

.modal {
	position: absolute;
	background-color: $white;
	width: 666px;
	min-height: 100%;
	padding-left: 33px;
	padding-right: 33px;
	padding-top: 26px;
	padding-bottom: 16px;
	z-index: 100;
	animation: slide-in var(--animation-timeOut) ease-in-out forwards;

	h3 {
		display: flex;
		align-items: center;
		gap: 1rem;
		margin-block: 0;

		span {
			display: block;
			transform: scale(0.8) translateY(4px);
		}
	}

	@media screen and (max-width: 800px) {
		width: 90%;
	}
}

@keyframes slide-out {
	from {
		right: 0;
	}

	to {
		right: -666px;
	}
}

.modal.slideOut {
	animation: slide-out var(--animation-timeOut) ease-in-out forwards;
}

.closeButton {
	width: 100%;

	.icon {
		position: absolute;
		right: 47px;
		top: 32px;
		color: $black;
		cursor: pointer;
	}
}

form {
	color: $black;
	margin-top: 20px;

	.duration {
		display: flex;
		gap: 12px;
		margin-top: $marginTopInput;

		input {
			margin-top: 0;
		}

		select {
			width: 120px;
			border-radius: 6px;
			// border: 1px solid rgba(0, 0, 0, 0.07);
		}
	}

	.attendees {
		.flex {
			margin-top: $marginTopInput;
			padding-inline: $paddingInlineInput;
			display: flex;
			align-items: center;
			gap: 5px;
		}

		select {
			width: 15%;
			height: 25px;
			border: none;
			background-color: $inputBg;

			&:focus {
				outline: none;
			}
		}

		button {
			margin-top: 10px;
		}

		.close {
			color: $black;
			font-size: large;
			cursor: pointer;
			margin-left: 15px;
		}
	}

	.isOnline {
		display: flex;
		align-items: center;
		gap: 25px;

		input {
			width: 15px;
		}

		select {
			border: none;
			background-color: $inputBg;

			&:focus {
				outline: none;
			}
		}
	}
}

.containerTags {
	cursor: pointer;
	position: relative;
	border: 1px solid $lign;
	padding-inline: $paddingInlineInput;
	margin-top: $marginTopInput;
	border-radius: 6px;
	margin-bottom: 10px;
	padding-block: 18px;

	.buttonTags {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	p {
		display: flex;
		align-items: center;
		color: #707070;
	}

	.tagsWrapper {
		position: relative;
		display: flex;
		gap: 15px;
		width: 100%;
	}
}

.tags {
	display: flex;
	align-items: center;
	gap: 15px;
	position: relative;
	border-radius: 6px;

	.icon {
		width: 24px;
		cursor: pointer;
	}

	.tag {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 5px;
		border-radius: 87px;
		padding-left: 10px;

		.iconDelete {
			cursor: pointer;
			display: flex;
			align-items: center;
		}
	}
}

.cancel {
	background-color: transparent;
	color: $black;
	margin-left: 15px;
	font-weight: 600;
	border: 1px solid #8a8886;
	border-radius: 2px;
	padding-inline: $paddingInlineInput;
	padding-block: $paddingBlockInput;

	&:hover {
		background-color: #ebebeb;
	}
}

.flex {
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	border-radius: 6px;
	padding-inline: $paddingInlineInput;
	padding-block: $paddingBlockInput;
	text-align: left;
	border: 1px solid #d7d7d7;
	margin-top: 10px;
	padding-inline: 10px;
	padding-block: 8px;
	border-radius: 8px;
	position: relative;

	.attendeeLayout {
		position: absolute;
		inset: 0;
		cursor: pointer;
	}

	.attendeeWrapper {
		display: flex;
		align-items: center;
		gap: 15px;
		padding-block: 6px;
	}
}

.modalAttendee {
	position: absolute;
	z-index: 9;
	width: 50%;
	border-radius: 4px;
	left: 0;
	top: 100%;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding: 15px;

	.searchInput {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		color: $textNav;

		span {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
		}

		input {
			// font-size: medium;
			color: $textNav;
			padding-block: 8px;
			padding-inline: $paddingInlineInput;
			margin-top: 0;
		}
	}

	.containerList {
		max-height: 300px;
		overflow-y: auto;
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-top: 15px;
		margin-right: 10px;
		margin-bottom: 15px;
		cursor: pointer;

		.name {
			display: flex;
			align-items: center;
			gap: 5px;
		}

		input {
			width: 15px;
			margin: 0;
		}
	}

	button {
		width: 100%;
	}
}

.datePicker {
	position: absolute;
	z-index: 9;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	top: -150px;

	.confirm {
		width: 100%;
		margin-top: 15px;
	}
}

.startDate {
	border: 1px solid $lign;
	background-color: $white;
	border-radius: 6px;
	padding-left: 14px;
	padding-right: 10px;
	padding-block: 7px;
	color: #707070;
	margin-top: 10px;
	position: relative;
	gap: 10px;

	.titleLabel {
		color: $green;
		font-size: 13px;
		font-weight: 500 !important;
	}

	.startDateButton {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.datePickerTime {
		margin-top: 7px;
		font-weight: 500;
		color: $black;
	}
}

.statusAndTimeContainer {
	position: relative;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	gap: 10px;

	.durationSelect {
		position: absolute;
		width: min-content;
		top: 73%;
		border-radius: 6px;
		padding-right: 20px;
		background-color: transparent;
		right: 52%;
		transform: translateY(-50%);
	}
}

.description {
	border: 1px solid $lign;
	border-radius: 6px;
	padding-inline: $paddingInlineInput;
	padding-block: $paddingBlockInput;
	margin-bottom: 10px;
}

.dueDateAu {
	border: 1px solid $lign;
	border-radius: 6px;
	margin-bottom: 20px;
}

.btnContainer {
	gap: 1rem;
	display: flex;
	justify-content: end;

	.addBtn {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
	}
}

.inputWrapperTime {
	text-align: left;
	border: 1px solid #d7d7d7;
	padding-inline: 10px;
	padding-block: 8px;
	border-radius: 8px;
	position: relative;

	label {
		position: absolute;
		color: $green;
		font-size: 13px;
		font-weight: 400 !important;
	}

	.inputFlex {
		margin-top: 10px;

		input {
			width: 80%;
			border-radius: 0;
			margin-top: 5px;
			border: none;
			font-size: 14px;
			padding: 1px;

			&:-webkit-autofill {
				-webkit-background-clip: text;
			}

			&::placeholder {
				font-weight: 400;
			}
		}

		select {
			width: 20%;
			font-size: 14px;
			font-weight: 500;
			background-color: transparent;
			padding: 1px;
		}
	}
}

.inputTodoWrapper {
	border: 1px solid #d7d7d7;
	padding-left: 10px;
	padding-right: 12px;
	padding-block: 8px;
	border-radius: 6px;
	position: relative;
	margin-top: 10px;

	.usageSelect {
		display: none;
	}

	.selectChoice {
		padding-top: 20px;
	}

	label {
		position: absolute;
		color: $green;
		font-size: 13px;
		font-weight: 400 !important;
	}

	span {
		position: absolute;
		right: 20px;
		top: 50%;
		z-index: 2;
		transform: translateY(-45%);
	}

	select {
		margin: 0;
		padding: 0;
		padding-top: 20px;
		font-size: 14px;
		font-weight: 500;
		max-width: 100%;
		background-color: transparent;
	}

	.selectIcon {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 14px;
		z-index: -1;
	}

	.customOptionsContainer {
		position: absolute;
		top: 105%;
		z-index: 100;
		max-height: 200px;
		overflow: auto;
		left: 0;
		width: 100%;
		background-color: $white;
		border-radius: 10px;
		box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
		border: 1px solid rgba(128, 128, 128, 0.1);
		cursor: pointer;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 10px;
		}

		li {
			list-style: none;
			padding-inline: 10px;
			margin: 5px;
			border-radius: 10px;
			padding-block: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 10px;

			&:hover {
				background-color: rgba(128, 128, 128, 0.05);
			}

			.checkbox {
				display: inline-block;
				height: 15px;
				width: 15px;
				border: 1px solid rgba(0, 0, 0, 0.3);
				border-radius: 100%;
			}
		}
	}
}

form {
	color: $black;

	label {
		font-weight: 500;
	}
}
