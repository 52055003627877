//==================================//
//============= COLORS =============//
//==================================//

$color-black: #231f20;
$color-dark: #14475a;
$color-light: #b3b3b3;

$black: #231f20;
$greyBackground: #f7f7f7;
$greyMarge: #d7d7d7;

$yellowPrimary: #ffb100;
$yellowSecondary: rgba(255, 184, 0, 0.1);

$white: #ffffff;
$green: #00ad56;
$greenHover: #006b34;
$disa: #a5a5a5;
$lightGreen: #f2fcf7;
// $lightGrey: #faf9f8;
$lightGrey: #f7f7f7;
$grey: #edebe9;
$lightGreyText: #cac9c8;
$red: rgb(156, 21, 21);
$orange: #db461a;
$blue: #2e4552;

$button: #00ad56;
$buttonLight: #00ad56;
$buttonBorder: #02ba5e;
$buttonOutline: #02a554;
$icon: #009c4e;
$inputBg: #fbfaf9;
$inputBgDarker: #f3f2f1;
$textGrey: #707070;
// $textGrey: #807e7d;
$textNav: #a6a4a2;
// $lign: #edebe9;
$lign: #d7d7d7a1;
$teams: #6264a7;
$teamsHover: #a0a2d5;

$greyMail: #605e5c;

$bgLogin: #f2fcf7;

//===============================//
//======= SHARED SIZES ==========//
//===============================//
$header-height: 67px;
$line-height: 40px;

//=====================================//
//========== BORDER RADIUS ============//
//=====================================//
$border-radius-standard: 10px;

//==================================//
//========== FONT SIZES ============//
//==================================//
$font-size-small: 14px;
$font-size-normal: 14px;
$font-size-big: 16px;
$font-size-bigger: 18px;
$font-size-huge: 28px;

//==========================================//
//================= BORDERS ================//
//==========================================//
$border-standard: 1px solid $color-light;

//=========================//
//======= SHADOWS =========//
//=========================//
$heavy-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
$light-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);

//===================================//
//======= PADDING & MARGIN =========//
//==================================//
$paddingInlineInput: 14px;
$paddingBlockInput: 14px;

$marginTopInput: 10px;