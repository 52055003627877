@import '../../../../../../../styles/variables.scss';

.copilotContainer {
	margin-inline: 32px;
	margin-top: 32px;
}

.copilotWrapper {
	display: flex;
	gap: 19px;
}

.isOnline {
	background-color: $greyBackground;
	padding: 20px;
	margin-top: 16px;
	border-radius: 4px;
	// padding-bottom: calc(1.5rem - $marginTopInput);
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	.teams {
		display: flex;
		align-items: center;
		gap: 15px;
		font-size: 16px;
		font-weight: 500;

		img {
			width: 23px;
		}
	}

	.teamsBtn {
		font-size: 16px;
		font-weight: 500;
		margin-top: 2px;
		color: $textGrey;
	}
}

.switch {
	position: relative;
	display: inline-block;
	width: 36px;
	height: 24px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #707070;
		transition: 0.2s;
		border-radius: 34px;

		&::after {
			position: absolute;
			content: '';
			height: 19.5px;
			width: 19.5px;
			left: 2px;
			bottom: 2px;
			background-color: white;
			transition: 0.2s;
			border-radius: 50%;
		}
	}

	input:checked + .slider {
		background-color: $green;
	}

	input:checked + .slider::after {
		-webkit-transform: translateX(13px);
		-ms-transform: translateX(13px);
		transform: translateX(13px);
	}
}

.botStatus {
	display: flex;
	align-items: center;
	margin-top: 12px;
	border: 1px solid $greyMarge;
	padding: 11px 18px;
	width: fit-content;
	border-radius: 48px;
	font-weight: 500;
	background-color: $white;
	font-size: 16px;
	gap: 8px;
}

.flex {
	display: flex;
	justify-content: space-between;
	flex-flow: wrap;
	width: 100%;
	padding: 15px;

	.card {
		width: 48%;
		border: 1px solid $lign;
		padding: 15px;
		cursor: pointer;
	}
}

.copilotPreInfo {
	display: flex;
	align-items: center;
	font-size: 16px;
	gap: 19px;
	background: linear-gradient(90deg, #1e0290 0%, #033ac8 100%);
	color: white;
	padding: 20px;
	border-radius: 4px;
}

.flexButton {
	gap: 50px;
}

.creditContainer {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	background-color: $greyBackground;
	margin-top: 16px;
	border-radius: 4px;
	padding: 20px;
}

.creditInfo {
	gap: 19px;
	display: flex;

	p {
		font-size: 16px;
		font-weight: 500;
	}

	.creditSubTitle {
		margin-top: 3px;
		color: $textGrey;
		width: 80%;
	}

	.creditLeft {
		margin-top: 12px;
		border: 1px solid $greyMarge;
		padding: 11px 18px;
		width: fit-content;
		border-radius: 48px;
		background-color: $white;
		color: #707070;
		font-size: 14px;
	}
}

.creditBtn {
	background-color: $white;
	color: $green;
	border: 1px solid $green;
	font-weight: 500;

	&:hover {
		background-color: $white;
		border: 1px solid $green;
	}
}

.options {
	margin-top: 15px;

	h4,
	a {
		cursor: pointer;
		color: $green;
		font-weight: 700;
	}
}

.promptContainer {
	margin-top: 16px;
	border-radius: 4px;
	padding: 20px;
	border: 1px solid #d7d7d7;
	cursor: pointer;
	width: 300px;
	height: 120px;
	overflow: auto;
}

.flexPrompt {
	display: flex;
	align-items: center;
	gap: 15px;
	flex-flow: wrap;
}

.promptInputContainer {
	// position: absolute;
	// bottom: 10px;

	width: calc(100%);
	margin-top: 10px;

	// @media screen and (max-width: 820px) {

	// 	width: calc(100% - 120px);
	// }
}

.promptInput {
	display: flex;
	align-items: center;
	gap: 16px;
	border: 1px solid $lign;
	border-radius: 15px;
	padding: 20px;

	.promptIcon {
		font-size: 32px;
	}

	input {
		border: none;
		margin: 0;
		padding: 0;
		font-size: 18px;
	}

	.send {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		font-size: 26px;
		padding: 0;
		margin: 0;
		height: fit-content;
		width: fit-content;
	}
}

.select {
	margin-top: 10px;
	margin-bottom: 10px;

	select {
		border-radius: 6px;
		border-color: #d7d7d7;
	}
}

.copilotContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;

	height: calc(100vh - 300px);

	overflow: auto;

	@media screen and (max-width: 1060px) {
		height: unset;
	}

	@media screen and (max-width: 700px) {
		height: 300px;
		overflow: auto;
	}
}

.selectCustom {
	position: relative;
	margin: 15px;

	button {
		background-color: $greyBackground;
		border: none;
		color: $black;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
		padding-block: $paddingBlockInput;
		padding-inline: $paddingInlineInput;

		p {
			white-space: wrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: 500;
			margin: 0;
			padding: 0;
		}

		i {
			font-size: 14px;
		}

		&:hover {
			background-color: #ebebeb;
		}
	}

	.dropdown {
		background-color: white;
		width: 100%;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		position: absolute;
		max-height: 100px;
		overflow: auto;
		top: 100%;
		z-index: 200;

		p {
			padding: 0;
			margin: 0;
			padding: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 14px;

			&:hover {
				background-color: $lightGreen;
				color: $green;
			}
		}
	}
}
