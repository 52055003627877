@import '../../../../../styles/variables';
@import '../../../../../styles/mixins.scss';

// .main {
// 	width: 100%;
// 	display: flex;

// 	@media screen and (max-width: 820px) {
// 		width: 100%;
// 	}

// 	.meetingDetails {
// 		width: 100%;
// 		-ms-overflow-style: none;
// 		scrollbar-width: none;
// 		overflow-y: scroll;

// 		&::-webkit-scrollbar {
// 			display: none;
// 		}

// 		@media screen and (max-width: 1200px) {
// 			width: 100%;
// 		}
// 	}

// 	.meetingsSide {
// 		max-width: 350px;
// 		min-width: 350px;
// 		border-left: 1px solid $lign;

// 		@media screen and (max-width: 1200px) {
// 			display: none;
// 		}
// 	}
// }

.main {
	width: 100%;
	display: flex;


	@media screen and (max-width: 820px) {
		flex-direction: column;
		width: 100%;
	}

	.meetingDetails {
		flex: 1;
		width: 100%;
		//   -ms-overflow-style: none;
		//   scrollbar-width: none;
		//   overflow-y: scroll;

		//   &::-webkit-scrollbar {
		// 	display: none;
		//   }

		@media screen and (max-width: 1200px) {
			width: 100%;
		}
	}

	.meetingsSide {
		flex: 0 0 350px;
		border-left: 1px solid $lign;
		-ms-overflow-style: none;
		scrollbar-width: none;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			display: none;
		}

		@media screen and (max-width: 1200px) {
			display: none;
		}
	}
}


.menu {
	padding-bottom: 30px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 15px;
	border-bottom: 1px solid $lign;
	display: none;



	@media screen and (max-width: 820px) {
		display: block;
	}

	@media screen and (max-width: 500px) {
		padding-left: 64px;
	}

	.calendar {
		display: flex;
		align-items: center;
		gap: 10px;
		float: left;
		display: none;
		color: $icon;
		cursor: pointer;




		@media screen and (max-width: 820px) {
			display: flex;
		}

		span {
			color: $black;
		}
	}

	.files {
		display: flex;
		align-items: center;
		gap: 10px;
		float: right;
		display: none;
		cursor: pointer;
		color: $icon;

		span {
			color: $black;
		}

		@media screen and (max-width: 1200px) {
			display: flex;
		}
	}
}

.modalFiles {
	.close {
		float: right;
		margin: 20px;
		cursor: pointer;
	}

	position: absolute;
	right: 0;
	z-index: 10;
	background-color: white;
	width: 350px;
	height: calc(85% - $header-height);
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding-top: 10px;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: 360px) {
		width: 98%;
	}
}


.flex {
	display: none;
	padding: 10px;

	@media screen and (max-width: 500px) {
		display: flex;
		align-items: center;
		gap: 15px;

	}

	.icon {
		padding: 0;
		margin: 0;
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	p {
		font-weight: 700;
		font-size: 24px;
		margin: 0;
		padding: 0;
	}
}