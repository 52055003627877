@import '../../../../../../styles/variables';

.attendees {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-flow: wrap;
	gap: 40px;
	// margin-top: 20px;

	.avatar {
		width: 33px;
		border-radius: 50%;
	}
}

.groupAttendees {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	align-items: center;
	gap: 0.5rem;
	position: relative;
}

.attendeeWrapper {
	z-index: 50;
	top: 0;
}

.domain {
	display: flex;
	align-items: center;
	gap: 10px;
	z-index: 15;
	text-transform: capitalize;
	border-radius: 100%;
	border: 1px solid $green;
	height: 45px;
	width: 45px;
	overflow: hidden;
	padding: 3px;
	background-color: $white;

	img {
		width: 100%;
		height: 100%;
	}
}

.domainModal {
	display: flex;
	align-items: center;
	gap: 10px;
	z-index: 15;
	text-transform: capitalize;
	border-radius: 100%;
	border: 1px solid $green;
	overflow: hidden;
	padding: 3px;
	background-color: $white;

	img {
		width: 100%;
		height: 100%;
	}
}

.icon {
	// margin-top: 12px;
	margin-left: 15px;
	// height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.modal {
	z-index: 99;
	position: absolute;
	background-color: $white;
	padding: 15px;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: 400px;

	p {
		margin: 0;
		padding: 0;
	}
}

.tabs {
	display: flex;
	flex-direction: row;
	overflow: auto;
	gap: 1.25rem;
	color: #8a8886;
	margin-bottom: 15px;
	padding: 10px;

	a {
		cursor: pointer;

		&:hover {
			color: $black;
		}
	}

	.activeTab {
		font-weight: 600;
		padding-bottom: 5px;
		border-bottom: 2px solid $green;
		color: $black;
	}
}

.flex {
	display: flex;
	align-items: center;
	gap: 15px;
	margin-block: 10px;
}

.attendeesList {
	max-height: 500px;
	overflow: auto;
}

.divModal {
	height: 0;
	width: 0;
	padding: 0;
	margin: 0;
}
