@import '../../../../../styles/variables.scss';

.headerShimmer {
    padding-left: 32px;
    padding-right: 48px;
    padding-top: 24px;
    padding-bottom: 26.5px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    border-bottom: 1px solid $lign;
}

.shimmerAvatarContainer {
    position: relative;
    display: flex;
    align-items: center;
    gap: -50px;
}