@import '../../../../styles/variables.scss';

@keyframes fade-in {
	from {
		background-color: rgba(0, 0, 0, 0);
	}

	to {
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.container {
	--animation-timeOut: 0.3s;

	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	animation: fade-in var(--animation-timeOut) ease-in-out forwards;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		display: none;
	}
}

@keyframes fade-out {
	from {
		background-color: rgba(0, 0, 0, 0.4);
	}

	to {
		background-color: rgba(0, 0, 0, 0);
	}
}

.container.fadeOut {
	animation: fade-out var(--animation-timeOut) ease-in-out forwards;
}

@keyframes slide-in {
	from {
		right: -666px;
	}

	to {
		right: 0;
	}
}

.modal {
	position: absolute;
	right: 0;
	background-color: $white;
	width: 660px;
	padding-inline: 33px;
	padding-block: 26px;
	min-height: 100%;
	animation: slide-in var(--animation-timeOut) ease-in-out forwards;

	.timeFLex {
		display: flex;
	}

	@media screen and (max-width: 800px) {
		margin: 0;
		width: 90%;
	}

	@media screen and (max-width: 600px) {
		width: 85%;
	}

	@media screen and (max-width: 500px) {
		height: calc(100% - 100px);
		overflow: auto;
	}

	.titleWrapper {
		padding-right: $paddingInlineInput;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		h3 {
			display: flex;
			gap: 1rem;
			width: 100%;
			justify-content: start;
			align-items: center;
			margin: 0;
			padding: 0;
			color: $black;
		}
	}
}

@keyframes slide-out {
	from {
		right: 0;
	}

	to {
		right: -666px;
	}
}

.modal.slideOut {
	animation: slide-out var(--animation-timeOut) ease-in-out forwards;
}

.closeButton {
	.icon {
		color: $black;
	}
}

form {
	color: $black;

	label {
		font-weight: 500;
	}

	.confirmBtn {
		margin-top: 10px;

	}
}

.cancel {
	background-color: $white;
	color: $black;
	font-weight: 600;
	border: 1px solid $black;
	margin-left: 16px;

	&:hover {
		background-color: #ebebeb;
	}
}

.flexAccess {
	display: flex;
	align-items: center;
	margin-top: 15px;
	border-radius: 6px;
	border: 1px solid $lign;
	width: fit-content;
	margin-bottom: 5px;
	overflow: hidden;

	i {
		font-size: 16px;
	}
}

.access {
	display: flex;
	align-items: center;
	gap: 15px;
	border-radius: 6px;
	cursor: pointer;
	background-color: $white;
	padding-inline: 15px;
	height: 45px;
	margin: 0;
}

.selectAccess {
	display: flex;
	align-items: center;
	gap: 15px;
	height: fit-content;
	cursor: pointer;
	background-color: $green;
	padding-inline: 15px;
	height: 45px;
	color: $white;
	margin: 0;
}

.addAttendeeContainer {
	border: 1px solid $lign;
	background-color: $white;
	border-radius: 6px;
	padding-inline: $paddingInlineInput;
	padding-block: $paddingBlockInput;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	color: #707070;
	margin-top: $marginTopInput;
	cursor: pointer;

	.invite {
		display: flex;
		align-items: center;
		gap: 15px;
	}
}

.addAttendee {
	position: relative;
}

.iconZone {
	position: relative;
}

.modalPeople {
	position: absolute;
	z-index: 99;
	width: 50%;
	border-radius: 4px;
	left: 0;
	top: 50px;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding: 15px;

	.searchInput {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		color: $textNav;

		span {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
		}

		input {
			// font-size: medium;
			color: $textNav;
			padding-block: 8px;
			padding-inline: $paddingInlineInput;
			margin-top: 0;
		}
	}

	.peopleList {
		max-height: 320px;
		overflow: auto;
	}

	.avatar {
		display: flex;
		align-items: center;
		gap: 15px;
	}

	.row {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		padding-block: 10px;
		margin-block: 5px;
		padding-left: 5px;
		padding-right: 10px;
		border-radius: 4px;
		z-index: 10;
		cursor: pointer;

		.avatar {
			max-width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&:hover {
			background-color: rgba(128, 128, 128, 0.04);
		}

		input {
			width: 15px;
			margin: 0;
		}
	}

	button {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
}

.avatarWrapper {
	display: flex;
	align-items: center;
	gap: 10px;
}

.avatar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.icon {
		margin-right: 15px;
	}
}

.button {
	margin-top: 20px;
}

.invited {
	margin-top: 15px;
	margin-bottom: 15px;
}

.addAttendee {
	cursor: pointer;
	border: 1px solid $lign;
	background-color: $white;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #707070;
	font-style: italic;
	position: relative;
	width: 100%;
	padding-right: 10px;

	input {
		margin-top: 0;
		border: none;
		width: 100%;
	}
}

.modalAttendee {
	position: absolute;
	z-index: 9;
	width: 100%;
	border-radius: 4px;
	left: 0;
	top: 103%;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);

	.searchInput {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		color: $textNav;

		span {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
		}

		input {
			// font-size: medium;
			color: $textNav;
			padding-block: 8px;
			padding-inline: $paddingInlineInput;
			margin-top: 0;
		}
	}

	.containerList {
		max-height: 300px;
		overflow-y: auto;
		cursor: pointer;
		color: rgba(0, 128, 0, 0);
		position: relative;
		z-index: 10;
	}

	.list {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		padding-block: 5px;
		margin-block: 5px;
		padding-left: 15px;
		padding-right: 15px;
		border-radius: 4px;
		z-index: 10;

		&:hover {
			background-color: rgba(128, 128, 128, 0.04);
		}

		.name {
			display: flex;
			align-items: center;
			gap: 10px;
			color: $black;
		}

		input {
			display: none;
			width: 15px;
			margin: 0;
		}

		i {
			height: 15px;
			color: black;
		}
	}

	button {
		padding: 0;
		padding-left: 13px;
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		background-color: transparent;
		color: $green;
		border: none;

		&:hover {
			background-color: transparent;
		}
	}
}

.attendees {
	margin-top: $marginTopInput;
	position: relative;

	.flex {
		padding-top: $marginTopInput;
		margin-bottom: calc($marginTopInput * 2);
		padding-right: 14px;
		padding-left: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.name {
			display: flex;
			align-items: center;
			gap: 15px;
			margin: 0;
		}
	}

	select {
		width: 100px;
		border: none;
		background-color: $inputBg;
		margin-top: 9px;

		&:focus {
			outline: none;
		}
	}

	button {
		margin-top: 10px;
	}

	.close {
		color: $black;
		cursor: pointer;
	}
}