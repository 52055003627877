@import '../../../../styles/variables';

.inputWrapper {
    text-align: left;
    border: 1px solid #D7D7D7;
    margin-bottom: 20px;
    padding-inline: 10px;
    padding-block: 8px;
    border-radius: 8px;
    position: relative;

    cursor: pointer;

    .usageSelect {
        display: none;
    }

    .selectChoice {
        padding-top: 20px;
        max-height: 40px;
        width: 1200px;
    }

    .selectChoiceWrapper {
        position: relative;
        overflow: hidden; 
        margin-right: 80px;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 40px;
            background: linear-gradient(90deg, transparent 50%, white 90%);
        } 
    }

    .counter {
        position: absolute;
        right: 60px;
        font-weight: 500;
        top: 51%;
    }

    label {
        position: absolute;
        color: $green;
        font-size: 13px;
        font-weight: 400 !important;
    }

    span {
        position: absolute;
        right: 20px;
        top: 50%;
        z-index: 2;
        transform: translateY(-45%);
    }

    input {
        margin-bottom: 20px;
        padding: 0;
        padding-top: 17px;
        margin: 0;
        border-radius: 0;
        margin-top: 5px;
        max-width: 100%;
        border: none;
        font-size: 14px;
        font-weight: 500;

        &:-webkit-autofill { 
            -webkit-background-clip: text;
        }

        &::placeholder {
            font-weight: 400;
        }
    }

    select {
        margin: 0;
        padding: 0;
        padding-top: 20px;
        font-size: 14px;
        font-weight: 500;
        max-width: 100%;
        background-color: transparent;
    }

    .selectIcon {
        position: absolute;
        right: 20px;
        top: 52%;	
        transform: translateY(-40%);
        font-size: 14px;
        z-index: -1;
    }

    .customOptionsContainer {
        position: absolute;
        top: 105%;
        z-index: 100;
        max-height: 300px;
        overflow: auto;
        left: 0;
        width: 100%;
        background-color: $white;
        border-radius: 6px;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(128, 128, 128, 0.1);
        cursor: pointer;

    
        &::-webkit-scrollbar {
            width: 6px;
        }  

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
            margin-block: 10px;
        }

        li {
            list-style: none;
            padding-inline: 8px;
            margin: 5px;
            border-radius: 10px;
            padding-block: 12px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 15px;

            &:hover {
                background-color: rgba(128, 128, 128, 0.05);
            }

            .checkbox {
                display: inline-block;
                height: 17px;
                width: 17px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1.5px;
            }
        }
    }
}