@import '../../../../../../../styles/variables.scss';
@import '../../../../../../../styles/mixins.scss';

.over {
	overflow-x: none;
	height: calc(100vh - 258px);
	position: relative;
	padding-top: 24px;

	.mainTodoPage {
		margin-top: 10px;
	}
}

.overviewList {
	padding-top: 50px;

	&::-webkit-scrollbar {
		display: none;
	}

	overflow-x: auto;
	white-space: nowrap;

	table {
		margin: 0 auto;
		width: 100%;
		border-collapse: collapse;
		empty-cells: show;
		text-align: left;
		padding-top: 31px;

		h2 {
			margin-top: 25px;
			font-weight: 700 !important;
			font-size: 16px;
			margin-bottom: 17px;
		}

		.todoName {
			padding-left: 22px;
			padding-bottom: 15px;
		}

		th,
		td {
			padding-right: 10px;
		}

		input[type='checkbox'] {
			width: 18px !important;
			height: 24px;
			margin: 0;
		}

		tbody {
			max-height: 650px;
			overflow-y: auto;
			overflow-x: hidden;

			tr {
				border: none;

				&:hover {
					background-color: $lightGrey;
				}
			}
		}

		td {
			display: table-cell;

			.span {
				color: $textGrey;
			}
		}

		tr {
			border-bottom: 1px solid $lign;

			th {
				padding-bottom: 15px;
				font-size: medium;

				.title {
					display: flex;
					align-items: center;
					gap: 10px;
					font-weight: 500;
					font-size: 16px;
					color: $textGrey;
				}
			}
		}

		.more {
			text-align: right;
			position: relative;

			.icon {
				float: right;
				cursor: pointer;
				padding: 10px;
				margin-right: 8px;
				width: fit-content;
			}
		}

		tr {
			.more {
				color: $lign;
			}

			&:hover {
				.more {
					color: $black;
				}
			}
		}

		.title {
			white-space: wrap;
			overflow: hidden;
			font-size: 16px;
			text-overflow: ellipsis;
			margin: 0;
			padding: 0;
		}

		.done {
			opacity: 0.5;
		}
	}

	.form {
		margin-top: 20px;
		margin-left: 20px;
		display: flex;
		align-items: center;

		.submit {
			margin-top: 5px;
			width: 24px;
			cursor: pointer;
			color: $green;
			font-size: 20px;
		}

		.add {
			color: $green;
			border: none;
			margin-left: 5px;
			cursor: pointer;
		}
	}

	.tags {
		display: flex;
		align-items: center;
		gap: 15px;
		position: relative;

		.plus {
			border-radius: 50%;
			border: none;
			background-color: $lign;
			font-size: 14px;
			padding: 2px;
			height: 25px;
			width: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.tag {
			padding-inline: 16px;
			padding-block: 5px;
			border-radius: 87px;

			div {
				font-size: 14px;
				font-weight: 500 !important;
			}
		}
	}
}

.options {
	position: absolute;
	z-index: 9;
	background-color: white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding-right: 10px;
	top: 40px;
	right: 0;

	.remove {
		color: red;
		margin: 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.confirmBox {
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	div {
		margin: auto;
		margin-top: 300px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;

		button {
			margin: 10px;
		}
	}
}

.modalAttendees {
	position: absolute;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: max-content;
	padding: 15px;
	top: 50px;
	z-index: 9;
	max-height: 500px;
	overflow-y: auto;

	.attendee {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
	}
}

.form {
	background-color: $white;
	padding-inline: 22px;
	padding-block: 22px;
	border: 1px solid $lign;
	z-index: 90;
	min-width: 400px;
	position: absolute;
	top: 75px;
	left: 32px;
	border-radius: 4px;
	box-shadow: $heavy-shadow;
	@include fade-animation;

	// &::after {
	// 	content: '';
	// 	position: absolute;
	// 	left: 0;
	// 	width: 100%;
	// 	height: .5px;
	// 	top: 68px;
	// 	background-color: $lign;
	// }

	.icon {

		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media screen and (max-width: 800px) {
		margin: 0;
		width: 90%;
	}

	p {
		font-weight: 500;
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 10px;
	}

	form {
		margin-top: 20px;

		input {
			border: 1px solid $lign;
			padding-block: $paddingBlockInput;
			padding-inline: $paddingInlineInput;
		}

		button {
			display: flex;
			align-items: center;
			gap: 8px;
			width: 100%;
			margin-top: $marginTopInput;
		}

		.formButton {
			width: min-content;
			float: right;
			position: relative;
			z-index: 10;
			font-size: 16px;
			margin-top: 15px;
			padding-inline: 24px;
			padding-block: 12px;
		}
	}
}

.selectCustom {
	position: relative;

	button {
		background-color: $greyBackground;
		border: 1px solid $lign;
		color: $black;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
		padding-block: $paddingBlockInput;
		padding-inline: $paddingInlineInput;

		p {
			white-space: wrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: 500;
			margin: 0;
			padding: 0;
		}

		i {
			font-size: 14px;
		}

		&:hover {
			background-color: #ebebeb;
		}
	}

	.dropdown {
		background-color: white;
		width: 100%;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		position: absolute;
		max-height: 100px;
		overflow: auto;
		top: 100%;
		z-index: 200;

		p {
			padding: 0;
			margin: 0;
			padding: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 14px;

			&:hover {
				background-color: $lightGreen;
				color: $green;
			}
		}
	}
}

.flex {
	display: flex;
	align-items: center;
	gap: 15px;
	padding-left: 18px;
}

.cancel {
	background-color: transparent;
	color: $black;
	font-weight: 500;
	font-size: 16px;
	padding-block: 12px;
	padding-inline: 24px;
	border: 1px solid #8a8886;
	border-radius: 4px;
	display: flex;
	align-items: center;
	gap: 10px;

	&:hover {
		background-color: #ebebeb;
	}
}

.error {
	color: $red;
}

.flex {
	display: flex;
	align-items: center;
	gap: 15px;
	padding: 0;
	margin: 0;
}

.top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-flow: wrap;
	padding-bottom: 0;
	padding-inline: 32px;
	margin-bottom: 32px;

	.icons {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $lign;
		border-radius: 4px;

		.iconSelect {
			background-color: #2e4552;
			color: #dcdfe3;
			cursor: pointer;
			height: 43.5px;
			width: 43.5px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:nth-child(1) {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}

			&:nth-child(4) {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}

		.icon {
			background-color: transparent;
			color: #2e4552;
			cursor: pointer;
			height: 43.5px;
			width: 43.5px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.filters {
	display: flex;
	align-items: center;
	gap: 15px;
	position: relative;

	.place {
		position: relative;
	}

	.filterModalWrapper {
		position: absolute;
		top: 110%;
		left: 0;
	}

	.buttonFilter {
		position: relative;
		background-color: #f7f7f7;
		border-radius: 4px;
		padding-inline: 24px;
		padding-block: 12px;
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 8px;
		font-weight: 500;
		cursor: pointer;
		border: 1px solid #f7f7f7;
		transition: 100ms;

		&:hover {
			background-color: #ebebeb;
			border: 1px solid $black;
		}
	}

	.activeButton {
		position: relative;
		border-radius: 4px;
		padding-inline: 24px;
		padding-block: 12px;
		font-weight: 500;
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 8px;
		cursor: pointer;
		border: 1px solid $black;
		background-color: #ebebeb;
	}
}

.flexButton {
	display: flex;
	align-items: center;
	gap: 15px;
}