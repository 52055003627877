.tooltip {
	position: relative;
	padding-block: 10px;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;

	.tooltipContent {
		font-size: 16px !important;
		font-weight: 500;
		color: #231f20;
	}
}
