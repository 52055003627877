@import '../../../../../styles/variables.scss';

.shimmerEditor {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-top: 12px;
    border: 1px solid #F7F7F7;
    border-radius: 4px;

    &:last-child {
        margin-bottom: 20px;
    }
}

.shimmerText {
    padding-block: 32px;
    padding-inline: 20px;
    height: 380px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.shimmerComment {
    height: 50px;
    background-color: #F7F7F7;
}