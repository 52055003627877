.tag {
    display: flex;
    align-items: center;
    gap: 7px;
    padding-block: 3px;
    padding-inline: 10px;
    border-radius: 87px;
    font-size: 14px;
    width: max-content;
    height: fit-content;

    .iconDelete {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 11px;
        margin: 0;
        padding: 0;
    }

}