@import '../../../../../../../styles/variables';

@-webkit-keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

// ---------------

.main {
	padding: 18px;
	border-radius: 4px;
	position: absolute;
	width: 350px;
	top: 50px;
	right: 120px;
	z-index: 9;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);

	h2 {
		font-weight: 600;
		padding: 0;
		margin: 0;
		margin-bottom: 24px;
	}

	.subTitle {
		color: var(--grey-palette-grey-190, #201f1e);
		font-family: Segoe UI;
		font-size: 14px;
		margin-top: 15px;
		padding-inline: 3px;
		margin-bottom: 5px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
	}

	.unshare {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 7px;
		color: #fff;
		font-family: Segoe UI;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		width: 100%;
		padding-block: 11px;
		// padding: 8px 68px 8px 53px;
	}

	.shareButton {
		display: flex;
		align-items: center;
		gap: 7px;
		color: #fff;
		text-align: center;
		font-family: Segoe UI;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		padding: 8px 10px 8px 10px;
		cursor: default;

		span {
			border: 1px solid white;
			border-radius: 100%;
			width: 20px;
			height: 20px;
		}
	}
}

.shareDiv {
	position: relative;
	width: 100%;
	margin-top: 10px;
}

.buttonLinkCopied {
	display: flex;
	width: 124px;
	padding: 5px 5px 7px 5px;
	justify-content: center;
	align-items: center;
	gap: 3px;
	border-radius: 52px;
	border: 0.5px solid #00ad56;
	background: var(--grey-palette-white, #fff);
	box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
	position: absolute;
	right: 0;

	color: var(--theme-primary, #00ad56);
	text-align: center;
	font-family: Segoe UI;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;

	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;

	span {
		border: 1px solid #00ad56;
		border-radius: 100%;
		width: 20px;
		height: 20px;
		color: #00ad56;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.share {
	display: flex;
	align-items: center;
	gap: 10px;
	color: var(--grey-palette-grey-190, #201f1e);
	font-family: Segoe UI;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	padding-inline: 3px;
	line-height: 20px;
}

.link {
	text-decoration: underline;
	cursor: pointer;
}

.collaborators {
	max-height: 200px;
	overflow: auto;
	padding-inline: 3px;

	.row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #efedec;
		padding-block: 10px;

		.avatar {
			display: flex;
			align-items: center;
			gap: 10px;
		}

		.right {
			color: var(--grey-palette-grey-130, #605e5c);
			text-align: right;
			font-family: Segoe UI;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px;
		}
	}
}

.containerSharing {
	position: fixed;
	z-index: 9;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: 250px;
	padding: 20px;
	top: 100px;
	color: $black;
	margin-top: 80px;

	button {
		width: 100%;
		text-align: center;
		margin: 0 auto;
		margin-top: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	input {
		width: 15px;
	}

	.cancel {
		background-color: $white;
		color: $black;
		font-weight: 600;
		border: 1px solid $black;

		&:hover {
			background-color: #ebebeb;
		}
	}

	.send {
		margin-top: 20px;
		max-height: 200px;
		overflow-y: auto;

		.mails {
			display: flex;
			align-items: center;
			gap: 5px;
			height: 40px;
		}

		.mail {
			display: flex;
			align-items: center;

			gap: 5px;
		}
	}
}

.search_input {
	background-color: $white;
	width: auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	padding-left: 10px;
	color: $textNav;
	margin: 5px;

	input {
		width: 100px;
		font-size: medium;
		color: $textNav;
	}
}
