@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/mixins.scss';

.overview {
	width: 100%;
}

.column {
	padding-inline: 25px;
}

.noData {
	padding-inline: 25px;
}

.mainTodoBoardPage {
	background-color: #f7f7f7;
	overflow: auto;
	display: flex;
	// gap: 50px;
	padding-top: 32px;
	width: calc(100vw - 424px);
	height: calc(100vh - 405px);
	@include fade-animation;
}

.item {
	position: relative;
	display: flex;
	background-color: white;
	box-shadow: 1px 1px 3px 0px #00000020;
	border-radius: 6px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	width: 300px;
	padding-right: 15px;
	padding-left: 10px;
	padding-block: 20px;
	z-index: 50;
	cursor: pointer;

	p {
		margin: 0;
		padding: 0;
		margin-bottom: 10px;
	}

	.info {
		display: flex;
		width: 100%;
		align-items: start;
		justify-content: space-between;
	}

	.icons {
		display: flex;
		align-items: center;
		gap: 15px;
		color: $lign;
		visibility: hidden;
	}

	&:hover {
		.icons {
			color: $black;
			visibility: visible;
		}
	}
}

.cardWrapper {
	padding-left: 15px;
	width: 100%;
}

.leftLine {
	position: absolute;
	width: 5px;
	bottom: 10px;
	left: 10px;
	top: 10px;
	border-radius: 10px;
	flex-basis: 1;
}

.bottomCard {
	display: flex;
	flex-direction: column;
	gap: 15px;

	.tagWrapper {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.moreTags {
		font-weight: 500;
		margin-left: 10px;
	}

	.tag {
		font-size: 14px;
		padding-block: 5px !important;
		padding-inline: 16px !important;
		border-radius: 87px;
	}
}

.layout {
	position: absolute;
	inset: 0;
}

.emptyPlaceHolder {
	width: 300px;
}

.placeholder {
	opacity: 0.5;
	height: 8px;
	background-color: $lightGrey;
}

.activePlaceHolder {
	// height: 123px;
	padding-top: 30px;
	border-radius: 6px;
	margin-top: 8px;
	margin-bottom: 8px;
	border-radius: 3px;
	background-color: #efeeee;
}

/* width */
::-webkit-scrollbar {
	width: 8px;
	height: 12px;
}

// /* Track */
// ::-webkit-scrollbar-track {
// 	background: #f1f1f1;
// 	border-radius: 20px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
// 	background: #c1c1c1;
// 	border-radius: 20px;
// 	transition: 0.2s;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
// 	background: #a6a6a6;
// 	transition: 0.2s;
// }

.number {
	margin-left: 15px;
	color: #767676;
	font-size: 15px;
	font-weight: 700;
}

.flex {
	display: flex;
	align-items: end;
	justify-content: space-between;
	gap: 0.5rem;
}

.iconPlus {
	@include center-flex(flex);

	cursor: pointer;
	color: $white;
	background-color: $green;
	border-radius: 100%;
	font-size: 10px;
	padding: 5px;
}

.addTaskForm {
	padding: 15px;
	background-color: #e9e9e9;
	position: relative;
	margin-bottom: 15px;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1490196078);

	h2 {
		margin-bottom: 5px;
	}
}

.datePicker {
	position: absolute;
	z-index: 9;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	top: -150px;

	.confirm {
		width: 100%;
		margin-top: 15px;
	}
}

.startDate {
	background-color: $white;
	border-radius: 6px;
	padding-inline: $paddingInlineInput;
	padding-block: $paddingBlockInput;
	color: #707070;
	border: 1px solid $lign;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	gap: 10px;
	margin-top: $marginTopInput;

	.icon {
		cursor: pointer;
	}
}

.assignee {
	background-color: $white;
	padding-inline: $paddingInlineInput;
	padding-block: $paddingBlockInput;
	margin-top: $marginTopInput;
	margin-bottom: 10px;
	border-radius: 6px;
	border: 1px solid $lign;
	position: relative;
	cursor: pointer;

	.flexAvatar {
		display: flex;
		align-items: center;
		gap: 15px;
	}
}

.modalAttendee {
	position: absolute;
	z-index: 9;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding: 10px;
	width: 300px;

	.searchInput {
		height: 45px;
		width: 97%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		padding-left: 10px;
		color: $textNav;
		margin-bottom: 20px;

		span {
			height: 45px;
			display: flex;
			align-items: center;
		}

		input {
			font-size: medium;
			color: $textNav;
			margin: 0;
		}
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-top: 15px;
		margin-right: 10px;
		margin-bottom: 15px;
		cursor: pointer;

		.name {
			display: flex;
			align-items: center;
			gap: 5px;
		}

		input {
			width: 15px;
			margin: 0;
		}
	}

	button {
		width: 100%;
	}
}

.dropFlex {
	display: flex;
	align-items: center;
	gap: 10px;
}

.shadow {
	//background-color: #efeeee;
	position: relative;
	border-radius: 3px;
}

.inCol {
	position: relative;
	margin-top: 10px;
	height: 95%;
	// overflow-y: hidden;
	// overflow-x: hidden;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1000;
		width: calc(100% - 8px);
		height: 40px;
		background: linear-gradient(to bottom, transparent, $lightGrey);
	}

	&::-webkit-scrollbar {
		display: none;
	}
}

.colTodo {
	position: relative;
	height: 100%;
	width: 300px;
	overflow-y: auto;
	overflow-x: hidden;

	&::-webkit-scrollbar-track {
		margin-top: 8px;
	}
}

.newCol {
	margin-top: 10px;
	height: 90%;
	width: 100px;
	border: 1px dotted $lign;
	cursor: pointer;
}

.flexCol {
	display: flex;
	align-items: center;
	gap: 20px;

	p {
		display: flex;
		align-items: start;
		font-weight: 700;
		font-size: 16px;
	}

	.icon {
		cursor: pointer;
		width: max-content;
		visibility: hidden;
	}

	&:hover {
		.icon {
			visibility: visible;
		}
	}
}

.inputTitle {
	display: flex;
	align-items: center;

	input {
		margin: 0;
		min-width: 150px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	button {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.check {
	display: flex;
	align-items: start;
	justify-content: center;
	margin-bottom: 5px;

	.checkbox {
		height: 16px !important;
		min-width: 16px !important;
		max-width: 16px !important;
		display: inline;
	}

	input {
		position: relative;
		margin-top: 1.5px;
		appearance: none;
		display: inline;
		padding: 0;
		min-width: 16px !important;
		max-width: 16px !important;
		height: 16px !important;
		border-radius: 100%;

		&:checked {
			border-color: $green;
		}

		&:checked::before {
			content: '';
			position: absolute;
			height: 8px !important;
			width: 8px !important;
			border-radius: 100%;
			background-color: $green;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	p {
		padding: 0;
		margin-right: 10px;
		font-weight: 500;
	}
}
