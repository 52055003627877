@import '../../../../styles/variables';

.main {
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
	white-space: pre-wrap;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		display: none;
	}
}

.modal {
	background-color: $white;
	width: 900px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 16px;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 50px;
	height: 438px;
	position: relative;

	@media screen and (max-width: 950px) {
		width: 90%;
	}

	@media screen and (max-width: 830px) {
		height: unset;
	}

	.close {
		cursor: pointer;
		color: rgba(32, 31, 30, 1);
		position: absolute;
		right: 15px;
		top: 15px;
	}

	.in {
		display: flex;
		align-items: center;

		@media screen and (max-width: 830px) {
			display: block;
		}
	}

	.left {
		width: 50%;

		@media screen and (max-width: 830px) {
			width: 100%;
		}

		h2 {
			padding: 0;
			margin: 0;
			margin-left: 32px;
			margin-bottom: 20px;

			@media screen and (max-width: 830px) {
				padding-top: 18px;
			}
		}

		.text {
			white-space: pre-wrap;
			max-width: 400px;
			margin: 0;
			padding: 0;
			margin-left: 32px;
		}

		.form {
			margin-left: 32px;
			margin-top: 31px;
			margin-right: 20px;

			input {
				width: 15px;
			}

			.buttons {
				@media screen and (max-width: 830px) {
					text-align: center;
					margin: 0 auto;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-flow: wrap;
				}
			}

			.cancel {
				background-color: $white;
				color: $black;
				font-weight: 600;
				border: 1px solid $black;
				margin-left: 15px;

				&:hover {
					background-color: #ebebeb;
				}

				@media screen and (max-width: 380px) {
					margin: 0;
				}

				@media screen and (max-width: 365px) {
					margin-top: 15px;
				}
			}

			.send {
				margin-top: 20px;
				margin-bottom: 20px;
				height: 240px;
				overflow-y: auto;

				.mails {
					display: flex;
					align-items: center;
					gap: 18px;
					height: 40px;
					border-bottom: 1px solid $lign;
				}

				.mail {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 8px;

					.grey {
						color: #717171;
						font-family: Segoe UI;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px;

						@media screen and (max-width: 830px) {
							width: 100%;
						}
					}
				}
			}
		}
	}

	.right {
		margin: 0 auto;
		text-align: center;
		border-radius: 4px;
		background: #f2fcf7;
		width: 50%;
		height: 435px;
		margin-top: 10px;

		@media screen and (max-width: 830px) {
			width: 100%;
		}

		.lign {
			width: 43px;
			height: 1px;
			background: #a1e7c4;
		}

		.link {
			text-decoration: underline;
			cursor: pointer;
		}

		.flex {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.image {
			img {
				width: 48px;
			}
		}

		h2 {
			color: #00ad56;
			text-align: center;
			font-family: Segoe UI;
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 21px;
		}
	}
}
