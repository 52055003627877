@import '../../../../styles/variables.scss';

.icons {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $lign;
    border-radius: 4px;
    font-size: 16px;
    overflow: hidden;

    .slider {
        position: absolute;
        height: 100%;
        width: 50%;
        left: 0;
        background-color: #2e4552;
        z-index: -1;
        transition: .1s ease-in-out;
    }


    .iconSelect {
        color: #dcdfe3;
        cursor: pointer;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .1s ease-in-out;

        &:nth-child(1) {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:nth-child(4) {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    .icon {
        background-color: transparent;
        color: #2e4552;
        cursor: pointer;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}