@import '../../../../styles/variables';

.hours {
	width: 55px;
	height: calc(60px * 24);
	border-right: 1px solid $lign;
	color: #605e5c;

	.hour {
		height: 60px;
		padding-left: 10px;
		font-size: 14px;
		line-height: 16px;
	}
}
