@import '../../../../styles/variables';

.container {
	width: 341px;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 999;
	background-color: white;
	box-shadow: 2px 2px 20px 0px rgb(200 198 196);
	padding-inline: 16px;
	padding-block: 16px;
	border-radius: 6px;

	.iconClose {
		cursor: pointer;
		margin: 5px;
		float: right;
	}

	h3 {
		margin: 0;
		font-size: 18px;
		margin-bottom: 14px;
	}

	form {
		padding: 0;
		margin: 0;

		hr {
			color: $lign;
			opacity: 0.3;
		}

		select {
			background-color: $inputBg;
			border: none;
		}

		p {
			margin-top: 8px;
			font-size: smaller;
			color: grey;
		}

		button {
			&:disabled {
				pointer-events: none;
				opacity: 0.7;
				padding-inline: 12px;
				padding-block: 8px;
				font-size: 14px;
			}
		}
	}

	.tags {
		display: flex;
		max-height: 200px;
		overflow-y: auto;
		flex-flow: wrap;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
		margin-top: 10px;

		.tag {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-basis: 33%;
			padding-block: 5px;
			padding-inline: 10px;
			cursor: pointer;
			border-radius: 87px;
		}
	}
}

.input {
	border-bottom: 1px solid rgba(0, 0, 0, 0.07) !important;
	padding-bottom: 8px;

	input {
		padding-block: 8px;
	}
}

.pickerColorWrapper {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
}

.pickColor {
	position: relative;
	background-color: $white;
	display: flex;
	align-items: center;
	border-radius: 100%;
	color: #8a8886;
}

.colors {
	position: absolute;
	background-color: white;
	z-index: 9;
	border-radius: 6px;
	margin: 0;
	padding: 0;
	top: 100%;
	left: 0;
	box-shadow: 0 0 6px 0px rgb(200 198 196);

	table {
		margin: 0;

		td {
			margin: 0;
			padding: 5px;

			button {
				width: 15px;
				height: 20px;
				margin: 0;
				padding: 0;
			}
		}
	}
}