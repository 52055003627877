@import '../../../../../styles/variables.scss';

.filterBtn {
    position: relative;
    background-color: #f7f7f7;
    border-radius: 4px;
    padding-inline: 24px;
    padding-block: 12px;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid $lign;
    transition: 100ms;

    &:hover {
        background-color: #ebebeb;
        border: 1px solid $black;
    }

    &.active {
        border: 1px solid $black;
		background-color: #ebebeb;
    }
    
}