@import '../../../../../../../../styles/variables.scss';

@keyframes fade-in {
	from {
		background-color: rgba(0, 0, 0, 0);
	}
	to {
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.container {
	--animation-timeOut: 0.3s;

	position: fixed;
	z-index: 30;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	animation: fade-in var(--animation-timeOut) ease-in-out forwards;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		display: none;
	}
}

@keyframes fade-out {
	from {
		background-color: rgba(0, 0, 0, 0.4);
	}
	to {
		background-color: rgba(0, 0, 0, 0);
	}
}

.container.fadeOut {
	animation: fade-out var(--animation-timeOut) ease-in-out forwards;
}

@keyframes slide-in {
	from {
		right: -666px;
	}
	to {
		right: 0;
	}
}

.modal {
	position: absolute;
	right: 0;
	background-color: $white;
	width: 660px;
	padding-inline: 33px;
	padding-block: 26px;
	min-height: 100%;
	animation: slide-in var(--animation-timeOut) ease-in-out forwards;

	.timeFLex {
		display: flex;
	}

	@media screen and (max-width: 800px) {
		margin: 0;
		width: 90%;
	}

	@media screen and (max-width: 600px) {
		width: 85%;
	}

	@media screen and (max-width: 500px) {
		height: calc(100% - 100px);
		overflow: auto;
	}

	.titleWrapper {
		padding-right: $paddingInlineInput;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		h3 {
			display: flex;
			gap: 1rem;
			width: 100%;
			justify-content: start;
			align-items: center;
			margin: 0;
			padding: 0;
			color: $black;
		}
	}
}

@keyframes slide-out {
	from {
		right: 0;
	}
	to {
		right: -666px;
	}
}

.modal.slideOut {
	animation: slide-out var(--animation-timeOut) ease-in-out forwards;
}

.closeButton {
	.icon {
		color: $black;
	}
}

form {
	color: $black;

	label {
		font-weight: 500;
	}
}

.cancel {
	background-color: $white;
	color: $black;
	font-weight: 600;
	border: 1px solid $black;
	margin-left: 16px;

	&:hover {
		background-color: #ebebeb;
	}
}

.transition {
	transition: 0.3s;
}

// .linkMeeting {
// 	border: 1px solid $lign;
// 	background-color: $white;
// 	border-radius: 6px;
// 	margin-top: $marginTopInput;
// 	padding-inline: $paddingInlineInput;
// 	padding-block: $paddingBlockInput;
// 	color: #707070;
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	cursor: pointer;
// 	font-style: italic;
// }

.dueDateAu {
	border: 1px solid $lign;
	margin-top: $marginTopInput;
	border-radius: 6px;
}

.btnContainer {
	gap: 1rem;
	margin-top: 16px;
	display: flex;
	justify-content: end;

	.addBtn {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
	}
}
