@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

.top{
    padding-top: 14px;
    padding-bottom: 24px;
    padding-left: 32px;
    padding-right: 48px;
    border-bottom: 1px solid $lign;
    height: 143.5px;
}

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap;

    .title{
        display: flex;
        align-items: center;
        gap: 5px;

        h1{
            margin: 0;
            padding: 0;
            font-size: 20px;
            font-weight: 600 !important;
        }

        span {
            font-size: 18px;
        }
    }

    .buttons{
        display: flex;
        align-items: center;
        gap: 10px;

        .icon {
            cursor: pointer;
            font-size: 16px;
            line-height: 16px;
            color: #231F20;
            border: 1.5px solid #231F20;
            border-radius: 3px;
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 6px;
            padding-bottom: 6px;
            border-radius: 3px;
        
            &:hover{
                background-color: #EBEBEB;
            }
        }
    }
}

.attendeeWrapper {
	z-index: 50;
	top: 0;
}