@import '../../../../../../styles/variables.scss';

.body {
	// height: calc(100vh - 280px);
	padding-top: 55px;
	padding-inline: 32px;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	.link {
		display: flex;
		align-items: center;
		gap: 15px;
		img {
			width: 20px;
		}
	}

	.row {
		display: flex;
		align-items: center;
		gap: 3rem;
		flex-flow: wrap;
		padding-bottom: 20px;

		.title {
			color: $textGrey;
			display: flex;
			align-items: center;
			gap: 15px;
			font-weight: 500;
			width: 100px;
		}
	}
}

.slot {
	cursor: pointer;
	color: $green;

	&:hover {
		color: $greenHover;
	}
}

.icon {
	font-size: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.des {
	font-weight: 500;
	p {
		margin: 0;
		padding: 0;
	}
}

.tags {
	display: flex;
	align-items: center;
	flex-flow: wrap;
	gap: 15px;
	position: relative;

	.tag {
		display: flex;
		align-items: center;
		gap: 10px;
		padding-block: 3px;
		padding-inline: 10px;
		font-size: 14px;
		border-radius: 87px;
	}
}
