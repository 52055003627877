@import '../../../../styles/variables';

.containerIllustration {
	width: 40%;
	margin: 0 auto;
	background-image: url('../../../../images/illustration/backgroundimglogin.webp');
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	overflow: hidden;

	color: $white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-top: 100px;
	padding-bottom: 100px;
	padding-left: 50px;
	padding-right: 50px;

	.topParagraph{
		font-size: 32px;
		font-weight: 400;
		text-align: center;
	}

	.bottom{
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 100px;
	}

	.policy{
		text-align: center;

		a{
			color: $white;
			text-decoration: underline !important;
		}
	}

	@media screen and (max-width: 820px) {
		min-height: 545px;
	}


	@media screen and (max-width: 820px) {
		width: 100%;
		display: block;
	}
}
