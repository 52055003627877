@import '../../../styles/variables.scss';

.main {
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	overflow: hidden;

	&::-webkit-scrollbar {
		display: none;
	}

	.sharedMeetingContainer {
		height: calc(100vh - 70px);
	}
}


.mainInvite{
	margin: 0;
	padding: 0;
	.header{
		margin: 0;
		padding: 0;
		background-color: $green;
		text-align: center;
		justify-content: center;
		padding-top: 80px;

		h1 {
			color: $white;
			font-weight: 800;
			font-size: 28px;
			margin-right: 20px;
		}

		p{
			color: $white;
			font-weight: 600;
			font-size: 20px
		}

		.buttons{
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 15px;
			padding-top: 35px;
			padding-bottom: 40px;

			button{

				border: 1.5px solid $white;
				padding-block: 8px;
				padding-inline: 16px;
				background-color: $white;
				font-weight: 600;
				color: #09AB57;

				&:hover{
				background-color: transparent;
				color: $white;
				border: 1.5px solid $white;
				}
			}
			.transparantButton {
				background-color: transparent;
				color: $white;
				font-weight: 600;
				border: 1.5px solid $white;
				padding-block: 8px;
				padding-inline: 16px;
				display: flex;
				align-items: center;
				gap: 5px;

				&:hover{
					border: 1.5px solid $white;
					background-color: $white;
					color: #09AB57;
				}
	
			
			}
		}
	}
}

.main{

	.next{
		text-align: center;
		color: #707070;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 14px;
		margin-top: 60px;
		margin-bottom: 20px;
	}

	.meetingWrapper {
		position: relative;
		max-width: 655.81px;
		margin: 0 auto;
	}

	.meetingDescription {
		box-shadow: 1px 1px 3px #00000030;
		padding-top: 50px;
		padding-bottom: 30px;
		padding-inline: 25px;
		margin-top: -20px;
		z-index: 2;
		width: 100%;
		background-color: white;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		margin-bottom: 80px;

		.fullDate {
			font-weight: 500;
			color: $textGrey;
			font-size: 13px;
		}
	}

	.meetingContainer {
		position: relative;
		box-shadow: 1px 1px 3px 0px #00000030;
		padding-left: 25px;
		padding-right: 70px;
		padding-top: 25px;
		padding-bottom: 70px;
		background-color: $white;
		display: flex;
		align-items: start;
		gap: 30px;
		border-radius: 15px;
		z-index: 10;

		.date {
			font-weight: 500;
			padding-block: 10px;
			padding-inline: 14px;
			border-radius: 60px;
			border: 1.5px solid $black;
			width: max-content;
		}
	}
	

	.flex{
		
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 20px;
		width: 100%;
		margin-top: 10px;

		.meetingSubject {
			font-weight: 600;
			font-size: 17px;
		}

		.avatar{
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			.tag{
				color: #2E4552;
				font-weight: 500;
				font-size: 14px;
				border: 1.5px solid #2E4552;
				border-radius: 87px;
				padding-block: 6px;
				padding-inline: 12px;

				p{
					margin: 0;
					padding: 0;
				}
			}
		}
		.location{
			color: $green;
			display: flex;
			align-items: center;
			gap: 10px;
			margin-top: 20px;
			font-weight: 500;

			.lo{
				text-decoration: underline;
			}
		}
	}
}

.footer{
	text-align: center;
	padding-bottom: 50px;

	.join{
		font-weight: 700;
		font-size: 24px;

		.freeDays {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: $black;
			}
		}
	}

	.info{
		font-weight: 500;
		font-size: 18px;
		margin-top: 15px;
		margin-bottom: 25px;
	}

	.buttons{
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 15px;

		.transparantButton{
			background-color: transparent;
			color: $black;
			border-color: $black;

			a{
				color: $black;
			}

			&:hover{
				background-color: #EBEBEB;
			}
		}
	}
}