@import '../../../../../styles/variables';

.container {
	background-color: #f7f7f7;
	border: 1px solid #d7d7d7;
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 550px;
	padding: 15px;

	margin-bottom: 30px;

	.left {
		width: 60%;
		border-right: 1px solid #d7d7d7;
		padding-right: 30px;

		p {
			margin: 5px;
			margin-bottom: 10px;
		}

		h2 {
			margin: 5px;
		}

		.buttonAdd {
			display: flex;
			align-items: center;
			gap: 5px;
		}

		.buttonCancel {
			background-color: $white;
			color: $black;
			font-weight: 600;
			border: 1px solid $black;
			padding: 5px;

			&:hover {
				background-color: #ebebeb;
			}
		}

		.flex {
			display: flex;
			align-items: center;
			flex-flow: wrap;
			gap: 15px;
		}
	}

	.right {
		width: 40%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.places {
			border: 2px solid $green;
			border-radius: 61px;
			color: $green;
			padding-left: 24px;
			padding-right: 24px;
			padding-top: 12px;
			padding-bottom: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
		}

		.seats {
			margin-top: 15px;
			font-weight: 500;
			font-size: 14px;
		}
	}
}
