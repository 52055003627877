@import './variables';
@import './mixins';

body {
	color: $black;
}

button {
	background-color: $button;
	color: $white;
	border: 1px solid $button;
	border-radius: 4px;
	// padding-inline: 0.6rem;
	// padding-block: .6rem;
	font-size: 16px;
	padding-inline: 24px;
	padding-block: 12px;
	cursor: pointer;
	transition: 100ms;

	&:hover {
		background-color: $greenHover;
		border-color: $greenHover;
	}

	&:disabled {
		background-color: $disa;
		border: 1px solid $disa;
		cursor: default;
	}
}

input {
	margin-top: $marginTopInput;
	border: 1px solid $lign;
	background-color: $white;
	border-radius: 6px;
	font-size: 14px;
	width: 100%;
	padding-inline: $paddingInlineInput;
	padding-block: $paddingBlockInput;

	&:focus {
		outline: none;
	}

	&::placeholder {
		font-style: italic;
	}
}

input[type=checkbox] {
	margin-top: 0 !important
}

select {
	width: 100%;

	border: none !important;
	padding-inline: $paddingInlineInput;
	padding-block: $paddingBlockInput;
	background-color: $inputBg;
	&:focus {
		outline: none;
	}
}

.success {
	color: $green;
}

.failed {
	color: $red;
}

input[type='checkbox'] {
	accent-color: green;
}

label {
	font-weight: 600 !important;
}

h1 {
	font-weight: 700 !important;
}

h2 {
	font-weight: 500 !important;
}

h3 {
	font-weight: 500 !important;
	font-size: 20px;
}

th {
	font-weight: 500 !important;
}

td {
	font-size: 14px;
}

.css-1oj0loj-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
	background-color: #f2fcf7 !important;
	color: $black !important;
}

.remirror-editor-wrapper {
	padding: 20px !important;
}

.remirror-editor {
	ol {
		margin-left: 25px;
	}

	ul {
		margin-left: 35px;
	}
}

.remirror-list-item-with-custom-mark {
	margin-right: 0;
	padding-right: 0;
}

a {
	cursor: pointer !important;
}

.remirror-theme .ProseMirror {
	box-shadow: none !important;
	overflow: hidden;
	padding-inline: 0 !important;
	padding-top: 8px;
}

.remirror-theme h2 {
	margin-inline: 18px !important;
}

.remirror-theme .ProseMirror-selectednode {
	outline: 2px solid $green;
}

.remirror-mention-atom {
	color: $green;
	background-color: transparent;
	font-size: 14px;
	font-weight: 400;
	margin: 0;
	padding: 0;
}

.custom-chat-button {
	display: none;
}
