@import '../../../../../../../styles/variables.scss';
@import '../../../../../../../styles/mixins.scss';


.container{
    position: relative;
    width: 250px;
    background-color: $white;
    padding: 15px;
    box-shadow: 0 0 6px 0px rgb(200 198 196);
    overflow: auto;
    border-radius: 4px;
    z-index: 99;
    @include fade-animation;

    h2{
        padding: 0;
        margin: 0;
        font-size: 18px;
        margin-bottom: 20px;
 
    }

    .list{
        .row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            font-weight: 500;
            color: #333;

            .infoCheckbox {
                width: 16px;
                height: 20px;
            }

            .avatar{
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }
}