@import '../../../../../styles/variables';

// @-webkit-keyframes slide-top {
// 	0% {
// 		-webkit-transform: translateY(10px);
// 		transform: translateX(10px);
// 	}
// 	100% {
// 		-webkit-transform: translateX(-50px);
// 		transform: translateX(-50px);
// 	}
// }
@keyframes slide-top {
	from {
		left: -320px;
	}
	to {
		left: 10px;
	}
}

.toastContainer {
	position: fixed;
	bottom: 10px;
	left: -320px;
	border-radius: 4px;
	width: 300px;
	font-weight: 500;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
	z-index: 9;
	background-color: $white;
	padding: 20px;

	// -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both forwards;
	animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

	.toastHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.icon {
		color: $black;
		cursor: pointer;
		display: flex;
		align-items: center;
		font-size: 14px;
	}

	.content {
		display: flex;
		align-items: center;
		gap: 5px;

		.icon {
			border: 1px solid #00ad56;
			border-radius: 100%;
			font-size: 14px;
			color: #00ad56;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
