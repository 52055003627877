.tooltip {
    position: relative;
    z-index: 1999;
	padding-block: 10px;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;

	.tooltipAvatarContainer {
		position: relative;
		display: flex;
		align-items: center;
	}

	.domainTooltip {
		background-color: white;
		z-index: 20;
		width: 32px;
		height: 30px;
		overflow: hidden;
		border-radius: 100%;
		border: 1px solid #D7D7D7;
	}

	.avatarTolltip {
		position: absolute;
		left: 18px;
	}

	.tooltipContent {
		font-size: 16px;
		font-weight: 500;
		color: #231F20;
	}

	.jobTitleTooltip {
		color: #707070;
		font-weight: 500;
		font-size: 14px;
	}
}