@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.mainContainerOv {
	width: 100%;
	display: flex;

	@media screen and (max-width: 800px) {
		display: block;
		overflow-y: auto;
	}
}

.filterContainer {
	min-width: 360px;
	max-width: 360px;
	border-right: 1px solid $lign;
	background-color: $lightGrey;

	@media screen and (max-width: 800px) {
		border: none;
		min-width: 100%;
		max-width: 100%;
		padding: 10px;
	}

	@media screen and (max-width: 500px) {
		display: none;
	}

	.filter {
		.title {
			font-weight: 500;
		}

		p {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.active {
			font-weight: 700;
			color: $green;
		}
	}
}

.overviewList {
	width: 100%;
	@include fade-animation-page;

	@media screen and (max-width: 500px) {
		display: none;
		padding: 10px;
	}
}

.optionsTable {
	position: absolute;
	z-index: 9;
	background-color: white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	top: 0;

	.remove {
		color: red;
		margin: 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.confirmBox {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	div {
		margin: auto;
		margin-top: 300px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;

		button {
			margin: 10px;
		}
	}
}

.noteTitle {
	width: 200px !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	font-weight: 600;
}

.form {
	margin-top: 20px;
	margin-left: 20px;
	display: flex;
	align-items: center;
	flex-flow: wrap;
	color: $green;

	button {
		width: 24px;
		border: none;
		background-color: transparent;
		color: $green;
		cursor: pointer;
		margin-right: 15px;
		margin-top: 5px;
		font-size: 20px;
	}

	p {
		cursor: pointer;
	}
}

.text {
	max-width: 250px;
	width: 300px !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.modalAttendees {
	position: absolute;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: max-content;
	padding: 15px;
	top: 50px;
	z-index: 9;
	max-height: 500px;
	overflow-y: auto;

	.attendee {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
	}
}

.noMeetings {
	margin: auto;
	margin-top: 200px;
	text-align: center;
}

.filterContainer {
	padding-bottom: 20px;

	@media screen and (max-width: 800px) {
		max-height: 300px;
	}
}

.note {
	border-top: 1px solid $lign;
	border-left: 0px solid $green;
	border-right: none;
	font-size: 16px;
	padding-left: 30px;
	padding-block: 24px;
	cursor: pointer;
	opacity: 0.4 !important;
	transition: 0.1s ease-in-out;

	&:hover {
		opacity: 0.7 !important;
	}

	&:last-child {
		border-bottom: 1px solid $lign;
	}

	p {
		margin: 0 auto;
		padding: 0;
	}
}

.selectNote {
	border-top: 1px solid $lign;
	border-right: none;
	padding-left: 30px;
	font-size: 16px;
	padding-left: 30px;
	padding-block: 24px;
	cursor: pointer;
	border-left: 4px solid $green;
	font-weight: 500;
	transition: 0.1s;

	&:last-child {
		border-bottom: 1px solid $lign;
	}

	p {
		margin: 0 auto;
		padding: 0;
	}
}

.filters {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	position: relative;
	margin-top: 43.5px;

	i {
		font-size: 12px;
	}

	.modalFilterWrapper {
		padding-top: 5px;
		width: 95%;
		margin: auto;
		margin-bottom: 5px;
		overflow: hidden;
		border-radius: 4px;
		transition: 0.2s ease-in-out;
	}

	.buttonFilter {
		transform: translateX(0);
		background-color: #f7f7f7;
		background-color: $grey;
		border-radius: 4px;
		margin-left: 14px;
		padding-left: 14px;
		margin-right: 10px;
		padding-right: 14px;
		padding-top: 7.5px;
		padding-bottom: 8px;
		display: flex;
		align-items: center;
		gap: 10px;
		cursor: pointer;
		transition: transform 0.2s ease-in-out, color 50ms ease-in-out, background-color 50ms ease-in-out;

		&:hover {
			background-color: $green;
			color: white;
		}
	}

	.activeButton {
		transform: translateX(10px);
		background-color: $green;
		color: white;
		border-radius: 4px;
		margin-left: 14px;
		padding-left: 14px;
		margin-right: 10px;
		padding-right: 14px;
		padding-top: 7.5px;
		padding-bottom: 8px;
		display: flex;
		align-items: center;
		gap: 10px;
		cursor: pointer;
		transition: 0.2s;
	}
}

.filterContainerMobile {
	display: none;
	width: 100%;
	background-color: $lightGrey;
	padding-bottom: 10px;

	height: calc(100vh - 120px);
	overflow: auto;

	.filter {
		padding: 10px;

		.title {
			font-weight: 500;
		}

		p {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.active {
			font-weight: 700;
			color: $green;
		}
	}

	@media screen and (max-width: 500px) {
		display: block;
	}
}

.overviewListMobile {
	width: 100%;
	overflow: auto;
	display: none;

	@media screen and (max-width: 500px) {
		display: block;
	}
}

.flexTitle {
	display: flex;
	align-items: center;
	gap: 15px;

	p {
		margin: 0;
	}
}

.button {
	cursor: pointer;
	background-color: $green;
	border-radius: 100%;
	width: 27px;
	height: 27px;
	font-size: 11px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
}

.titlePlace {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-top: 28px;

	.title {
		font-size: 24px;
		font-weight: 800 !important;
	}
}

.list {
	max-height: calc(100vh - $header-height - 150px);
	overflow: auto;

	@media screen and (max-width: 800px) {
		min-width: 100%;
		max-height: 100px;
	}

	@media screen and (max-width: 500px) {
		max-height: calc(100vh - $header-height - 200px);
	}
}
