@import '../../../../styles/variables.scss';

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.modalContainer {
    width: 100%;
    position: absolute;
    background-color: white;
    top: 110%;
    box-shadow: $heavy-shadow;
    border-radius: 6px;
    border: 1px solid $lign;
    opacity: 0;
    animation: fade-in .1s forwards;
    z-index: 999;
}