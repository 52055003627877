@import '../../../styles/variables';

.container {
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	height: 100vh;

	@media screen and (max-width: 820px) {
		display: block;
		overflow: visible;
		margin-top: 50px;
	}

	h1 {
		margin-bottom: 15px;
	}
}

.containerLoader {
	width: 60%;
	text-align: left;
	margin: 0 auto;
	height: 100%;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: hidden;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: 820px) {
		width: 100%;
	}
}

.loader {
	margin-top: 200px;
}
