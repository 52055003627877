@import '../../../styles/variables';

.container {
	width: inherit;
	padding-top: 5px;
	padding-inline: 32px;

	h1 {
		margin: 0;
		padding: 0;
		margin-bottom: 28px;
		margin-top: 32px;
	}
}

.tabs {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	margin-bottom: 20px;
	color: #8a8886;

	a {
		cursor: pointer;

		&:hover {
			font-weight: 500;
			border-bottom: 2px solid $green;
			color: $black;
		}
	}

	.activeTab {
		font-weight: 500;
		padding-bottom: 10px;
		border-bottom: 2px solid $green;
		color: $black;
	}
}

.tabContainer {
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	padding-bottom: 20px;
	height: 75vh;
}
