@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

@keyframes fade-in {
	from {
		background-color: rgba(0, 0, 0, 0);
	}
	to {
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.container {
	--animation-timeOut: 0.3s;

	position: fixed;
	z-index: 99;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	animation: fade-in var(--animation-timeOut) ease-in-out forwards;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		display: none;
	}
}

@keyframes fade-out {
	from {
		background-color: rgba(0, 0, 0, 0.4);
	}
	to {
		background-color: rgba(0, 0, 0, 0);
	}
}

.container.fadeOut {
	animation: fade-out var(--animation-timeOut) ease-in-out forwards;
}

@keyframes slide-in {
	from {
		right: -666px;
	}
	to {
		right: 0;
	}
}

.modal {
	position: absolute;
	right: 0;
	background-color: $white;
	width: 80%;
	padding-inline: 33px;
	padding-block: 26px;
	min-height: 100%;
	animation: slide-in var(--animation-timeOut) ease-in-out forwards;

	.timeFLex {
		display: flex;
	}

	@media screen and (max-width: 800px) {
		margin: 0;
		width: 90%;
	}

	@media screen and (max-width: 600px) {
		width: 85%;
	}

	@media screen and (max-width: 500px) {
		height: calc(100% - 100px);
		overflow: auto;
	}

	.titleWrapper {
		padding-right: $paddingInlineInput;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		h3 {
			display: flex;
			gap: 1rem;
			width: 100%;
			justify-content: start;
			align-items: center;
			margin: 0;
			padding: 0;
			color: $black;
			text-transform: capitalize;
		}
	}
}

@keyframes slide-out {
	from {
		right: 0;
	}
	to {
		right: -666px;
	}
}

.modal.slideOut {
	animation: slide-out var(--animation-timeOut) ease-in-out forwards;
}

.closeButton {
	@media screen and (max-width: 564px) {
		margin-bottom: 60px;
	}
	.icon {
		color: $black;
	}
}

table {
	text-align: left;
	padding-left: 0;
	margin: 0;
	th,
	td {
		padding-right: 10px;
	}

	tr,
	th {
		min-height: 47px;
	}

	td,
	th {
		border-bottom: 1px solid $lign;
		padding: 20px;

		.more {
			cursor: pointer;
			float: right;
		}
	}

	.displayName {
		text-transform: capitalize;
	}

	.statut {
		&.active {
			color: $green;
		}

		&.pending {
			color: $red;
		}
	}
}

.options {
	position: absolute;
	z-index: 9;
	background-color: white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: 200px;
	right: 20px;

	.edit {
		color: $green;
		margin: 10px;
		display: flex;
		align-items: center;
		gap: 10px;
		cursor: pointer;
		span {
			color: $black;
		}
	}

	.remove {
		color: red;
		margin: 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.confirmBox {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	div {
		margin: auto;
		margin-top: 300px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;

		button {
			margin: 10px;
		}
	}
}

.cat {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	width: max-content;
	color: var(--grey-palette-grey-190, #201f1e);
	font-family: Segoe UI;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px;
	padding-bottom: 20px;
	padding-top: 10px;
}

.titleCat {
	display: flex;
	align-items: center;
	gap: 15px;

	.number {
		padding-block: 2px;
		padding-inline: 6px;
		border-radius: 4px;
		font-size: 13px;
		color: grey;
		background-color: $lightGrey;
	}
}

.overview {
	margin-bottom: 50px;
}

.flex {
	display: flex;
	align-items: center;

	@media screen and (max-width: 564px) {
		display: flex;
		flex-direction: column;
	}

	.wrapperContainer {
		display: flex;
		align-items: center;
		gap: 15px;

		.invite {
			background-color: transparent;
			color: $black;
			font-weight: 600;
			border: 1px solid #8a8886;
			border-radius: 2px;
			display: flex;
			align-items: center;
			gap: 10px;
			height: 44px;
			width: 100%;

			&:hover {
				background-color: #ebebeb;
			}

			margin-bottom: 20px;

			.icon {
				font-size: 14px;
			}
		}
	}
}

.domain {
	border: 1px solid #8a8886;
	padding: 5px;
	margin: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	border: 2px solid transparent;
	transition: 0.2s border;

	transition: 0.2s;

	&:hover {
		background-color: #ebebeb;
	}

	.close {
		cursor: pointer;
	}
}

.form {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 50px;

	input {
		margin: 0;
	}

	.icon {
		cursor: pointer;
		padding: 5px;
		margin: 5px;
	}
}
