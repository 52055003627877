@import '../../../../../styles/variables';

.main {
	width: 100%;
	-ms-overflow-style: none;
	/* for Internet Explorer, Edge */
	scrollbar-width: none;
	/* for Firefox */
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
		/* for Chrome, Safari, and Opera */
	}

	max-height: calc(100vh - 280px);
	margin-top: 20px;

	@media screen and (max-width: 800px) {
		max-height: 200px;
	}
}

.meeting {
	border: 1px solid $lign;
	border-right: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 32px;
	padding-block: 16px;
	font-weight: 500;
	filter: grayscale(100%);
	opacity: 0.4;
	border-left: 0px solid $green;
	cursor: pointer;
	transition: .1s ease-in-out;

	&:hover {
		opacity: .7;
	}

	span {
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 20px;
	}
}

.meetingHour {
	margin-bottom: 18px;
}

.selectMeeting {
	border: 1px solid $lign;
	border-right: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 32px;
	padding-block: 16px;
	cursor: pointer;
	border-left: 4px solid $green;
	font-weight: 500;
	transition: .1s;

	span {
		font-size: 14px;
		font-weight: 700;
	}
}


.task:has(i),
.task:has(svg) {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 10px;

	i {
		padding: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.todoIcon {
		border: 1px solid rgba(0, 0, 0, 0.4);
		border-radius: 100%;
	}

	.micIcon {
		background-color: $green;
		color: $white;
		border-radius: 100%;
	}

	.noteIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid rgba(0, 0, 0, 0.4);
		border-radius: 100%;
		padding: 4px;
	}
}

.title {
	width: 300px !important;
	font-size: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}