@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/mixins.scss';

.over {
	overflow-x: auto;
	position: relative;
	overflow-y: hidden;
	height: calc(100vh - 260px);
	// padding: 25px;
}

.projectRowList {
	margin-top: 10px;
	overflow: auto;
}

.overviewList {
	height: calc(100vh - 300px) !important;
	width: 100%;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	overflow-x: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	overflow-x: auto;
	white-space: nowrap;

	.form {
		margin-top: 20px;
		margin-left: 20px;
		display: flex;
		align-items: center;

		.submit {
			margin-top: 5px;
			width: 24px;
			cursor: pointer;
			color: $green;
			font-size: 20px;
		}

		.add {
			color: $green;
			border: none;
			margin-left: 5px;
			cursor: pointer;
		}
	}
}

.options {
	position: absolute;
	z-index: 9;
	background-color: white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding-right: 10px;
	top: 40px;
	right: 0;

	.remove {
		color: red;
		margin: 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.confirmBox {
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	div {
		margin: auto;
		margin-top: 300px;
		background-color: $white;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;

		button {
			margin: 10px;
		}
	}
}

.modalAttendees {
	position: absolute;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: max-content;
	padding: 15px;
	top: 50px;
	z-index: 9;
	max-height: 500px;
	overflow-y: auto;

	.attendee {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
	}
}

.form {
	background-color: $white;
	padding-inline: 22px;
	padding-block: 22px;
	border: 1px solid rgba(128, 128, 128, 0.1);
	min-width: 400px;
	position: absolute;
	z-index: 80;
	top: 75px;
	left: 32px;
	border-radius: 4px;
	box-shadow: $heavy-shadow;
	@include fade-animation;

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media screen and (max-width: 800px) {
		margin: 0;
		width: 90%;
	}

	p {
		font-weight: 500;
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 10px;
	}

	form {
		margin-top: 16px;

		input {
			border: 1px solid $lign;
			padding-block: $paddingBlockInput;
			padding-inline: $paddingInlineInput;
		}

		button {
			display: flex;
			align-items: center;
			gap: 10px;
			width: 100%;
			margin-top: $marginTopInput;
		}

		.formButton {
			float: right;
			width: max-content;
			margin-top: 10px;
			padding-inline: 24px;
			padding-block: 12px;
		}
	}
}

.selectCustom {
	position: relative;

	button {
		background-color: $inputBg;
		border: 1px solid $lign;
		color: $black;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
		padding-block: $paddingBlockInput;
		padding-inline: $paddingInlineInput;

		p {
			white-space: wrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: 500;
			margin: 0;
			padding: 0;
		}

		&:hover {
			background-color: #ebebeb;
		}
	}

	.dropdown {
		background-color: white;
		width: 100%;
		box-shadow: 0 0 6px 0px rgb(200 198 196);
		position: absolute;
		max-height: 120px;
		overflow: auto;
		top: 100%;

		p {
			padding: 0;
			margin: 0;
			padding: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 14px;

			&:hover {
				background-color: $lightGreen;
				color: $green;
			}
		}
	}
}

.flex {
	display: flex;
	align-items: center;
	gap: 15px;
}

.cancel {
	background-color: transparent;
	color: $black;
	font-weight: 600;
	border: 1px solid #8a8886;
	display: flex;
	align-items: center;
	gap: 10px;

	&:hover {
		background-color: #ebebeb;
	}
}

.error {
	color: $red;
}

.flex {
	display: flex;
	align-items: center;
	gap: 15px;
	padding: 0;
	margin: 0;
}

.top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-flow: wrap;
	padding-top: 24px;
	margin-bottom: 32px;
	padding-inline: 32px;
	padding-bottom: 0;

	.icons {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $lign;
		border-radius: 4px;

		.iconSelect {
			background-color: #2e4552;
			color: #dcdfe3;
			cursor: pointer;
			height: 43.5px;
			width: 43.5px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:nth-child(1) {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}

			&:nth-child(4) {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}

		.icon {
			background-color: transparent;
			color: #2e4552;
			cursor: pointer;
			height: 43.5px;
			width: 43.5px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.overview {
	margin-top: 10px;

	.mainTodoPage {
		height: 100%;
		// height: calc(100vh - 200px);
	}

	table {
		width: 100%;
	}
}

// .mainTodoBoardPage {
// 	background-color: #f7f7f7;
// 	margin-top: 10px;
// 	display: flex;
// 	gap: 30px;
// 	padding: 15px;
// 	padding-inline: 32px;
// }

.filters {
	display: flex;
	align-items: center;
	gap: 15px;
	position: relative;

	.place {
		position: relative;
	}

	.filterModalWrapper {
		position: absolute;
		top: 110%;
		left: 0;
	}

	.buttonFilter {
		position: relative;
		background-color: #f7f7f7;
		border-radius: 4px;
		padding-inline: 24px;
		padding-block: 12px;
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 8px;
		font-weight: 500;
		cursor: pointer;
		border: 1px solid #f7f7f7;
		transition: 100ms;

		&:hover {
			background-color: #ebebeb;
			border: 1px solid $black;
		}
	}

	.activeButton {
		position: relative;
		border-radius: 4px;
		padding-inline: 24px;
		padding-block: 12px;
		font-weight: 500;
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 8px;
		cursor: pointer;
		border: 1px solid $black;
		background-color: #ebebeb;
	}
}

.flexButton {
	display: flex;
	align-items: center;
	gap: 15px;
}