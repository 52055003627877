@import '../../../../styles/variables';

.container {
	width: 60%;
	text-align: left;
	background-color: white;
	height: 100vh;
	overflow: hidden;

	@keyframes fade-bottom_right {
		0% {
			max-height: 0px;
		}
		50% {
			transform: translateX(30px);
			max-height: 130px;
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
			max-height: 130px;
		}
	}

	@keyframes fade-bottom_login {
		0% {
			max-height: 0px;
		}
		50% {
			transform: translateX(30px);
			max-height: 80px;
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
			max-height: 80px;
		}
	}

	@keyframes fade-bottom_access {
		0% {
			max-height: 0px;
		}
		50% {
			transform: translateX(30px);
			max-height: 160px;
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
			max-height: 160px;
		}
	}

	@keyframes fade-bottom_register {
		0% {
			max-height: 0px;
			margin-top: -50px;
		}
		50% {
			transform: translateX(30px);
			max-height: 160px;
			opacity: 0;
			margin-top: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
			max-height: 160px;
			margin-top: 0;
		}
	}

	@keyframes fade-right {
		0% {
			transform: translateX(30px);
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
		}
	}

	.fadeRight {
		transform: translateX(30px);
		opacity: 0;
		animation: fade-right 0.4s forwards ease;
	}

	.fadeRightBottom {
		max-height: 0;
		transform: translateX(30px);
		opacity: 0;
		animation: fade-bottom_right 0.8s forwards ease;
	}

	.fadeRightLogin {
		max-height: 0;
		transform: translateX(30px);
		opacity: 0;
		animation: fade-bottom_login 0.8s forwards ease;
	}

	.fadeRightAccess {
		max-height: 0;
		transform: translateX(30px);
		opacity: 0;
		animation: fade-bottom_access 0.8s forwards ease;
	}

	.fadeRightBottomRegister {
		margin-top: -50px;
		max-height: 0;
		transform: translateX(30px);
		opacity: 0;
		animation: fade-bottom_register 0.8s forwards ease;
	}

	.spinner {
	}

	h1 {
		font-size: 28px;
		font-weight: 700;
		margin: 0;
	}

	h2 {
		font-weight: 500;
		margin-top: 5px;
		font-size: 28px;
	}

	.form {
		max-width: 500px;
		margin: 0 auto;
		padding-top: 150px;
		margin-bottom: 50px;
		margin-top: 50px;

		form {
			position: relative;

			input {
				padding: 14px;
			}

			.checkIcon {
				position: absolute;
				right: 15px;
				top: 45%;
				font-size: 16px;
				color: $green;
			}
		}

		.error {
			position: absolute;
			font-size: 14px;
			margin-top: 10px;
			color: red;
		}
	}

	.button {
		margin-top: 30px;
		width: 100%;
		height: 50px;
		background-color: $buttonLight;
		border: none;
		border-radius: 8px;
	}

	.cancel {
		background-color: transparent;
		color: $black;
		border-color: $black;
		width: 100%;
		height: 45px;
		font-weight: 600;
		margin-top: 16px;
		border-radius: 8px;
	}

	.existingCompany {
		font-size: 13px;
		font-weight: 500;
		margin-top: 10px;
	}

	.connectWithWindows {
		font-size: 13px;
		font-weight: 500;
		margin-top: 10px;
	}

	.loginButton {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
	}

	.signUp {
		text-align: center;
		margin-top: 200px;
		transition: 0.2s;

		span {
			color: $green;
			font-weight: 600;
			text-decoration: underline;
		}
	}

	.notifier {
		margin-top: 25px;
		font-size: 13px;
		font-weight: 500;
	}

	@media screen and (max-width: 820px) {
		width: 100%;
		margin-bottom: 200px;
	}
}

.companyName {
	text-transform: uppercase;
	font-weight: 500;
}

.spinner {
	position: absolute;
	right: 15px;
	top: 45%;
	font-size: 10px;
}
