@import './../../../../../styles/variables.scss';

@keyframes fade-in {
	from {
		backdrop-filter: blur(0);
		// background-color: rgba(0, 0, 0, 0);
	}

	to {
		// background-color: rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(0);
	}
}

@keyframes fade-out {
	from {
		// background-color: rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(0);
	}

	to {
		// background-color: rgba(0, 0, 0, 0);
		backdrop-filter: blur(0);
	}
}

@keyframes slide-in {
	from {
		right: -666px;
	}

	to {
		right: 0;
	}
}

@keyframes slide-out {
	from {
		right: 0;
	}

	to {
		right: -666px;
	}
}

.formContainer {
	--animation-timeOut: 0.3s;

	position: fixed;
	z-index: 9999;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	animation: fade-in var(--animation-timeOut) ease-in-out forwards;

	overflow-y: scroll;
	overflow-x: hidden;

}

.formContainer.fadeOut {
	animation: fade-out var(--animation-timeOut) ease-in-out forwards;
}

.formModal {
	position: absolute;
	right: 0;
	border-left: 1px solid rgba(128, 128, 128, 0.15);
	background-color: $white;
	width: 660px;
	box-shadow: 1px -1px 20px rgba(0, 0, 0, 0.07);
	padding-inline: 33px;
	padding-block: 26px;
	min-height: 100%;
	animation: slide-in var(--animation-timeOut) ease-in-out forwards;

	@media screen and (max-width: 800px) {
		margin: 0;
		width: 90%;
	}

	@media screen and (max-width: 600px) {
		width: 85%;
	}

	@media screen and (max-width: 500px) {
		height: calc(100% - 100px);
		overflow: auto;
	}
}

.formModal.slideOut {
	animation: slide-out var(--animation-timeOut) ease-in-out forwards;
}

.formIcon {
	background-color: $green;
	transform: scale(.9);
	color: $white;
	height: 45px;
	width: 45px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.closeButton {
	.icon {
		font-size: 12px;
		color: $black;
		background-color: rgba(128, 128, 128, 0.1);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 7px;
		border-radius: 100%;
	}
}

.titleWrapper {
	padding-right: $paddingInlineInput;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	h3 {
		display: flex;
		gap: .9rem;
		width: 100%;
		justify-content: start;
		align-items: center;
		margin: 0;
		padding: 0;
		color: $black;
	}
}

.btnContainer {
	gap: 1rem;
	margin-top: 16px;
	display: flex;
	justify-content: end;

	.addBtn {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
		transition: .3s;
		border: 1px solid $buttonBorder;
		outline: 1px solid $buttonOutline;
	}
}

.cancel {
	background-color: $white;
	color: $black;
	font-weight: 600;
	border: 1px solid $black;
	margin-left: 16px;

	&:hover {
		background-color: #ebebeb;
	}
}