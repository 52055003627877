@import '../../../../styles/variables.scss';

.editor {
	position: relative;
}

.overflowMenu {
	display: flex;
	align-items: center;
	flex-flow: wrap;
	border: 1px solid $lign;
	width: fit-content;
	padding: 5px;
	border-radius: 3px;
	position: fixed;
	background-color: $white;
	z-index: 99;

	.group {
		display: flex;
		align-items: center;
		position: relative;
		padding-right: 15px;
	}

	button {
		background-color: transparent;
		padding: 0;
		padding: 6px 6px;
		border: 1px solid rgba(0, 0, 0, 0.12);
		color: rgba(0, 0, 0, 0.54);
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 35px;
		border: none;

		&:hover {
			background-color: $lightGreen !important;
		}
	}

	.button-active {
		background-color: $lightGreen;
	}
}

.menu {
	display: flex;
	align-items: center;
	flex-flow: wrap;
	align-items: flex-start;
	border: 1px solid $lign;
	width: fit-content;
	padding: 5px;
	border-radius: 3px;
	min-height: 52px;

	.group {
		display: flex;
		align-items: center;
		position: relative;
		padding-right: 15px;
	}

	button {
		background-color: transparent;
		padding: 0;
		padding: 6px 6px;
		border: 1px solid rgba(0, 0, 0, 0.12);
		color: rgba(0, 0, 0, 0.54);
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 35px;
		width: 35px;
		border: none;

		&:hover {
			background-color: $lightGreen !important;
		}
	}

	.button-active {
		background-color: $lightGreen;
	}
}

.pickColor {
	background-color: $inputBg;
	margin-top: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	color: #8a8886;
}

.color {
	display: flex;
	flex-direction: column;

	.col {
		width: 15px;
		height: 1px;
	}

	.cols {
		width: 20px;
		height: 20px;
	}
}

.colors {
	position: absolute;
	background-color: white;
	z-index: 9;
	margin-top: 130px;
	padding: 5px;
	width: 150px;
	box-shadow: 0 0 6px 0px rgb(200 198 196);

	display: flex;
	flex-flow: wrap;
	align-items: center;
	justify-content: center;
	gap: 5px;
}

.tables {
	position: absolute;
	background-color: white;
	z-index: 1;
	margin-top: 10px;
	padding: 5px;
	width: 150px;
	box-shadow: 0 0 6px 0px rgb(200 198 196);

	.row {
		display: flex;
		flex-flow: wrap;
		align-items: center;
		justify-content: center;
		gap: 1px;
		padding: 20px;
	}

	.cell {
		border: 1px solid black;
		width: 15px;
		height: 15px;
	}
}

a[data-link-auto] {
	cursor: pointer;

	&:hover {
		text-decoration: underline !important;
	}
}

.meetingTitleInput {
	font-size: 20px;
	font-weight: 700;
	padding: 0;
	margin: 0;
	margin-inline: 18px !important;
	border: none;
	height: 25px;
	margin-top: 50px;
}

.suggestor {
	border-radius: 6px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
	border: 1px solid $grey;

	.mentionsSuggestor {
		padding-inline: 16px;
		padding-block: 8px;
		display: flex;
		align-items: center;
		gap: 10px;
		background-color: $white;

		&:hover {
			background-color: $lightGreen;
			cursor: pointer;
		}
	}
}

.toolbarLink {
	display: flex;
	align-items: center;
	gap: 5px;
}