@import '../../../styles/variables';

.main {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	// overflow: hidden;

	@media screen and (max-width: 800px) {
		display: block;
		overflow-y: auto;
	}

	.calendar {
		max-width: 360px;
		min-width: 360px;
		display: flex;
		flex-direction: column;
		border-right: 1px solid $lign;
		-ms-overflow-style: none;
		scrollbar-width: none;
		overflow-y: scroll;
		background-color: $greyBackground;

		h1 {
			margin-top: 24.5px;
			margin-bottom: 16px;
			font-size: 24px;
			font-weight: 800 !important;
		}

		&::-webkit-scrollbar {
			display: none;
		}

		@media screen and (max-width: 800px) {
			max-width: 100%;
			min-width: 100%;
			padding-bottom: 15px;
		}

		@media screen and (max-width: 500px) {
			display: none;
		}
	}
}

.modalCalendar {
	background-color: $greyBackground;
	width: 96%;
	height: calc(100vh - 100px);
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	padding: 15px;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	.icon {
		float: right;
		cursor: pointer;
		margin: 10px;
	}
}

.date {
	border: 1px solid $black;
	padding: 15px 16px;
	border-radius: 4px;
	display: flex;
	font-weight: 600;
	font-size: 18px;
	align-items: center;
	justify-content: center;
	gap: 10px;
	width: 178px;
	cursor: pointer;
	transition: .1s;

	i {
		font-size: 15px;
	}

	&:hover {
		background-color: #ebebeb;
	}
}

.activeDate {
	border: 1px solid $black;
	padding: 15px 16px;
	border-radius: 4px;
	font-weight: 600;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	width: 178px;
	cursor: pointer;
	background-color: #ebebeb;

	i {
		font-size: 15px;
	}
}

.dateSelect {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	margin-inline: 30px;
}

.arrows {
	display: flex;
	align-items: center;
	gap: 20px;

	.chevArrow {
		cursor: pointer;
	}

	i {
		font-size: 18px;
	}
}

.pickerDate {
	position: relative;
}

.buttonCreate {
	margin-top: 31px;
	margin-left: 33px;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	border-radius: 4px;

	i {
		display: flex;
		align-items: center;
	}
}

.titlePlace {
	display: flex;
	align-items: center;
	gap: 20px;

	.title {
		font-size: 24px;
		font-weight: 800 !important;
	}

	.button {
		cursor: pointer;
		background-color: $green;
		border-radius: 100%;
		width: 27px;
		height: 27px;
		font-size: 11px;
		margin-top: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $white;
	}
}