@import '../../../../styles/variables';

.checkboxFlex {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 50px;

	input {
		width: 15px;
		height: 15px;
		margin: 0;
	}

	p {
		padding: 0;
		margin: 0;
	}

	a {
		padding: 0;
		margin: 0;
		color: $black;
		font-weight: 600;
		text-decoration: underline !important;
	}
}
