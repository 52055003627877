@import '../../../styles/variables.scss';

.main {
	width: 100%;
	background-color: white;
	overflow-y: hidden;
}

.timeInfo {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 50px;
	margin-right: 30px;

	h2 {
		font-size: 14px;
	}

	.monthAndWeek {
		display: flex;
		gap: 50px;
	}

	.iconClose {
		cursor: pointer;
	}

	.icon {
		display: flex;
		align-items: center;
		gap: 20px;

		div {
			cursor: pointer;
		}
	}
}

.headers {
	display: flex;
	border-top: 1px solid $lign;
	border-bottom: 1px solid $lign;
	background-color: #faf9f8;

	margin-top: 15px;
	min-width: 800px;
	overflow-x: auto;

	h2 {
		font-weight: 600;
	}

	.timeHeader {
		width: 55px;
		height: 50px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-weight: 600;
		font-size: 14px;
	}

	.dayHeaders {
		display: flex;
		width: 100%;

		.dayHeader {
			width: 20%;
			border-left: 1px solid $lign;
			position: relative;

			h2 {
				font-size: 14px;
				margin: 0;
				padding: 0;
				margin-top: 15px;
				padding-left: 10px;
				font-weight: 500 !important;
			}

			.timeSlot {
				width: 100px;

				.timeZone {
					height: 50px;
					border-top: 1px solid $lign;
					border-bottom: 1px solid $lign;
				}
			}
		}
	}
}

.agenda {
	h2 {
		font-weight: 600;
	}

	min-width: 800px;
	overflow-x: auto;

	height: calc(100vh - 220px);

	@media screen and (max-width: 500px) {
		height: calc(100vh - 350px);
	}

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	.week {
		display: flex;
		width: 100%;

		.day {
			border-right: 1px solid $lign;
			position: relative;

			.meeting {
				height: 59px;
				width: 100%;
				border-bottom: 1px solid $lign;
			}
		}
	}
}

.now {
	position: absolute;
	width: 100%;
	border-bottom: 1px dashed $green;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-flow: wrap;
	margin-top: 15px;
}

.today {
	background-color: transparent;
	color: $black;
	margin-right: 33px;
	font-weight: 600;
	padding-left: 20px !important;
	padding-right: 20px !important;
	padding-top: 4px !important;
	padding-bottom: 4px !important;
	border: 1px solid #8a8886;
	border-radius: 2px;

	&:hover {
		background-color: #ebebeb;
	}
}

.allDayPart {
	min-height: 50px !important;
	max-height: 50px !important;
	margin-top: 5px;
}

.allDay {
	background-color: $green;
	color: $white;
	cursor: pointer;
	margin-bottom: 5px;
	margin-left: 4px;
	margin-right: 4px;
	padding-left: 20px !important;
	padding-top: 4px !important;
	padding-bottom: 4px !important;
}
