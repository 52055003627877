@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.container {
	display: flex;
	align-items: center;
	flex-flow: wrap;
	gap: 15px;
}

.card {
	border: 1px solid $lign;
	margin: 20px;
	padding: 15px;
	cursor: pointer;
}

.name {
	// font-weight: 700;
	font-size: 15px;
	padding: 3px;
}

.listViewContainer {
	position: relative;
	padding-inline: 24px;
	height: 100%;
	overflow-y: auto;
}

.headTitle {
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 30;
	display: grid;
	padding-inline: 20px;
	height: 45px;
	align-items: stretch;
	flex-shrink: 1;
	border-top: 1px solid $lign;
	border-bottom: 1px solid $lign;
	font-weight: 500;

	.title {
		display: flex;
		align-items: center;
		font-weight: 400;
		gap: 10px;
		width: 100%;
		font-size: 14px;
		color: $textGrey;
		padding-left: 10px;
	}
}

.flexHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-basis: 450px;
	flex-shrink: 0;
	padding-right: 10px;
	border-right: 1px solid $lign;
	gap: 15px;

	i {
		padding-right: 40px;
		font-size: 14px;
		display: flex;
		align-items: center;
		color: #2e4552;
	}
}

.titleTodo {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 10px;
	border-right: 1px solid $lign;
	flex-shrink: 0;
	flex-basis: 450px;
	transition: 0.2s;
	cursor: text;

	.inputTitleModalContainer {
		.modalTitleInput {
			height: 100%;
			margin: 0 !important;
			width: 100%;
			border: none;
			padding-block: 0 !important;
			padding-inline: 10px;
		}
	}

	.titleTodoIcon {
		font-size: 12px;
		color: $textGrey;
		padding: 5px;
		cursor: pointer;
		border-radius: 4px;
		transition: 0.2s;

		&:hover {
			background-color: $lign;
		}
	}
}

.todoRow {
	display: grid;
	align-items: stretch;
	padding-inline: 20px;
	min-height: 25px;
	border-radius: 4px;
	transition: 0.2s;
	border-bottom: 1px solid $lign;

	&:hover {
		background-color: $lightGrey;

		.titleTodo {
			background-color: $lightGrey;
		}

		.todoChekbox {
			opacity: 1;
		}

		.titleTodoIcon {
			opacity: 1;
		}

		.dotMenuContainer {
			opacity: 1;
		}
	}
}
