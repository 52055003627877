@import '../../../styles/variables.scss';

.mainProjects {
	width: 100%;
	display: flex;
	overflow: hidden;

	@media screen and (max-width: 800px) {
		display: block;
		// overflow-y: auto;
		// -ms-overflow-style: none;
		// scrollbar-width: none;

		// &::-webkit-scrollbar {
		// 	display: none;
		// }
	}
}
