@import '../../../../styles/variables';

.container {
	width: 100%;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	overflow-x: hidden;

	height: 79vh;

	@media screen and (max-width: 500px) {
		height: calc(100% - 150px);
	}

	&::-webkit-scrollbar {
		display: none;
	}

	form {
		max-width: 681px;

		.day {
			background-color: $inputBg;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-flow: wrap;
			padding: 20px;
			margin-bottom: 20px;

			input {
				width: 20px;
				margin-right: 10px;
			}

			.checkDay {
				display: flex;
				align-items: center;

				input {
					margin-top: 0;
				}
			}

			.hours {
				display: flex;
				gap: 20px;
				width: 400px;

				select {
					background-color: $white;
				}

				.fromTo {
					width: 100%;
				}
			}
		}

		.disableDay {
			background-color: $inputBg;
			display: flex;
			justify-content: space-between;
			flex-flow: wrap;
			align-items: center;
			padding: 20px;
			margin-bottom: 20px;

			opacity: 0.5;

			input {
				width: 20px;
				margin-right: 10px;
			}

			.checkDay {
				display: flex;
				align-items: center;

				input {
					margin-top: 0;
				}
			}

			.hours {
				display: flex;
				gap: 20px;
				width: 400px;

				select {
					background-color: $white;
				}

				.fromTo {
					width: 100%;
				}
			}
		}

		.lang {
			background-color: $inputBg;
			padding: 30px;

			select {
				background-color: $white;
				width: 150px;
			}
		}

		button {
			margin-top: 20px;
			margin-right: 20px;
		}

		.cancel {
			background-color: transparent;
			color: $black;
			margin-right: 33px;
			font-weight: 600;
			border: 1px solid #8a8886;
			border-radius: 2px;

			&:hover {
				background-color: #ebebeb;
			}
		}
	}
}
