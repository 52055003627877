@import '../../../../styles/variables.scss';



.header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	padding-inline: 30px;
	border-bottom: 1px solid $lign;
	background-color: #09AB57;
	color: #FFFFFF;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 60px;
		height: 10px;
		background-color: $white;
		opacity: .4;
	}

	&::after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 60px;
		height: 30px;
		background-color: $white;
		opacity: .4;
	}

	.right{
		img{
			width: 62px;
		}
	}

	.left {
		display: flex;
		align-items: center;
		gap: 15px;

		.bold {
			font-weight: 700;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				bottom: -1px;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: $white;
			}
		}

		p{
			margin: 0;
			padding: 0;
			font-weight: 400;
			font-size: 16px;
			margin-right: 10px;
		}

		button{

			border: 1.5px solid $white;
			padding-block: 8px;
			padding-inline: 22px;
			background-color: $white;
			font-weight: 600;
			color: #09AB57;

		}

		
		.transparantButton {
			background-color: transparent;
			color: $white;
			font-weight: 600;
			border: 1.5px solid $white;
			padding-block: 7px;
			padding-inline: 22px;
			display: flex;
			align-items: center;
			gap: 8px;

			img{
				width: 15px;
			}
		}
	}
}

.return{
	display: flex;
	align-items: center;
	gap: 15px;
	cursor: pointer;
}


.headerInvite{
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 70px;
	padding: 10px;
	border-bottom: 1px solid $lign;
	background-color: #09AB57;
	color: #FFFFFF;

	.right{
		img{
			width: 72px;
		}
	}

	
}