@import '../../../../../styles/variables.scss';

.optionBtn {
	box-shadow: 0px 0px 1px $lign;
	cursor: pointer;
	font-size: 16px;
	line-height: 16px;
	color: #231F20;
	border: 1px solid #231F20;
	border-radius: 3px;
	padding-inline: 18px;
	font-size: 18px;
	padding-block: 12px;
	border-radius: 4px;
	transition: 100ms;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        font-size: 18px;
    }

	&:hover{
		background-color: #EBEBEB;
	}
	
}