@import '../../../../../styles/variables';

.overviewList {
	position: relative;
	width: 100%;
	height: calc(100% - 150px);
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	overflow-x: auto;
	white-space: nowrap;

	

	.noMeetings {
		margin: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -30%);
		text-align: center;

		h2 {
			font-weight: 400;
			font-size: 28px;

			@media screen and (max-width: 1040px) {
				font-size: x-large;
			}

			@media screen and (max-width: 600px) {
				font-size: medium;
			}

			@media screen and (max-width: 370px) {
				font-size: small;
			}
		}

		.buttonCreate {
			padding-inline: 24px;
    		padding-block: 12px;
		}

		button {
			padding-inline: 20px;
    		padding-block: 12px;

			span {
				display: flex;
				align-items: center;
				gap: 15px;
			}
		}
	}

	.timeLeft {
		color: $green;
		font-weight: 600;
		font-size: 10px;
	}
}

.avatar {
	width: 40px;
	border-radius: 50%;
}

h1 {
	margin-left: 30px;
}

.menu {
	border-bottom: 1px solid $lign;
	padding: 15px;
	display: none;

	@media screen and (max-width: 820px) {
		display: block;
	}

	@media screen and (max-width: 500px) {
		padding-left: 64px;
	}

	.calendar {
		display: flex;
		align-items: center;
		gap: 10px;
		color: $icon;
		cursor: pointer;

		span {
			color: $black;
		}
	}
}

.main {
	width: calc(100% - 350px);

	@media screen and (max-width: 820px) {
		width: 90%;
	}
}

.modalAttendees {
	position: absolute;
	background-color: $white;
	box-shadow: 0 0 6px 0px rgb(200 198 196);
	width: max-content;
	padding: 15px;
	top: 50px;
	z-index: 9;
	max-height: 500px;
	overflow-y: auto;

	.attendee {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
	}
}

.flex{
	display: none;
	padding: 10px;

	@media screen and (max-width: 500px) {
		display: flex;
		align-items: center;
		gap: 15px;

	}

	.icon{
		padding: 0;
		margin: 0;
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	p{
		font-weight: 700;
		font-size: 24px;
		margin: 0;
		padding: 0;
	}
}

.emptyMEetingIllustration {
	position: relative;
	width: 400px;
}