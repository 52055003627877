@import '../../../styles/variables.scss';

.confirmBox {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 2000;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	.modal {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: $white;
		border-radius: 6px;
		// box-shadow: 0 0 6px 0px rgb(200 198 196);
		width: 450px;
		padding-block: 40px;
		text-align: center;

		p {
			margin-bottom: 25px;
			font-weight: 500;
		}

		.buttonContainer {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;

			button {
				padding-block: 10px;
			}

			.cancelButton {
				background-color: transparent;
				color: $black;
				font-weight: 600;
				padding-block: 10px;
				padding-inline: 20px;
				border: 1px solid #8a8886;
				border-radius: 3px;
				display: flex;
				align-items: center;
				gap: 10px;

				&:hover {
					background-color: #ebebeb;
				}
			}
		}
	}
}

.fivedays {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	border: 2px solid $green;
}

.nonFivedays {
	display: flex;
	justify-content: center;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	transform: scale(1.05);
}

.avatarImage {
	opacity: 1;
	transition: none;
}

.menu {
	display: flex;
	align-items: center;
	flex-flow: wrap;
	align-items: flex-start;
	border: 1px solid $lign;
	width: fit-content;
	padding: 5px;
	border-radius: 3px;
	min-height: 52px;

	.group {
		display: flex;
		align-items: center;
		position: relative;
		padding-right: 15px;
	}

	button {
		background-color: transparent;
		padding: 0;
		padding: 6px 6px;
		border: 1px solid rgba(0, 0, 0, 0.12);
		color: rgba(0, 0, 0, 0.54);
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 35px;
		border: none;

		&:hover {
			background-color: $lightGreen !important;
		}
	}

	.button-active {
		background-color: $lightGreen;
	}
}

.pickColor {
	background-color: $inputBg;
	margin-top: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	color: #8a8886;
}

.color {
	display: flex;
	flex-direction: column;
	.col {
		width: 15px;
		height: 1px;
	}

	.cols {
		width: 20px;
		height: 20px;
	}
}

.colors {
	position: absolute;
	background-color: white;
	z-index: 9;
	margin-top: 130px;
	padding: 5px;
	width: 150px;
	box-shadow: 0 0 6px 0px rgb(200 198 196);

	display: flex;
	flex-flow: wrap;
	align-items: center;
	justify-content: center;
	gap: 5px;
}
